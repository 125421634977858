import { useRef } from 'react';

const ChangePHPSESSID = () => {
  const phpsessid_ref = useRef<HTMLInputElement>(null);

  const handle_submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const phpsessid = phpsessid_ref.current?.value;
      if (!phpsessid) throw new Error('Silahkan isi phpsessid Anda');

      const response = await fetch('/api/user/change-phpsessid', {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ phpsessid }),
      });

      const res: { errors?: { message: string }[]; message?: string } =
        await response.json();

      if (res.errors && res.errors.length)
        throw new Error(res.errors[0].message);

      alert(res.message);

      phpsessid_ref.current.value = '';
    } catch (error: any) {
      alert(error.message || `Terjadi kesalahan ketika mengganti phpsessid`);
    }
  };

  return (
    <form className='w-1/5 space-y-3' onSubmit={handle_submit}>
      <h1 className='text-2xl font-bold'>Set PHPSESSID</h1>

      <input
        type='text'
        className='shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block w-full sm:text-sm border-gray-300 rounded-md'
        placeholder='PHPSESSID'
        ref={phpsessid_ref}
      />

      <input
        type='submit'
        className='inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 w-full'
        value='Set'
      />
    </form>
  );
};

export { ChangePHPSESSID };
