import { DateTime } from 'luxon';

export enum KodeWebsite {
  SPACE = 'vnqaaauto',
  PUB = 'vnsaaauto',
  EDM = 'vnraaauto',
  BAR = 'vopaaauto',
  GAS = 'voraaauto',
  GOOD = 'voqaaauto',
}

export interface AccountModel {
  id: string;
  namaRekening: string;
  labelRekening: string;
  usernameRekening: string;
  passwordRekening: string;
  namaBank: Banks;
  nomorRekening: string;
  lastFetchStatementTime?: Date;
  kodeWebsite: KodeWebsite;
  refreshToken: string;
  pin: string;
  balance: number;
  deleted: boolean;
  version: number;
  urutan: number;
  mid: string;
}

export const isAccountModel = (
  object: string | AccountModel
): object is AccountModel => {
  return (object as AccountModel)?.id !== undefined;
};

export type FormModel = {
  id: string;
  pengirim: {
    bankPlayer: Banks;
    nomorRekeningPlayer: string;
    namaRekeningPlayer: string;
  };
  penerima: { bankPT: Banks; nomorRekeningPT: string; namaRekeningPT: string };
  agentId: string;
  amount: number;
  inputId: string;
  inputName: string;
  kodeWebsite: KodeWebsite;
  secondsDuration: number;
  timeConfirmed: string;
  timeRequest: string;
  username: string;
  statementId: string | StatementModel;
  accountId: string;
  keterangan?: string;
  version: number;
  balance: string;
};

export interface StatementModel {
  id: string;
  accountId: string | AccountModel;
  balance: number;
  confirmed: boolean;
  date: string;
  formId?: string;
  keterangan?: string;
  kodeWebsite: string;
  membal?: boolean;
  nama: string;
  nominal: number;
  playerUsername?: string;
  type: 'CR' | 'DB';
  agentUsername?: string;
  merchantInvoice?: string; // * untuk penanda transaksi OVO
  batch: number;
  klop: string[];
  updatedAt: string;

  // * Exclusive on Front End
  // * Used for filtering selected statements
  selected: boolean;
}

export const isStatementModel = (
  object: string | StatementModel
): object is StatementModel => {
  return (object as StatementModel)?.id !== undefined;
};

export interface TarikanModel {
  id: string;
  accountId: string;
  date: DateTime;
  agentUsername: string;
  batch: number;
  details: { [k: string]: any };
  version: number;
  success: boolean;
}

export interface UserModel {
  id: string;
  role: string;
  username: string;
  nama: string;
  active: boolean;
}

export type LogType = 'USER' | 'ACCOUNT' | 'STATEMENT' | 'FORM' | 'START' | '';

export interface LogModel {
  id: string;
  type: LogType;
  time: string;
  agent: string;
  description: string;
  target: string;
  details?: { before: { [k: string]: any }; after: { [k: string]: any } };
  version: number;
}

export enum Banks {
  ALL = '',
  BCA = 'BCA',
  BNI = 'BNI',
  BRI = 'BRI',
  CIMB = 'CIMB',
  DANAMON = 'DANAMON',
  MANDIRI = 'MANDIRI',
  OVO = 'OVO',
  GOPAY = 'GOPAY',
  LINKAJA = 'LINKAJA',
  DANA = 'DANA',
  QRIS = 'QRIS',
  JAGO = 'JAGO',
}

export interface BonusModel {
  id: string;
  accountId: string;
  date: string;
  agentUsername: string;
  amount: number;
}
