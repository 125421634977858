import { Dialog, Transition } from '@headlessui/react';
import { DateTime } from 'luxon';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { LeafIcon } from '../../../../../Assets';
import LoadingIndicator from '../../../../../Components/LoadingIndicator/LoadingIndicator';
import { errMsg, thousandSeparator } from '../../../../../helper';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { set_active_tab } from '../../../../../store/state/active-tab';
import { set_find_statement_query } from '../../../../../store/state/find-statement-query';
import { set_finding_statement } from '../../../../../store/state/finding-statement';
import { set_selected_acc } from '../../../../../store/state/selected-acc';
import {
  AccountModel,
  Banks,
  StatementModel,
  isAccountModel,
} from '../../../../../types';

const NoStatements = () => (
  <div className='opacity-50 w-full text-center py-20'>
    <div className='w-24 m-auto'>
      <img src={LeafIcon.default} alt='No statements...' className='w-full' />
    </div>

    <div>Tidak ada mutasi...</div>
  </div>
);

const AREA = { FINDING: 'FINDING' };

const FindStmt: React.FC = () => {
  const dispatch = useAppDispatch();
  const input_nama_ref = useRef<HTMLInputElement>(null);
  const [pendingan, set_pendingan] = useState<StatementModel[]>([]);
  const { promiseInProgress: searching_statement } = usePromiseTracker({
    area: AREA.FINDING,
  });

  const { finding_statement } = useAppSelector(
    state => state.finding_statement
  );
  const { find_statement_query } = useAppSelector(
    state => state.find_statement_query
  );

  const close_window = () => {
    dispatch(set_finding_statement(false));

    setTimeout(() => {
      dispatch(set_find_statement_query({ nama_pengirim: '', nominal: '' }));
      set_pendingan([]);
    }, 200);
  };

  useEffect(() => {
    const { nama_pengirim, nominal } = find_statement_query;

    const search_statement = async () => {
      try {
        const response = await fetch(`/api/statement/find`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ namaPengirim: nama_pengirim, nominal }),
        });

        const res: { message: string; data: { statements: StatementModel[] } } =
          await response.json();
        if (!response.ok) throw new Error(errMsg(res) || res.message);
        set_pendingan(res.data.statements);
      } catch (error: any) {
        alert(error.message || `Terjadi kesalahan ketika mencari pendingan...`);
      }
    };

    if (nama_pengirim || nominal)
      trackPromise(search_statement(), AREA.FINDING);
  }, [find_statement_query]);

  const open_pendingan = async (account: AccountModel) => {
    dispatch(set_active_tab(account.namaBank as Banks));
    dispatch(set_selected_acc(account));
    dispatch(set_finding_statement(false));
  };

  return (
    <Transition.Root show={finding_statement} as={Fragment}>
      <Dialog
        as='div'
        className='relative z-10'
        initialFocus={input_nama_ref}
        onClose={close_window}
      >
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed z-10 inset-0 overflow-y-auto'>
          <div className='flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-[84rem] sm:w-full sm:p-6 bg-gray-50'>
                <div className=''>
                  <div className='mt-3 text-center sm:mt-5'>
                    <Dialog.Title
                      as='h3'
                      className='text-lg leading-6 font-medium text-gray-900'
                    >
                      <div className='flex justify-between items-center'>
                        <div className='flex justify-between'>
                          Cari Pendingan
                        </div>

                        <div className='flex space-x-2'>
                          <DebounceInput
                            className='px-3 py-2 border border-gray-300 rounded shadow-sm placeholder-gray-400 focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 text-sm w-64'
                            placeholder='Nama Pengirim...'
                            onChange={e =>
                              dispatch(
                                set_find_statement_query({
                                  nama_pengirim: e.target.value,
                                  nominal: find_statement_query.nominal,
                                })
                              )
                            }
                            debounceTimeout={500}
                            value={find_statement_query.nama_pengirim}
                          />

                          <DebounceInput
                            className='px-3 py-2 border border-gray-300 rounded shadow-sm placeholder-gray-400 focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 text-sm'
                            placeholder='Nominal...'
                            onChange={e =>
                              dispatch(
                                set_find_statement_query({
                                  nama_pengirim:
                                    find_statement_query.nama_pengirim,
                                  nominal: e.target.value,
                                })
                              )
                            }
                            debounceTimeout={500}
                            value={find_statement_query.nominal}
                          />
                        </div>
                      </div>
                    </Dialog.Title>

                    <div className='overflow-auto shadow ring-1 ring-black ring-opacity-5 md:rounded-lg mt-4'>
                      <table className='min-w-full divide-y divide-gray-300 border-separate text-sm'>
                        <thead className='bg-gray-50'>
                          <tr className='whitespace-nowrap text-sm font-semibold text-gray-900'>
                            <th className='px-2 py-3.5 pl-4 text-center'>
                              Tanggal
                            </th>
                            <th className='px-2 py-3.5 text-center'>Jam</th>
                            <th className='px-2 py-3.5 text-left'>Nama</th>
                            <th className='px-2 py-3.5 text-left'>
                              Keterangan
                            </th>
                            <th className='px-2 py-3.5 text-right'>Kredit</th>
                            <th className='px-2 py-3.5 text-center'>Bank</th>
                            <th className='px-2 py-3.5 text-center'>
                              Atas Nama
                            </th>
                            <th className='px-2 py-3.5 text-center'>
                              Nomor Rekening
                            </th>
                          </tr>
                        </thead>

                        <tbody className='divide-y divide-gray-200 bg-white'>
                          {searching_statement ? (
                            <tr>
                              <td colSpan={99}>
                                <LoadingIndicator colorScheme='dark' />
                              </td>
                            </tr>
                          ) : pendingan.length ? (
                            pendingan.map(statement => {
                              const date = DateTime.fromISO(
                                statement.date
                              ).setLocale('id');

                              return (
                                <tr
                                  key={statement.id}
                                  className='whitespace-nowrap text-sm text-gray-500 hover:text-emerald-500 cursor-pointer active:opacity-70'
                                  onClick={() => {
                                    if (isAccountModel(statement.accountId))
                                      open_pendingan(statement.accountId);
                                  }}
                                >
                                  <td className='px-2 py-2 pl-4 text-center'>
                                    {date.toFormat('d LLLL yyyy')}
                                  </td>
                                  <td className='px-2 py-2 text-center'>
                                    {date.toFormat('HH:mm:ss')}
                                  </td>
                                  <td className='px-2 py-2 text-left max-w-xl whitespace-pre-wrap'>
                                    {statement.nama}
                                  </td>

                                  <td className='px-2 py-2 text-left max-w-xl whitespace-pre-wrap'>
                                    {statement.keterangan}
                                  </td>

                                  <td className='px-2 py-2 text-right font-mono font-light'>
                                    {thousandSeparator(
                                      Math.round(statement.nominal)
                                    )}
                                  </td>

                                  <td className='px-2 py-2 text-center'>
                                    {isAccountModel(statement.accountId)
                                      ? statement.accountId.namaBank
                                      : '-'}
                                  </td>

                                  <td className='px-2 py-2 text-center'>
                                    {isAccountModel(statement.accountId)
                                      ? statement.accountId.namaRekening
                                      : '-'}
                                  </td>

                                  <td className='px-2 py-2 text-center'>
                                    {isAccountModel(statement.accountId)
                                      ? statement.accountId.nomorRekening
                                      : '-'}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan={99}>
                                <NoStatements />
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default FindStmt;
