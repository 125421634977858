import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AccountModel } from '../../types';

interface SelectedAccState {
  selected_acc: AccountModel | null;
}

const initial_state: SelectedAccState = {
  selected_acc: null,
};

export const selected_acc_slice = createSlice({
  name: 'selected-acc',
  initialState: initial_state,

  reducers: {
    set_selected_acc: (state, action: PayloadAction<AccountModel | null>) => {
      state.selected_acc = action.payload;
    },
  },
});

export default selected_acc_slice;
export const { set_selected_acc } = selected_acc_slice.actions;
