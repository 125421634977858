import { Dialog, Transition } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { DateTime } from 'luxon';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import LoadingIndicator from '../../../../../Components/LoadingIndicator/LoadingIndicator';
import { classNames, errMsg, thousandSeparator } from '../../../../../helper';
import { useAppSelector } from '../../../../../store';
import { StatementModel } from '../../../../../types';

interface InsertingTrxProps {
  open: boolean;
  set_open: React.Dispatch<React.SetStateAction<boolean>>;
}

const AREA = { INSERT_TRX: 'INSERT_TRX', GET_TRX: 'GET_TRX' };

const InsertingTrx: React.FC<InsertingTrxProps> = ({ open, set_open }) => {
  const { selected_acc } = useAppSelector(state => state.selected_acc);
  const first_input_ref = useRef(null);

  const { promiseInProgress: inserting_transactions } = usePromiseTracker({
    area: AREA.INSERT_TRX,
  });
  const { promiseInProgress: getting_transactions } = usePromiseTracker({
    area: AREA.GET_TRX,
  });

  const [statements, set_statements] = useState<StatementModel[]>([]);
  const [insert_token, set_insert_token] = useState('');

  const [new_statements, set_new_statements] = useState<
    { keterangan: string; keluar: number; masuk: number }[]
  >([{ keterangan: '', keluar: 0, masuk: 0 }]);

  const close_window = useCallback(() => {
    set_open(false);

    setTimeout(() => {
      set_statements([]);
      set_new_statements([{ keterangan: '', keluar: 0, masuk: 0 }]);
    }, 200);
  }, [set_open]);

  const close_window_prompt = () => {
    if (new_statements.length <= 1) return close_window();

    const last_statement = new_statements[new_statements.length - 2];
    if (
      !last_statement.keterangan &&
      !last_statement.keluar &&
      !last_statement.masuk
    )
      return close_window();

    const confirmed = window.confirm('Yakin ingin batal menambahkan mutasi?');
    if (!confirmed) return;

    close_window();
  };

  const get_last_10_transactions = useCallback(async () => {
    try {
      if (!selected_acc || !open) return;
      const uri = `/api/statement/last-10/${selected_acc.id}`;
      const response = await fetch(uri);
      const res: { message: string; data: { statements: StatementModel[] } } =
        await response.json();
      if (!response.ok) throw new Error(errMsg(res) || res.message);
      set_statements(res.data.statements.reverse());
    } catch (error: any) {
      alert(
        error.message ||
          `Terjadi kesalahan ketika mengambil 10 mutasi terakhir...`
      );
    }
  }, [selected_acc, open]);

  useEffect(() => {
    const last_statement = new_statements[new_statements.length - 1];

    if (
      last_statement.keterangan &&
      (last_statement.keluar || last_statement.masuk)
    )
      set_new_statements(newStatements => {
        newStatements.push({ keterangan: '', keluar: 0, masuk: 0 });
        return newStatements;
      });
  }, [new_statements]);

  useEffect(() => {
    const get_insert_token = async () => {
      try {
        const uri = `/api/statement/get-insert-token`;
        const response = await fetch(uri);
        const res = await response.json();
        if (!response.ok) throw new Error(res.message);
        set_insert_token(res.data.insertToken);
      } catch (error: any) {
        alert(error.message || `Terjadi kesalahan ketika mengambil token...`);
      }
    };

    if (open) {
      trackPromise(get_last_10_transactions(), AREA.GET_TRX);
      get_insert_token();
    }
  }, [open, get_last_10_transactions]);

  const insert_transactions = useCallback(async () => {
    try {
      if (!selected_acc) return;

      const response = await fetch(`/api/statement/insert`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          newStatements: new_statements,
          selectedAccId: selected_acc.id,
          insertToken: insert_token,
        }),
      });

      const res = await response.json();
      if (!response.ok) throw new Error(errMsg(res) || res.message);
      alert(res.message);
      close_window();
    } catch (error: any) {
      alert(
        error.message || `Terjadi kesalahan ketika menambahkan transaksi...`
      );
    }
  }, [close_window, insert_token, new_statements, selected_acc]);

  const klop = async () => {
    try {
      if (!selected_acc) return;
      if (!window.confirm('Saldo sudah klop?')) return;

      const response = await fetch(
        `/api/statement/insert-klop/${selected_acc.id}`,
        { method: 'PATCH' }
      );
      const res = await response.json();

      if (!response.ok) throw new Error(errMsg(res) || res.message);
      alert(res.message);
      trackPromise(get_last_10_transactions(), AREA.GET_TRX);
    } catch (error: any) {
      alert(error.message || `Terjadi kesalahan ketika menambahkan klop...`);
    }
  };

  useEffect(() => {
    const listener = async (e: KeyboardEvent) => {
      if (e.key === 'Enter' && (e.ctrlKey || e.metaKey)) {
        e.preventDefault();
        if (inserting_transactions) return;
        trackPromise(insert_transactions(), AREA.INSERT_TRX);
      }
    };

    window.addEventListener('keydown', listener);

    return () => {
      window.removeEventListener('keydown', listener);
    };
  }, [insert_transactions, inserting_transactions]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as='div'
        className='relative z-10'
        initialFocus={first_input_ref}
        onClose={close_window_prompt}
      >
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed z-10 inset-0 overflow-y-auto'>
          <div className='flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-[84rem] sm:w-full sm:p-6 bg-gray-50'>
                <div className=''>
                  <div className='mt-3 text-center sm:mt-5'>
                    <Dialog.Title
                      as='h3'
                      className='text-lg leading-6 font-medium text-gray-900'
                    >
                      <div className='flex justify-between'>
                        <div className='w-16'></div>
                        <div>Tambah Mutasi</div>

                        <button
                          type='button'
                          className='inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded shadow-sm text-white bg-emerald-600 hover:bg-emerald-700 fozcus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500'
                          onClick={klop}
                        >
                          <CheckCircleIcon className='h-5 w-5 text-white mr-2' />{' '}
                          Klop
                        </button>
                      </div>
                    </Dialog.Title>

                    <div className='overflow-auto shadow ring-1 ring-black ring-opacity-5 md:rounded-lg mt-4'>
                      <table className='min-w-full divide-y divide-gray-300 border-separate text-sm'>
                        <thead className='bg-gray-50'>
                          <tr className='whitespace-nowrap text-sm font-semibold text-gray-900'>
                            <th className='px-2 py-3.5 pl-4 text-center'>
                              Tanggal
                            </th>
                            <th className='px-2 py-3.5 text-center'>Jam</th>
                            <th className='px-2 py-3.5 text-left'>
                              Keterangan
                            </th>
                            <th className='px-2 py-3.5 text-right'>Debet</th>
                            <th className='px-2 py-3.5 text-right'>Kredit</th>
                            <th className='px-2 py-3.5 text-right'>Saldo</th>
                            <th className='px-2 py-3.5'>Klop</th>
                          </tr>
                        </thead>

                        <tbody className='divide-y divide-gray-200 bg-white'>
                          {getting_transactions ? (
                            <tr>
                              <td colSpan={99} className='p-20'>
                                <LoadingIndicator colorScheme='dark' />
                              </td>
                            </tr>
                          ) : (
                            statements.map(statement => {
                              const nominal = thousandSeparator(
                                Math.round(statement.nominal)
                              );

                              return (
                                <tr
                                  key={statement.id}
                                  className={classNames(
                                    statement.membal
                                      ? 'text-yellow-500'
                                      : 'text-gray-500',
                                    'whitespace-nowrap text-sm',
                                    statement.confirmed && !statement.formId
                                      ? 'bg-red-300'
                                      : ''
                                  )}
                                >
                                  <td className='px-2 py-2 pl-4 text-center'>
                                    {DateTime.fromISO(
                                      statement.date || new Date().toISOString()
                                    )
                                      .setLocale('id')
                                      .toFormat('d LLLL yyyy')}
                                  </td>

                                  <td className='px-2 py-2 text-center'>
                                    {DateTime.fromISO(
                                      statement.date || new Date().toISOString()
                                    )
                                      .setLocale('id')
                                      .toFormat('HH:mm:ss')}
                                  </td>

                                  <td className='px-2 py-2 text-left max-w-xl whitespace-pre-wrap'>
                                    {statement.keterangan}
                                  </td>

                                  <td className='px-2 py-2 text-right font-mono font-light w-32'>
                                    {statement.type === 'DB' ? nominal : ''}
                                  </td>

                                  <td className='px-2 py-2 text-right font-mono font-light w-32'>
                                    {statement.type === 'CR' ? nominal : ''}
                                  </td>

                                  <td className='px-2 py-2 text-right font-mono font-light w-32'>
                                    {thousandSeparator(
                                      Math.round(statement.balance)
                                    )}
                                  </td>

                                  <td className='px-2 py-2'>
                                    {statement.klop.join(', ')}
                                  </td>
                                </tr>
                              );
                            })
                          )}

                          {new_statements.map((_statement, index) => (
                            <tr
                              className={classNames(
                                'whitespace-nowrap text-sm'
                              )}
                              key={`NewStatement#${index}`}
                            >
                              <td></td>
                              <td></td>

                              <td className='text-left p-0'>
                                <input
                                  type='text'
                                  placeholder='Keterangan...'
                                  className='focus:ring-emerald-500 block w-full sm:text-sm border-none'
                                  onChange={e =>
                                    set_new_statements(newStatements => {
                                      newStatements[index].keterangan =
                                        e.target.value.trim();
                                      return [...newStatements];
                                    })
                                  }
                                  ref={
                                    index === 0 ? first_input_ref : undefined
                                  }
                                />
                              </td>

                              <td className='p-0'>
                                <input
                                  type='text'
                                  placeholder={
                                    new_statements[index].masuk
                                      ? ''
                                      : 'Keluar...'
                                  }
                                  disabled={!!new_statements[index].masuk}
                                  className='focus:ring-emerald-500 block w-32 sm:text-sm border-none text-right font-mono font-light'
                                  value={thousandSeparator(
                                    new_statements[index].keluar,
                                    ''
                                  )}
                                  onChange={e =>
                                    set_new_statements(newStatements => {
                                      newStatements[index].keluar =
                                        +e.target.value
                                          .trim()
                                          .replace(/\./g, '');
                                      return [...newStatements];
                                    })
                                  }
                                />
                              </td>

                              <td className='p-0'>
                                <input
                                  type='text'
                                  placeholder={
                                    new_statements[index].keluar
                                      ? ''
                                      : 'Masuk...'
                                  }
                                  disabled={!!new_statements[index].keluar}
                                  className='focus:ring-emerald-500 block w-32 sm:text-sm border-none text-right font-mono font-light'
                                  value={thousandSeparator(
                                    new_statements[index].masuk,
                                    ''
                                  )}
                                  onChange={e =>
                                    set_new_statements(newStatements => {
                                      newStatements[index].masuk =
                                        +e.target.value
                                          .trim()
                                          .replace(/\./g, '');
                                      return [...newStatements];
                                    })
                                  }
                                />
                              </td>

                              <td></td>
                              <td></td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className='mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense'>
                  <button
                    type='button'
                    className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-emerald-600 text-base font-medium text-white hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 sm:col-start-2 sm:text-sm'
                    onClick={() => {
                      if (inserting_transactions) return;
                      trackPromise(insert_transactions(), AREA.INSERT_TRX);
                    }}
                  >
                    {inserting_transactions ? (
                      <LoadingIndicator colorScheme='light' />
                    ) : (
                      'Tambah'
                    )}
                  </button>

                  <button
                    type='button'
                    className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 sm:mt-0 sm:col-start-1 sm:text-sm'
                    onClick={close_window_prompt}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default InsertingTrx;
