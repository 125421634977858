import {
  ArrowPathIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/outline';
import { PlusIcon } from '@heroicons/react/24/solid';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { usePromiseTracker } from 'react-promise-tracker';
import { socket } from '../../../..';
import { socket_name } from '../../../../constant';
import { classNames } from '../../../../helper';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { set_finding_statement } from '../../../../store/state/finding-statement';
import { update_form } from '../../../../store/state/form';
import { get_active_forms } from '../../../../store/state/form/request/get-active-forms';
import { FormModel } from '../../../../types';
import ProfileDropdown from '../../Components/ProfileDropdown';
import Aside from './Components/Aside';
import FindStmt from './Components/FindStmt';
import InsertingTrx from './Components/InsertingTrx';
import Main from './Components/Main';
import RekeningTabs from './Components/RekeningTabs';

export const AREA = {
  START: 'Start',
  CONFIRM: 'Confirm',
  REJECT: 'Reject',
};

const Live: React.FC = () => {
  const dispatch = useAppDispatch();
  const { forms, getting_active_forms } = useAppSelector(state => state.forms);
  const { selected_acc } = useAppSelector(state => state.selected_acc);
  const { promiseInProgress: starting } = usePromiseTracker({
    area: AREA.START,
  });
  const [show_add_trx_window, set_show_add_trx_window] = useState(false);
  const [is_blinking, set_is_blinking] = useState(false);

  const current_forms_usernames = useRef(new Set());
  const new_form_count = useRef(0);

  useEffect(() => {
    const listener = async (e: KeyboardEvent) => {
      const refresh_keys = ['F5', 'r', 'R'];

      if (refresh_keys.includes(e.key)) {
        if (e.ctrlKey || e.metaKey) {
          e.preventDefault();
          dispatch(get_active_forms());
        }
      }

      if (e.key === 'a' && (e.ctrlKey || e.metaKey)) {
        e.preventDefault();

        if (!selected_acc) {
          alert('Harap pilih rekening terlebih dahulu!');
          return;
        }

        set_show_add_trx_window(true);
      }

      if (e.key === 'f' && (e.ctrlKey || e.metaKey) && e.shiftKey) {
        e.preventDefault();
        dispatch(set_finding_statement(true));
      }
    };

    window.addEventListener('keydown', listener);

    return () => {
      window.removeEventListener('keydown', listener);
    };
  }, [selected_acc, dispatch]);

  useEffect(() => {
    const upd_form_socket_name = `${socket_name.update_forms}`;

    socket.on(upd_form_socket_name, (e: FormModel[]) => {
      dispatch(update_form(e));
    });

    return () => {
      socket.off(upd_form_socket_name);
    };
  }, [dispatch]);

  const start_blinking = () => {
    const blinking_interval = setInterval(() => {
      set_is_blinking(prev_is_blinking => !prev_is_blinking);
    }, 750);

    setTimeout(() => {
      set_is_blinking(false);
      clearInterval(blinking_interval);
    }, 4500);

    return () => {
      clearInterval(blinking_interval);
    };
  };

  useEffect(() => {
    try {
      (async () => {
        const { meta, payload } = await dispatch(get_active_forms());
        const response = payload as { data: FormModel[]; message: string };
        console.log({ response });

        if (meta.requestStatus === 'rejected')
          // alert(response.message as string);

          alert('Tidak menemukan form. Harap set phpsessid ulang!');
      })();
    } catch (error: any) {
      alert(error.message);
    }
  }, [dispatch]);

  useEffect(() => {
    const new_forms = forms.filter(
      e => !current_forms_usernames.current.has(e.username)
    );

    new_form_count.current = new_forms.length;
    if (new_forms.length > 0) start_blinking();
    current_forms_usernames.current = new Set(forms.map(e => e.username));
  }, [forms]);

  return (
    <>
      <Helmet defer={false}>
        <title>
          {is_blinking
            ? `[ ${process.env.REACT_APP_SELECTED_WEBSITE} ] ${new_form_count.current} FORM BARU ❗❗❗`
            : `[ ${process.env.REACT_APP_SELECTED_WEBSITE} ] Live - Auto Deposit`}
        </title>
      </Helmet>

      <header className='w-full'>
        <div className='relative z-10 flex-shrink-0 h-16 bg-white border-b border-gray-200 shadow-sm flex'>
          <div className='flex-1 flex justify-between px-4 sm:px-6'>
            <div className='flex-1 flex'>
              <RekeningTabs />
            </div>

            <div className='flex items-center space-x-6 sm:ml-6 sm:space-x-3'>
              <ProfileDropdown />

              <button
                type='button'
                className='flex bg-emerald-600 p-1.5 rounded-full items-center justify-center text-white hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500'
                onClick={() => dispatch(set_finding_statement(true))}
              >
                <MagnifyingGlassIcon className={classNames('h-5 w-5')} />
              </button>

              <button
                type='button'
                className='flex bg-emerald-600 p-1.5 rounded-full items-center justify-center text-white hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500'
                onClick={async () => {
                  if (getting_active_forms) return;
                  const { meta, payload } = await dispatch(get_active_forms());

                  const response = payload as {
                    data: FormModel[];
                    message: string;
                  };

                  if (meta.requestStatus === 'rejected')
                    alert(response.message as string);
                }}
              >
                <ArrowPathIcon
                  className={classNames(
                    'h-5 w-5',
                    getting_active_forms || starting ? 'animate-spin' : ''
                  )}
                />
              </button>

              <button
                type='button'
                className='flex bg-emerald-600 p-1.5 rounded-full items-center justify-center text-white hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500'
                onClick={() => {
                  if (!selected_acc) return;
                  set_show_add_trx_window(true);
                }}
              >
                <PlusIcon className='h-5 w-5' />
              </button>
            </div>
          </div>
        </div>
      </header>

      <div className='flex-col flex-1 flex lg:flex-row items-stretch overflow-hidden'>
        <Main fetching_form={getting_active_forms || starting} />
        <Aside />
      </div>

      <InsertingTrx
        open={show_add_trx_window}
        set_open={set_show_add_trx_window}
      />
      <FindStmt />
    </>
  );
};

export default Live;
