import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface FindStatementQueryState {
  find_statement_query: { nama_pengirim: string; nominal: string };
}

const initial_state: FindStatementQueryState = {
  find_statement_query: { nama_pengirim: '', nominal: '' },
};

export const find_statement_query_slice = createSlice({
  name: 'find_statement_query',
  initialState: initial_state,

  reducers: {
    set_find_statement_query: (
      state,
      action: PayloadAction<{ nama_pengirim: string; nominal: string }>
    ) => {
      state.find_statement_query = action.payload;
    },
  },
});

export default find_statement_query_slice;
export const { set_find_statement_query } = find_statement_query_slice.actions;
