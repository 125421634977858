import { Dialog, Transition } from '@headlessui/react';
import {
  ExclamationTriangleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { Fragment, useContext, useEffect, useState } from 'react';
import MutasiContext from '../../../../../context/mutasi';
import { errMsg, thousandSeparator } from '../../../../../helper';
import { useAppSelector } from '../../../../../store';
import { StatementModel } from '../../../../../types';

interface AdjustIDProps {
  show_adj_id_window: boolean;
  set_show_adj_id_window: React.Dispatch<React.SetStateAction<boolean>>;
}

const AdjustID: React.FC<AdjustIDProps> = ({
  set_show_adj_id_window,
  show_adj_id_window,
}) => {
  const [last_stmt, set_last_stmt] = useState<StatementModel>();
  const [id_adj, set_id_adj] = useState<string>('');

  const { get_mutasi } = useContext(MutasiContext);

  const { selected_acc } = useAppSelector(state => state.selected_acc);

  const close_window = () => {
    set_show_adj_id_window(false);

    setTimeout(() => {
      set_id_adj('');
      set_last_stmt(undefined);
    }, 200);
  };

  useEffect(() => {
    const get_last_stmt = async () => {
      try {
        if (!selected_acc) return;

        const response = await fetch(
          `/api/statement/get-last/${selected_acc.id}`
        );
        const res = await response.json();
        if (!response.ok) throw new Error(errMsg(res) || res.message);

        set_last_stmt(res.data);
      } catch (error: any) {
        alert(
          error.message || `Terjadi kesalahan ketika mengambil mutasi terakhir`
        );
      }
    };

    get_last_stmt();
  }, [selected_acc, show_adj_id_window]);

  const submit_adj = async () => {
    try {
      if (!id_adj) return;
      if (!selected_acc) throw new Error('Belum memilih rekening.');

      const response = await fetch(`/api/statement/adjust-id`, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ accId: selected_acc.id, IDAdj: id_adj }),
      });

      const res = await response.json();
      if (!response.ok) throw new Error(errMsg(res) || res.message);

      alert(res.message);
      get_mutasi();
      close_window();
    } catch (error: any) {
      alert(error.message || `Terjadi kesalahan ketika mencocokkan saldo...`);
    }
  };

  return (
    <>
      <Transition.Root show={show_adj_id_window} as={Fragment}>
        <Dialog
          as='div'
          className='relative z-10'
          onClose={() => close_window()}
        >
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>

          <div className='fixed z-10 inset-0 overflow-y-auto'>
            <div className='flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                enterTo='opacity-100 translate-y-0 sm:scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              >
                <Dialog.Panel className='relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-xl sm:w-full sm:p-6'>
                  <div className='hidden sm:block absolute top-0 right-0 pt-4 pr-4'>
                    <button
                      type='button'
                      className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500'
                      onClick={() => close_window()}
                    >
                      <span className='sr-only'>Close</span>
                      <XMarkIcon className='h-6 w-6' />
                    </button>
                  </div>

                  <div className='sm:flex sm:items-start'>
                    <div className='mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10'>
                      <ExclamationTriangleIcon className='h-6 w-6 text-red-600' />
                    </div>

                    <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
                      <Dialog.Title
                        as='h3'
                        className='text-lg leading-6 font-medium text-gray-900'
                      >
                        Masukkan ID Baru
                      </Dialog.Title>

                      <p className='text-sm text-gray-500'>
                        Masukkan id yang benar untuk mutasi di bawah ini (lihat
                        di menu tarikan)
                      </p>

                      <div className='mt-2'>
                        <table className='min-w-full divide-y divide-gray-300'>
                          <tbody>
                            <tr className='whitespace-nowrap text-sm'>
                              <th className='px-2 py-2 pl-4 align-top'>
                                Keterangan
                              </th>

                              <td className='px-2 py-2 pl-4'>
                                {last_stmt?.keterangan
                                  ?.split(' - ')
                                  .map(ket => (
                                    <Fragment key={ket}>
                                      {ket}
                                      <br />
                                    </Fragment>
                                  ))}
                              </td>
                            </tr>

                            <tr className='whitespace-nowrap text-sm'>
                              <th className='px-2 py-2 pl-4'>Nominal</th>
                              <td className='px-2 py-2 pl-4'>
                                {thousandSeparator(
                                  Math.round(last_stmt?.nominal || 0)
                                )}
                              </td>
                            </tr>

                            <tr className='whitespace-nowrap text-sm'>
                              <th className='px-2 py-2 pl-4'>ID Tercatat</th>
                              <td className='px-2 py-2 pl-4'>
                                {last_stmt?.merchantInvoice}
                              </td>
                            </tr>

                            <tr className='whitespace-nowrap text-sm'>
                              <th className='px-2 py-2 pl-4'>ID Seharusnya</th>

                              <td className='px-2 py-2 pl-4'>
                                <input
                                  type='text'
                                  className='shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block w-full sm:text-sm border-gray-300 rounded-md min-w-[15rem]'
                                  placeholder='Masukkan id yang seharusnya...'
                                  value={id_adj}
                                  onChange={e => set_id_adj(e.target.value)}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div className='mt-5 sm:mt-4 sm:flex sm:flex-row-reverse'>
                    <button
                      type='button'
                      className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm'
                      onClick={submit_adj}
                    >
                      Cocokkan
                    </button>

                    <button
                      type='button'
                      className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 sm:mt-0 sm:w-auto sm:text-sm'
                      onClick={() => close_window()}
                    >
                      Batal
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default AdjustID;
