import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { FormModel } from '../../../types';
import { get_active_forms } from './request/get-active-forms';

interface FormState {
  forms: FormModel[];
  getting_active_forms: boolean;
}

const initial_state: FormState = {
  forms: [],
  getting_active_forms: false,
};

export const form_slice = createSlice({
  name: 'form',
  initialState: initial_state,

  reducers: {
    update_form: (state, action: PayloadAction<FormModel[]>) => {
      state.forms = action.payload;
    },
  },

  extraReducers: builder => {
    builder.addCase(get_active_forms.pending, state => {
      state.getting_active_forms = true;
    });

    builder.addCase(get_active_forms.rejected, state => {
      state.getting_active_forms = false;
    });

    builder.addCase(get_active_forms.fulfilled, (state, action) => {
      state.forms = action.payload.data;
      state.getting_active_forms = false;
    });

    // builder.addCase(check_and_confirm_form.fulfilled, (state, action) => {
    //   state.forms = action.payload.data.forms;
    // });

    // builder.addCase(check_and_reject_form.fulfilled, (state, action) => {
    //   state.forms = action.payload.data.forms;
    // });

    // builder.addCase(
    //   start_matching_forms_and_statements.fulfilled,
    //   (state, action) => {
    //     state.forms = action.payload.data;
    //   }
    // );
  },
});

export default form_slice;
export const { update_form } = form_slice.actions;
