import { useEffect, useState } from 'react';

const useRole = () => {
  const [role, set_role] = useState('');

  useEffect(() => {
    const abort_controller = new AbortController();

    (async () => {
      const response = await fetch('/api/user/current', {
        signal: abort_controller.signal,
      });

      const data = await response.json();
      set_role(data.currentUser.role);
    })();

    return () => {
      abort_controller.abort();
    };
  }, []);

  return { role };
};

export { useRole };
