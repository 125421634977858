// * sync with server/src/constant/socket-name.ts
const socket_name = {
  add_statement: 'new-statement',
  remove_statement: 'remove-statement',
  update_statement: 'update-statement',
  update_account: 'update-account',
  update_forms: 'update-forms',
};

export default socket_name;
