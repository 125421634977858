import {
  ArrowDownOnSquareStackIcon,
  ArrowDownTrayIcon,
  AtSymbolIcon,
  ChartBarIcon,
  CircleStackIcon,
  DocumentTextIcon,
  PlayIcon,
  TrashIcon,
  UserCircleIcon,
} from '@heroicons/react/24/outline';
import { useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { LogoImg } from '../../../Assets';
import { nav_path } from '../../../constant';
import { DashboardContext } from '../../../context/dashboard';
import { classNames } from '../../../helper';

const NarrowSidebar: React.FC = () => {
  const [side_nav, set_side_nav] = useState([
    {
      name: 'Live',
      href: nav_path.dashboard.live,
      icon: PlayIcon,
      current: window.location.pathname === nav_path.dashboard.live,
    },

    {
      name: 'History',
      href: nav_path.dashboard.history,
      icon: DocumentTextIcon,
      current: window.location.pathname === nav_path.dashboard.history,
    },

    {
      name: 'Trash',
      href: nav_path.dashboard.trash,
      icon: TrashIcon,
      current: window.location.pathname === nav_path.dashboard.trash,
    },

    {
      name: 'Mutasi',
      href: nav_path.dashboard.mutasi,
      icon: ArrowDownTrayIcon,
      current: window.location.pathname === nav_path.dashboard.mutasi,
    },

    {
      name: 'Tarikan',
      href: nav_path.dashboard.tarikan,
      icon: ArrowDownOnSquareStackIcon,
      current: window.location.pathname === nav_path.dashboard.tarikan,
    },
  ]);

  const { role } = useContext(DashboardContext);

  useEffect(() => {
    if (role === 'MASTER' || role === 'CS') {
      set_side_nav(side_nav => {
        side_nav.push(
          {
            name: 'Rekening',
            href: nav_path.dashboard.rekening,
            icon: AtSymbolIcon,
            current: window.location.pathname === nav_path.dashboard.rekening,
          },
          {
            name: 'Log',
            href: nav_path.dashboard.log,
            icon: CircleStackIcon,
            current: window.location.pathname === nav_path.dashboard.log,
          }
        );

        return [...side_nav];
      });
    }

    if (role === 'MASTER') {
      set_side_nav(side_nav => {
        side_nav.push(
          {
            name: 'User',
            href: nav_path.dashboard.user,
            icon: UserCircleIcon,
            current: window.location.pathname === nav_path.dashboard.user,
          },
          {
            name: 'Stats',
            href: nav_path.dashboard.stats,
            icon: ChartBarIcon,
            current: window.location.pathname === nav_path.dashboard.stats,
          }
        );

        return [...side_nav];
      });
    }
  }, [role]);

  return (
    <div className='hidden w-26 bg-emerald-700 overflow-y-auto md:block'>
      <div className='w-full py-6 flex flex-col items-center'>
        <div className='flex-shrink-0 flex items-center'>
          <img
            className='h-8 w-auto'
            src={LogoImg.default}
            alt='Logo Auto Deposit'
          />
        </div>

        <div className='flex-1 mt-6 w-full px-2 space-y-1'>
          {side_nav.map(item => (
            <NavLink
              key={item.name}
              to={item.href}
              className={({ isActive }) =>
                classNames(
                  isActive
                    ? 'bg-emerald-800 text-white'
                    : 'text-emerald-100 hover:bg-emerald-800 hover:text-white',
                  'group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium'
                )
              }
            >
              {({ isActive }) => (
                <>
                  <item.icon
                    className={classNames(
                      isActive
                        ? 'text-white'
                        : 'text-emerald-300 group-hover:text-white',
                      'h-6 w-6'
                    )}
                  />
                  <span className='mt-2'>{item.name}</span>
                </>
              )}
            </NavLink>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NarrowSidebar;
