import { ArrowPathIcon, PlusSmallIcon } from '@heroicons/react/24/outline';
import { PencilIcon } from '@heroicons/react/24/solid';
import { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { LeafIcon } from '../../../../Assets';
import { useAppSelector } from '../../../../store';
import ProfileDropdown from '../../Components/ProfileDropdown';
import AccountTabs from './AccountTabs';
import AddEditRekening from './AddEditRekening';

const NoAccount = () => (
  <div className='opacity-50 w-full text-center py-20 pr-20'>
    <div className='w-24 m-auto mb-3'>
      <img src={LeafIcon.default} alt='No forms' className='w-full' />
    </div>

    <div>Tidak Ada Rekening</div>
  </div>
);

const Rekening: React.FC = () => {
  const { active_tab } = useAppSelector(state => state.active_tab);
  const { accounts } = useAppSelector(state => state.accounts);

  const [display_add_edit_window, set_display_add_edit_window] =
    useState(false);
  const [editing_acc_id, set_editing_acc_id] = useState('');

  const displaying_acc = useMemo(() => {
    if (!active_tab) return accounts;
    else return accounts.filter(account => account.namaBank === active_tab);
  }, [accounts, active_tab]);

  const restore_bank_account = async (account_id: string) => {
    const uri = `/api/account/restore/${account_id}`;
    const response = await fetch(uri, { method: 'PATCH' });
    const res = await response.json();
    if (!response.ok) alert('Gagal mengaktifkan kembali rekening ini!');
    return res;
  };

  return (
    <>
      <Helmet>
        <title>{`[ ${process.env.REACT_APP_SELECTED_WEBSITE} ] Rekening - Auto Deposit`}</title>
      </Helmet>

      <header className='w-full'>
        <div className='relative z-10 flex-shrink-0 h-16 bg-white border-b border-gray-200 shadow-sm flex'>
          <div className='flex-1 flex justify-between px-4 sm:px-6'>
            <div className='flex-1 flex'>
              <AccountTabs />
            </div>

            <div className='ml-2 flex items-center space-x-4 sm:ml-6 sm:space-x-6'>
              <ProfileDropdown />

              <button
                type='button'
                className='flex bg-emerald-600 p-1 rounded-full items-center justify-center text-white hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500'
                onClick={() => set_display_add_edit_window(true)}
              >
                <PlusSmallIcon className='h-6 w-6' />
              </button>
            </div>
          </div>
        </div>
      </header>

      <div className='flex-1 flex items-stretch overflow-hidden'>
        <main className='flex-1'>
          <section className='min-w-0 flex-1 h-full flex flex-col lg:order-last py-3 px-5 overflow-auto'>
            <h1 className='text-3xl font-bold'>
              Rekening {process.env.REACT_APP_SELECTED_WEBSITE} -{' '}
              {active_tab || 'ALL'}
            </h1>

            {[false, true].map(deleted => (
              <div className='py-8'>
                <h2 className='text-2xl font-bold mb-4'>
                  {deleted ? 'Non-aktif' : 'Aktif'}
                </h2>

                <div className='mt-4 flex flex-col'>
                  <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
                    <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
                      {!displaying_acc.length && <NoAccount />}

                      <ul className='mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-4'>
                        {!!displaying_acc.length &&
                          displaying_acc
                            .filter(account => account.deleted === deleted)
                            .map(account => (
                              <li
                                key={account.id}
                                className='col-span-1 flex shadow-sm rounded-md'
                              >
                                <div
                                  className={` flex-shrink-0 flex items-center justify-center min-w-[4rem] w-fit text-white text-sm font-medium rounded-l-md ${
                                    deleted ? 'bg-gray-400' : 'bg-emerald-500'
                                  }`}
                                >
                                  {account.namaBank}
                                </div>

                                <div className='flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate'>
                                  <div className='flex-1 px-4 py-2 text-sm truncate'>
                                    <div className='text-gray-900 font-medium hover:text-gray-600'>
                                      {account.labelRekening}
                                    </div>

                                    <p className='text-gray-500'>
                                      {account.namaRekening} -{' '}
                                      {account.nomorRekening}
                                    </p>
                                  </div>

                                  <div className='flex-shrink-0 pr-2'>
                                    {!deleted ? (
                                      <button
                                        type='button'
                                        className='w-8 h-8 bg-white inline-flex items-center justify-center text-gray-400 rounded-full bg-transparent hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500'
                                        onClick={() => {
                                          set_editing_acc_id(account.id);
                                          set_display_add_edit_window(true);
                                        }}
                                      >
                                        <PencilIcon className='w-5 h-5' />
                                      </button>
                                    ) : (
                                      <button
                                        type='button'
                                        className='w-8 h-8 bg-white inline-flex items-center justify-center text-gray-400 rounded-full bg-transparent hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500'
                                        onClick={async () => {
                                          if (
                                            !window.confirm(
                                              `Aktifkan kembali bank ini ?`
                                            )
                                          )
                                            return;
                                          await restore_bank_account(
                                            account.id
                                          );
                                        }}
                                      >
                                        <ArrowPathIcon className='w-5 h-5' />
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </li>
                            ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </section>
        </main>
      </div>

      <AddEditRekening
        display_add_edit_window={display_add_edit_window}
        set_display_add_edit_window={set_display_add_edit_window}
        editing_acc_id={editing_acc_id}
        set_editing_acc_id={set_editing_acc_id}
      />
    </>
  );
};

export default Rekening;
