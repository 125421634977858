import { DateTime } from 'luxon';
import { useCallback, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { socket } from '../..';
import { ConnectionLost } from '../../Assets';
import { local_storage_var, nav_path, socket_name } from '../../constant';
import { DashboardContext } from '../../context/dashboard';
import { errMsg } from '../../helper';
import { useRole } from '../../hooks/use-role';
import { useAppDispatch, useAppSelector } from '../../store';
import { update_account } from '../../store/state/account';
import { get_accounts } from '../../store/state/account/request';
import { GetAccountsPayload } from '../../store/state/account/request/get';
import { set_locked } from '../../store/state/locked';
import { set_selected_acc } from '../../store/state/selected-acc';
import { set_warning_duration } from '../../store/state/warning-duration';
import { AccountModel } from '../../types';
import Disconnected from './Components/Disconnected';
import InputPIN from './Components/InputPIN';
import NarrowSidebar from './Components/Sidebar';

const { LAST_FOCUS, LOCKED } = local_storage_var;

const Dashboard: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { selected_acc } = useAppSelector(state => state.selected_acc);

  const check_if_locked = useCallback(() => {
    dispatch(set_locked(localStorage.getItem(LOCKED) === 'true'));
  }, [dispatch]);

  useEffect(() => {
    check_if_locked();

    const blur_handler = () => {
      localStorage.setItem(LAST_FOCUS, DateTime.now().toMillis().toString());
    };

    const focus_handler = () => {
      const last_focus = localStorage.getItem(LAST_FOCUS);
      if (!last_focus) return;
      const since_last_focus = DateTime.now().toMillis() - +last_focus;

      if (since_last_focus > 3 * 60 * 1000) {
        localStorage.setItem(LOCKED, 'true');
        check_if_locked();
      }
    };

    window.addEventListener('focus', focus_handler);
    window.addEventListener('blur', blur_handler);

    return () => {
      window.removeEventListener('focus', focus_handler);
      window.removeEventListener('blur', blur_handler);
    };
  }, [dispatch, check_if_locked]);

  const [accounts_fetched, set_accounts_fetched] = useState(false);

  useEffect(() => {
    (async () => {
      if (accounts_fetched) return;
      const { meta, payload } = await dispatch(get_accounts());
      const res = payload as GetAccountsPayload;

      if (meta.requestStatus === 'rejected') {
        alert(errMsg(res) || res.message);
        if (res.status === 401) navigate(nav_path.login);
        return;
      }

      set_accounts_fetched(true);
    })();
  }, [dispatch, navigate, accounts_fetched]);

  useEffect(() => {
    socket.on(`${socket_name.update_account}`, (e: AccountModel) => {
      dispatch(update_account(e));
      if (e.id === selected_acc?.id) dispatch(set_selected_acc(e));
    });

    return () => {
      socket.off(`${socket_name.update_account}`);
    };
  }, [dispatch, selected_acc?.id]);

  useEffect(() => {
    const get_warning_duration = async () => {
      const response = await fetch('/api/settings');
      const res = await response.json();

      if (!response.ok) {
        throw new Error(
          errMsg(res) || res.message || `Failed to get warning duration!`
        );
      }

      const new_warning_duration = res.data.warning_duration;

      dispatch(
        set_warning_duration(
          (new_warning_duration < 90 ? 90 : new_warning_duration) || 120
        )
      );
    };

    get_warning_duration();
  }, [dispatch]);

  const { role } = useRole();

  return (
    <>
      <DashboardContext.Provider value={{ role }}>
        <div className='h-screen flex'>
          <NarrowSidebar />

          <div className='flex-1 flex flex-col overflow-hidden'>
            <Outlet />
          </div>
        </div>

        <InputPIN />
        <Disconnected icon={ConnectionLost.default} />
      </DashboardContext.Provider>
    </>
  );
};

export default Dashboard;
