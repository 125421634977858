import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { ArrowIcon, FileIcon } from '../../../../../../Assets';
import LoadingIndicator from '../../../../../../Components/LoadingIndicator/LoadingIndicator';
import {
  classNames,
  errMsg,
  thousandSeparator,
} from '../../../../../../helper';
import { useAppDispatch, useAppSelector } from '../../../../../../store';
import { toggle_selected_pendingan } from '../../../../../../store/state/pendingan';
import { StatementModel } from '../../../../../../types';
import EditStatement from './EditStatement';

const LoadingStatements = () => (
  <div className='opacity-50 w-full text-center py-20'>
    <div className='w-24 m-auto mb-3'>
      <LoadingIndicator colorScheme='dark' />
    </div>

    <div>Mengambil Mutasi...</div>
  </div>
);

const NoStatements = () => (
  <div className='opacity-50 w-full text-center py-20'>
    <div className='w-24 m-auto mb-3'>
      <img src={FileIcon.default} alt='No forms' className='w-full' />
    </div>

    <div>Mutasi Kosong</div>
  </div>
);

const NoSelectedNomorRekening = () => (
  <div className='opacity-50 w-full text-center py-5 pr-20'>
    <div className='w-12 m-auto mb-3'>
      <img src={ArrowIcon.default} alt='No forms' className='w-full' />
    </div>

    <div>Pilih Nomor Rekening</div>
  </div>
);

interface StatementsProps {
  loading: boolean;
}

const Statements: React.FC<StatementsProps> = ({ loading }) => {
  const dispatch = useAppDispatch();

  const { pendingan } = useAppSelector(state => state.pendingan);
  const { forms } = useAppSelector(state => state.forms);
  const { active_tab } = useAppSelector(state => state.active_tab);
  const { selected_acc } = useAppSelector(state => state.selected_acc);

  const trash_statement = async (statementId: string) => {
    try {
      const confirmed = window.confirm(`Yakin ingin membuang mutasi ini?`);
      if (!confirmed) return;

      const response = await fetch(`/api/statement/trash/${statementId}`, {
        method: 'PATCH',
      });
      const res = await response.json();

      if (!response.ok) throw new Error(errMsg(res) || res.message);
    } catch (error: any) {
      alert(error.message || `Terjadi kesalahan ketika membuang statement.`);
    }
  };

  const [editing_statement, set_editing_statement] = useState<StatementModel>();
  const [show_edit_stmt_window, set_show_edit_stmt_window] =
    useState<boolean>(false);

  return (
    <>
      <div className='divide-y divide-gray-200 mt-1 block overflow-y-auto scrollbar-hide h-full pb-[134px]'>
        {loading ? (
          <LoadingStatements />
        ) : !selected_acc ? (
          <NoSelectedNomorRekening />
        ) : !pendingan.length ? (
          <NoStatements />
        ) : (
          pendingan.map((statement, index) => {
            return (
              <div
                className={classNames(
                  'flex items-center',
                  forms.findIndex(
                    form =>
                      form.pengirim.namaRekeningPlayer === statement.nama &&
                      form.amount === statement.nominal &&
                      form.penerima.bankPT.replace(/\d/g, '') === active_tab
                  ) >= 0
                    ? 'bg-emerald-50 hover:bg-emerald-100 rounded'
                    : 'bg-white hover:bg-gray-50'
                )}
                key={statement.id}
              >
                <div className='pl-3'>
                  <input
                    type='checkbox'
                    className='focus:ring-emerald-500 h-4 w-4 text-emerald-600 border-gray-300 rounded '
                    name={statement.id}
                    checked={statement.selected}
                    onChange={() => dispatch(toggle_selected_pendingan(index))}
                  />
                </div>

                <div className='py-5 px-3 flex-1'>
                  <div className='flex justify-between'>
                    <div className='min-w-0 flex-1 text-gray-500'>
                      <pre
                        className={classNames(
                          'text-sm max-w-[14rem] break-words mr-2 font-sans whitespace-pre-wrap',
                          forms.findIndex(
                            form =>
                              form.pengirim.namaRekeningPlayer ===
                              statement.nama
                          ) >= 0
                            ? 'font-semibold text-emerald-600'
                            : ''
                        )}
                      >
                        {statement.nama}
                      </pre>

                      <p
                        className={classNames(
                          'text-sm truncate mt-1',
                          forms.findIndex(
                            form =>
                              form.amount === statement.nominal &&
                              form.penerima.bankPT.replace(/\d/g, '') ===
                                active_tab
                          ) >= 0
                            ? 'font-semibold text-emerald-600'
                            : ''
                        )}
                      >
                        {thousandSeparator(statement.nominal.toFixed(0))}
                      </p>
                    </div>

                    <time
                      dateTime={statement.date}
                      className='flex-shrink-0 whitespace-nowrap text-sm text-gray-500 text-right'
                    >
                      {statement.date ? (
                        <>
                          <div className=''>
                            {DateTime.fromJSDate(new Date(statement.date))
                              .setLocale('id')
                              .toFormat('d LLLL yyyy')}
                          </div>
                          <div className='mt-1'>
                            {DateTime.fromJSDate(new Date(statement.date))
                              .setLocale('id')
                              .toFormat('HH:mm:ss')}
                          </div>
                        </>
                      ) : (
                        '-'
                      )}
                    </time>
                  </div>

                  <div className='mt-1 flex justify-between items-end'>
                    <pre className='text-sm text-gray-500 max-w-xs break-words mr-4 font-sans whitespace-pre-wrap'>
                      {statement.keterangan}
                    </pre>

                    <div className='flex items-end justify-end'>
                      <button
                        type='button'
                        className='inline-flex items-center p-1.5 border border-transparent rounded-full shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
                        onClick={() => trash_statement(statement.id)}
                      >
                        <TrashIcon className='h-4 w-4' />
                      </button>

                      <button
                        type='button'
                        className='inline-flex items-center p-1.5 border border-transparent rounded-full shadow-sm text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 ml-1'
                        onClick={() => {
                          set_editing_statement(statement);
                          set_show_edit_stmt_window(true);
                        }}
                      >
                        <PencilIcon className='h-4 w-4' />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>

      <EditStatement
        open={show_edit_stmt_window}
        set_open={set_show_edit_stmt_window}
        editing_statement={editing_statement}
      />
    </>
  );
};

export default Statements;
