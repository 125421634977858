import { createAsyncThunk } from '@reduxjs/toolkit';
import { FormModel, StatementModel } from '../../../../types';

interface ConfirmFormParams {
  form: FormModel;
  statements: StatementModel[];
}

export interface ConfirmFormPayload {
  message: string;
  data: { forms: FormModel[] };
  status?: number;
}

const check_and_confirm_form = createAsyncThunk(
  'form/confirm_form',

  async ({ form, statements }: ConfirmFormParams, { rejectWithValue }) => {
    const response = await fetch(`/api/form/confirm-manual`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        form,
        selectedStatements: statements.map(statement => statement.id),
      }),
    });

    const res: ConfirmFormPayload = await response.json();
    if (!response.ok)
      return rejectWithValue({ ...res, status: response.status });
    return res;
  }
);

export default check_and_confirm_form;
