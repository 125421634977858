import { createAsyncThunk } from '@reduxjs/toolkit';
import { FormModel } from '../../../../types';

const get_active_forms = createAsyncThunk(
  'form/get_active_forms',

  async (_void, { rejectWithValue }) => {
    const response = await fetch('/api/form/active');
    const res: { message: string; data: FormModel[] } = await response.json();

    if (!response.ok)
      return rejectWithValue({ ...res, status: response.status });

    return res;
  }
);

export { get_active_forms };
