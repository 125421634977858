import { Transition } from '@headlessui/react';
import { useAppSelector } from '../../../store';
import { Fragment } from 'react';

interface DisconnectedProps {
  icon: string;
}

const Disconnected: React.FC<DisconnectedProps> = ({ icon }) => {
  const { socket_id } = useAppSelector(state => state.socket_id);

  return (
    <Transition.Root show={socket_id === null} as={Fragment}>
      <Transition.Child
        as={Fragment}
        enter='ease-out duration-300'
        enterFrom='opacity-0'
        enterTo='opacity-100'
        leave='ease-in duration-200'
        leaveFrom='opacity-100'
        leaveTo='opacity-0'
      >
        <div className='fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50 z-20 text-white flex items-center justify-center pb-20 pr-32'>
          <div className='text-center'>
            <img src={icon} alt='Connection Lost' className='max-w-xl' />

            <div className='mt-10 text-3xl font-medium mr-10'>
              Koneksi Terputus
            </div>

            <div className='mr-10 mt-2 font-light'>
              Menyambungkan kembali...
            </div>
          </div>
        </div>
      </Transition.Child>
    </Transition.Root>
  );
};

export default Disconnected;
