export const classNames = (...classes: string[]) =>
  classes.filter(Boolean).join(' ');

export const thousandSeparator = (
  numbers: string | number,
  emptyPlaceholder: string = '-',
  separator: string = '.'
) => {
  if (!numbers) return emptyPlaceholder;
  if (isNaN(Number(numbers))) return emptyPlaceholder;
  let result = '';

  const numbersArr = numbers.toString().split('').reverse();

  for (let i in numbersArr) {
    if (i !== '0' && Number(i) % 3 === 0) result = separator + result;
    result = numbersArr[i] + result;
  }

  return result;
};

export const errMsg = (res: any) => {
  if (!res.errors || !res.errors.length) return undefined;
  return `${res.errors[0].message}${
    res.errors[0].field ? ` at ${res.errors[0].field}` : ''
  }`;
};

export const getPageArray = (currentPage: number, totalPage: number) => {
  const resArr = [];
  if (totalPage < 10) for (let i = 1; i < totalPage + 1; i++) resArr.push(i);

  if (totalPage >= 10) {
    if (currentPage - 4 <= 0) resArr.push(1, 2, 3, 4, 5, 6, 7, 8, 9);

    if (currentPage + 4 >= totalPage) {
      let i = totalPage;

      while (resArr.length < 9) {
        resArr.push(i);
        i--;
      }

      resArr.reverse();
    }

    if (currentPage - 4 > 0 && currentPage + 4 < totalPage) {
      let i = currentPage - 4;

      while (resArr.length < 9) {
        resArr.push(i);
        i++;
      }
    }
  }

  return resArr;
};
