import { useRef } from 'react';

const ChangePassword = () => {
  const old_password_ref = useRef<HTMLInputElement>(null);
  const new_password_ref = useRef<HTMLInputElement>(null);
  const confirm_password_ref = useRef<HTMLInputElement>(null);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const old_password = old_password_ref.current?.value;
      if (!old_password) throw new Error('Silahkan isi password lama');

      const new_password = new_password_ref.current?.value;
      if (!new_password) throw new Error('Silahkan isi password baru');

      const confirm_password = confirm_password_ref.current?.value;
      if (!confirm_password) throw new Error('Harap konfirmasi password baru');

      if (new_password.length < 6)
        throw new Error('Password baru minimal 6 karakter');

      if (new_password !== confirm_password)
        throw new Error('Password baru dan konfirmasi password tidak cocok');

      const response = await fetch('/api/user/change-password', {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          oldPassword: old_password,
          newPassword: new_password,
        }),
      });

      const res: { errors?: { message: string }[]; message?: string } =
        await response.json();

      if (res.errors && res.errors.length)
        throw new Error(res.errors[0].message);

      alert(res.message);

      old_password_ref.current.value = '';
      new_password_ref.current.value = '';
      confirm_password_ref.current.value = '';
    } catch (error: any) {
      alert(error.message || `Terjadi kesalahan ketika mengubah password`);
    }
  };

  return (
    <>
      <form className='w-1/5 space-y-3' onSubmit={handleSubmit}>
        <h1 className='text-2xl font-bold'>Ganti Password</h1>

        <input
          type='password'
          className='shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block w-full sm:text-sm border-gray-300 rounded-md'
          placeholder='Password Lama'
          ref={old_password_ref}
        />

        <input
          type='password'
          className='shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block w-full sm:text-sm border-gray-300 rounded-md'
          placeholder='Password Baru'
          ref={new_password_ref}
        />

        <input
          type='password'
          className='shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block w-full sm:text-sm border-gray-300 rounded-md'
          placeholder='Konfirmasi Password Baru'
          ref={confirm_password_ref}
        />

        <input
          type='submit'
          className='inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 w-full'
          value='Submit'
        />
      </form>
    </>
  );
};

export { ChangePassword };
