import { classNames } from '../../../../helper';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { set_active_tab } from '../../../../store/state/active-tab';
import { Banks } from '../../../../types';

const AccountTabs: React.FC = () => {
  const dispatch = useAppDispatch();
  const { active_tab } = useAppSelector(state => state.active_tab);
  const tabs = Object.values(Banks).map(bank => ({
    name: bank,
    current: active_tab === bank,
  }));

  return (
    <div className='flex items-center'>
      <div className='sm:hidden'>
        <select
          id='tabs'
          name='tabs'
          className='block w-full focus:ring-emerald-500 focus:border-emerald-500 border-gray-300 rounded-md'
          defaultValue=''
        >
          {tabs.map(tab => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>

      <div className='hidden sm:block'>
        <nav className='flex space-x-4'>
          {tabs.map(tab => (
            <div
              key={tab.name}
              className={classNames(
                tab.current
                  ? 'bg-emerald-100 text-emerald-700'
                  : 'text-gray-500 hover:text-gray-700',
                'px-3 py-2 font-medium text-sm rounded-md cursor-pointer'
              )}
              onClick={() => dispatch(set_active_tab(tab.name))}
            >
              {tab.name || 'ALL'}
            </div>
          ))}
        </nav>
      </div>
    </div>
  );
};

export default AccountTabs;
