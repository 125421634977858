import { Helmet } from 'react-helmet';
import ProfileDropdown from '../../Components/ProfileDropdown';
import { ChangePIN } from './ChangePIN';
import { ChangePassword } from './ChangePassword';
// import { ChangeAlias } from './SetAlias';
import { ChangePHPSESSID } from './SetPHPSESSID';

const Profile: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>{`[ ${process.env.REACT_APP_SELECTED_WEBSITE} ] Settings - Auto Deposit`}</title>
      </Helmet>

      <header className='w-full'>
        <div className='relative z-10 flex-shrink-0 h-16 bg-white border-b border-gray-200 shadow-sm flex'>
          <div className='flex-1 flex justify-between px-4 sm:px-6'>
            <div className='flex-1 flex'></div>

            <div className='ml-2 flex items-center space-x-4 sm:ml-6 sm:space-x-6'>
              <ProfileDropdown />
            </div>
          </div>
        </div>
      </header>

      <div className='py-6 px-8 flex space-x-14'>
        <ChangePassword />
        <ChangePIN />
        {/* <ChangeAlias /> */}
        <ChangePHPSESSID />
      </div>
    </>
  );
};

export default Profile;
