import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AccountModel } from '../../../types';
import { delete_account, get_accounts, save_account } from './request';

interface AccountState {
  accounts: AccountModel[];
}

const initial_state: AccountState = {
  accounts: [],
};

export const account_slice = createSlice({
  name: 'account',
  initialState: initial_state,

  reducers: {
    update_account: (state, action: PayloadAction<AccountModel>) => {
      const index = state.accounts.findIndex(
        account => account.id === action.payload.id
      );
      state.accounts[index] = action.payload;
    },
  },

  extraReducers: builder => {
    builder.addCase(get_accounts.fulfilled, (state, action) => {
      state.accounts = action.payload;
    });

    builder.addCase(save_account.fulfilled, (state, action) => {
      const index = state.accounts.findIndex(
        account => account.id === action.payload.data.id
      );

      if (index >= 0) state.accounts[index] = action.payload.data;
      else state.accounts.push(action.payload.data);

      state.accounts = state.accounts.sort((a, b) => {
        if (a.namaBank < b.namaBank) return -1;
        else if (a.namaBank > b.namaBank) return 1;
        else return +a.urutan - +b.urutan;
      });
    });

    builder.addCase(delete_account.fulfilled, (state, action) => {
      const index = state.accounts.findIndex(
        account => account.id === action.payload.data.id
      );
      state.accounts.splice(index, 1);
    });
  },
});

export default account_slice;
export const { update_account } = account_slice.actions;
