import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import LoadingIndicator from '../../../../../../Components/LoadingIndicator/LoadingIndicator';
import { errMsg } from '../../../../../../helper';
import { StatementModel } from '../../../../../../types';

interface EditStatementProps {
  open: boolean;
  set_open: React.Dispatch<React.SetStateAction<boolean>>;
  editing_statement: StatementModel | undefined;
}

const AREA = {
  UPDATE_STATEMENT: 'UPDATING_STATEMENT',
};

const EditStatement: React.FC<EditStatementProps> = ({
  editing_statement,
  open,
  set_open,
}) => {
  const [keterangan, set_keterangan] = useState('');
  const [nominal, set_nominal] = useState('');

  useEffect(() => {
    set_keterangan(editing_statement?.keterangan || '');
    set_nominal(editing_statement?.nominal.toString() || '');
  }, [editing_statement]);

  const { promiseInProgress: updating_statement } = usePromiseTracker({
    area: AREA.UPDATE_STATEMENT,
  });

  const update_statement = async () => {
    try {
      if (!editing_statement) return;
      if (!keterangan) throw new Error('Keterangan tidak ada...');
      if (!nominal) throw new Error('Nominal tidak ada...');

      const response = await fetch(`/api/statement/${editing_statement.id}`, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ keterangan, nominal }),
      });

      const res = await response.json();

      if (!response.ok) throw new Error(errMsg(res) || res.message);
      alert(res.message);
      set_open(false);
    } catch (error: any) {
      alert(error.message || `Terjadi kesalahan ketika mengupdate mutasi...`);
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={set_open}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed z-10 inset-0 overflow-y-auto'>
          <div className='flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-xl sm:w-full sm:p-6'>
                <div>
                  <div className='text-left'>
                    <Dialog.Title
                      as='h3'
                      className='text-lg leading-6 font-medium text-gray-900'
                    >
                      Edit Statement
                    </Dialog.Title>

                    <div className='mt-2'>
                      <div className='flex items-center w-full'>
                        <div className='mr-4 font-bold text-sm w-24'>
                          Keterangan
                        </div>

                        <div className='flex-grow'>
                          <input
                            type='text'
                            className='shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block w-full sm:text-sm border-gray-300 rounded'
                            value={keterangan}
                            onChange={e => set_keterangan(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className='flex items-center w-full mt-2'>
                        <div className='mr-4 font-bold text-sm w-24'>
                          Nominal
                        </div>

                        <div className='flex-grow'>
                          <input
                            type='text'
                            className='shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block w-full sm:text-sm border-gray-300 rounded'
                            value={nominal}
                            onChange={e => set_nominal(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='flex mt-3 space-x-3'>
                  <button
                    type='button'
                    className='inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-white border-emerald-600 text-emerald-600 text-base font-medium hover:bg-emerald-600 hover:text-white transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 sm:text-sm'
                    onClick={() => set_open(false)}
                  >
                    Batal
                  </button>

                  <button
                    type='button'
                    className='inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-emerald-600 text-base font-medium text-white hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 sm:text-sm'
                    onClick={() => {
                      if (updating_statement) return;
                      trackPromise(update_statement());
                    }}
                  >
                    {updating_statement ? (
                      <LoadingIndicator colorScheme='light' />
                    ) : (
                      'Simpan'
                    )}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default EditStatement;
