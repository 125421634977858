import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface SocketIdState {
  socket_id: string | null;
}

const initial_state: SocketIdState = {
  socket_id: '',
};

export const socket_id_slice = createSlice({
  name: 'socket-id',
  initialState: initial_state,

  reducers: {
    set_socket_id: (state, action: PayloadAction<string | null>) => {
      state.socket_id = action.payload;
    },
  },
});

export default socket_id_slice;
export const { set_socket_id } = socket_id_slice.actions;
