import { groupBy } from 'lodash';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { classNames } from '../../../../../helper';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { set_active_tab } from '../../../../../store/state/active-tab';
import { set_pendingan } from '../../../../../store/state/pendingan';
import { set_selected_acc } from '../../../../../store/state/selected-acc';
import { Banks, FormModel } from '../../../../../types';

const RekeningTabs: React.FC = () => {
  const dispatch = useAppDispatch();

  const { forms } = useAppSelector(state => state.forms);
  const { active_tab } = useAppSelector(state => state.active_tab);
  const { warning_duration } = useAppSelector(state => state.warning_duration);

  const groupped_forms = useMemo(() => {
    const obj = groupBy(forms, (obj: FormModel) =>
      obj.penerima.bankPT.replace(/\d/g, '')
    );
    let result: { [k: string]: FormModel[] } = {};

    Object.keys(obj).forEach(key => {
      const bank = key.replace(/\d/g, '');
      result[bank] = obj[key];
    });

    return result;
  }, [forms]);

  const tabs = Object.values(Banks)
    .filter(bank => bank)
    .map(bank => ({
      name: bank,
      count: groupped_forms[bank]?.length || 0,

      have_warning:
        groupped_forms[bank]?.findIndex(form => {
          const duration =
            DateTime.fromISO(form.timeRequest).diffNow().toMillis() / 1000;

          return Math.abs(duration) > warning_duration;
        }) >= 0 || 0,
    }));

  return (
    <>
      <div className='hidden sm:flex items-end'>
        <div>
          <nav className='-mb-px flex space-x-8'>
            {tabs.map(tab => (
              <div
                key={tab.name}
                className={classNames(
                  active_tab === tab.name
                    ? 'border-emerald-500 text-emerald-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200',
                  'whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm select-none cursor-pointer'
                )}
                onClick={() => {
                  dispatch(set_active_tab(tab.name));
                  dispatch(set_selected_acc(null));
                  dispatch(set_pendingan([]));
                }}
              >
                {tab.name}

                {tab.count ? (
                  <span
                    className={classNames(
                      tab.have_warning
                        ? 'bg-red-500 text-white'
                        : active_tab === tab.name
                        ? 'bg-emerald-100 text-emerald-600'
                        : 'bg-gray-100 text-gray-900',

                      'hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block transition-colors duration-200'
                    )}
                  >
                    {tab.count}
                  </span>
                ) : null}
              </div>
            ))}
          </nav>
        </div>
      </div>
    </>
  );
};

export default RekeningTabs;
