import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import React, { useEffect, useMemo, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Helmet } from 'react-helmet';
import { trackPromise } from 'react-promise-tracker';
import { useAppDispatch, useAppSelector } from '../../../store';
import { set_selected_date } from '../../../store/state/selected-date';
import { FormModel } from '../../../types';
import FilterAcc from '../Components/FilterAcc';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const AREA = { GET_HISTORIES: 'GET_HISTORIES', DOWNLOAD: 'DOWNLOAD' };

// === FUNCTION TO CHECK IF THE LAST THREE CHARACTERS IS '000' OR NOT === //
const checkThousands = (number: number): boolean => {
  const toString = number.toString();
  const lastThreeCharacters = toString.slice(-3);
  return lastThreeCharacters !== '000';
};

// const generateRandomColor = (includeAlpha = false) => {
//   const red = Math.floor(Math.random() * 256);
//   const green = Math.floor(Math.random() * 256);
//   const blue = Math.floor(Math.random() * 256);

//   if (includeAlpha) {
//     return `rgba(${red}, ${green}, ${blue}, 0.5)`;
//   }

//   return `rgb(${red}, ${green}, ${blue})`;
// };

const labels = Array.from({ length: 24 }, (_, index) => index.toString());

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Grafik Jam Ramai',
    },
  },
};

const Stats: React.FC = () => {
  const dispatch = useAppDispatch();

  const { selected_acc } = useAppSelector(state => state.selected_acc);
  const { selected_date } = useAppSelector(state => state.selected_date);
  const { accounts } = useAppSelector(state => state.accounts);

  const [histories, set_histories] = useState<FormModel[]>([]);

  // === GET ALL HISTORIES === //
  useEffect(() => {
    const get_histories = async () => {
      try {
        const queries = [];
        queries.push(`date=${selected_date}`);
        queries.push(`username=`);
        queries.push(`exact=false`);
        queries.push(`amount=`);

        const uri = `/api/form/history-all?${queries.join('&')}`;
        const response = await fetch(uri);

        const res: {
          message: string;
          data: FormModel[];
          totalPage: number;
          totalData: number;
          totalAmount: number;
        } = await response.json();

        if (!response.ok) throw new Error(res.message);
        set_histories(res.data);
      } catch (error: any) {
        alert(
          error.errors[0].message ||
            `Terjadi kesalahan ketika mengambil data history.`
        );
      }
    };

    trackPromise(get_histories(), AREA.GET_HISTORIES);
  }, [selected_date]);

  // === GET TOTAL UNIQUE DEPOSIT OF ALL HISTORIES === //
  const total_unique_deposit = useMemo(() => {
    return histories.reduce((count, filtered_history) => {
      if (checkThousands(filtered_history.amount)) {
        return count + 1;
      }
      return count;
    }, 0);
  }, [histories]);

  // === GET TOTAL UNIQUE DEPOSIT OF EACH ACCOUNT === //
  const account_total_unique_deposit = useMemo(() => {
    return accounts.map(account => {
      const filtered_histories = histories.filter(
        history => history.accountId === account.id
      );

      const unique_deposit = filtered_histories.reduce(
        (count, filtered_history) => {
          if (checkThousands(filtered_history.amount)) {
            return count + 1;
          }
          return count;
        },
        0
      );

      return unique_deposit;
    });
  }, [histories, accounts]);

  // === GET AVERAGE DURATION OF ALL HISTORIES === //
  const total_average_duration = useMemo(() => {
    const histories_less_than_hour = histories.filter(
      history => history.secondsDuration < 3600
    );

    const average =
      histories_less_than_hour.reduce(
        (acc, { secondsDuration }) => acc + secondsDuration,
        0
      ) / histories_less_than_hour.length;

    return average.toFixed(2);
  }, [histories]);

  // === GET AVERAGE DURATION OF EACH ACCOUNT === //
  const account_average_duration = useMemo(() => {
    return accounts.map(account => {
      const filtered_histories = histories.filter(
        history => history.accountId === account.id
      );

      const filtered_histories_less_than_hour = filtered_histories.filter(
        history => history.secondsDuration < 3600
      );

      const average_duration =
        filtered_histories_less_than_hour.reduce(
          (acc, { secondsDuration }) => acc + secondsDuration,
          0
        ) / filtered_histories_less_than_hour.length;

      return average_duration.toFixed(2);
    });
  }, [histories, accounts]);

  // === GET EACH BUSY HOUR OF EACH ACCOUNT === //
  const datasets = useMemo(() => {
    if (!selected_acc?.id) {
      const hour_count_array = histories.reduce((acc, history) => {
        if (
          new Date(history.timeRequest).getDate() ===
          new Date(selected_date).getDate()
        ) {
          const hour = new Date(history.timeRequest).getHours();
          acc[hour]++;
        }
        return acc;
      }, new Array(24).fill(0));

      const average = new Array(24).fill(histories.length / 24);

      return [
        {
          label: 'SEMUA REKENING',
          data: hour_count_array,
          borderColor: 'rgb(1, 50, 32)',
          backgroundColor: 'rgba(155, 251, 179, 0.5)',
          tension: 0.4,
        },
        {
          label: 'RATA-RATA',
          data: average,
          borderColor: 'rgb(135, 61, 240, 0.3)',
          backgroundColor: 'rgba(135, 61, 240, 0.3)',
          tension: 0.4,
        },
      ];
    } else {
      const filteredHistories = histories.filter(
        history => history.accountId === selected_acc?.id
      );
      const hour_count_array = filteredHistories.reduce(
        (acc, filteredHistory) => {
          if (
            new Date(filteredHistory.timeRequest).getDate() ===
            new Date(selected_date).getDate()
          ) {
            const hour = new Date(filteredHistory.timeRequest).getHours();
            acc[hour]++;
          }
          return acc;
        },
        new Array(24).fill(0)
      );

      const average = new Array(24).fill(histories.length / 24);

      return [
        {
          label: `${selected_acc?.labelRekening}, ${selected_acc?.namaRekening}`,
          data: hour_count_array,
          borderColor: 'rgb(1, 50, 32)',
          backgroundColor: 'rgba(155, 251, 179, 0.5)',
          tension: 0.4,
        },
        {
          label: `RATA-RATA`,
          data: average,
          borderColor: 'rgb(135, 61, 240, 0.3)',
          backgroundColor: 'rgba(135, 61, 240, 0.3)',
          tension: 0.4,
        },
      ];
    }
  }, [
    histories,
    selected_acc?.id,
    selected_acc?.labelRekening,
    selected_acc?.namaRekening,
    selected_date,
  ]);

  const account_history_length = useMemo(() => {
    return accounts.map(account => {
      const filtered_histories = histories.filter(
        history => history.accountId === account.id
      );

      return filtered_histories.length;
    });
  }, [histories, accounts]);

  const data = {
    labels,
    datasets: datasets,
  };

  return (
    <>
      <Helmet>
        <title>{`[ ${process.env.REACT_APP_SELECTED_WEBSITE} ] Stats - Auto Deposit`}</title>
      </Helmet>

      <header className='w-full'>
        <div className='relative z-10 flex-shrink-0 h-16 bg-white border-b border-gray-200 shadow-sm flex'>
          <div className='flex-1 flex justify-between px-4 sm:px-6'>
            <div className='flex-1 flex items-center'>
              <FilterAcc have_null_option={true} />
              <input
                type='date'
                className='shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block sm:text-sm border-gray-300 rounded-md ml-2'
                value={selected_date}
                onChange={e => {
                  dispatch(set_selected_date(e.target.value));
                }}
              />
            </div>
          </div>
        </div>
      </header>

      <main className='flex-1 overflow-auto'>
        <section className='min-w-0 flex-1 flex flex-col lg:order-last py-3 px-5'>
          <div className='flex justify-between items-center'>
            <h1 className='text-3xl font-bold'>
              Statistics{' '}
              {process.env.REACT_APP_SELECTED_WEBSITE} - [
              Account Aktif ]
            </h1>
            <div>
              <h1>
                Total deposit nominal unik:{' '}
                <span className='font-bold'>{total_unique_deposit}</span> ({' '}
                <span className='text-emerald-500 font-bold'>
                  {((total_unique_deposit / histories.length) * 100).toFixed(2)}{' '}
                  %
                </span>
                )
              </h1>
              <h1>
                Total durasi rata-rata :{' '}
                <span className='font-bold'>
                  {total_average_duration} detik
                </span>
              </h1>
            </div>
          </div>

          <div className='bg-white p-5 ring-black ring-1 ring-opacity-5 rounded-md shadow mt-2 h-1/3 flex items-center justify-center'>
            <Line data={data} options={options} className='w-full' />
          </div>

          <div className=' ring-black shadow ring-1 ring-opacity-5 md:rounded-lg mt-4 overflow-hidden'>
            <table className='min-w-full divide-y divide-gray-30'>
              <thead className='bg-gray-50 sticky top-0'>
                <tr>
                  <th
                    scope='col'
                    className='whitespace-nowrap px-2 py-3.5 text-sm font-semibold text-gray-900 text-left'
                  >
                    Label Rekening
                  </th>

                  <th
                    scope='col'
                    className='whitespace-nowrap px-2 py-3.5 text-sm font-semibold text-gray-900 text-left'
                  >
                    Nama Rekening
                  </th>

                  <th
                    scope='col'
                    className='whitespace-nowrap px-2 py-3.5 text-sm font-semibold text-gray-900 text-right'
                  >
                    Deposit Unik
                  </th>

                  <th
                    scope='col'
                    className='whitespace-nowrap px-2 py-3.5 text-sm font-semibold text-gray-900 text-right'
                  >
                    Durasi rata-rata
                  </th>
                </tr>
              </thead>
              <tbody className='divide-y divide-gray-200 bg-white'>
                {accounts.map((account, index) => {
                  if (
                    account.namaRekening !== '-' &&
                    +account_average_duration[index]
                  ) {
                    return (
                      <tr key={account.id}>
                        <td className='whitespace-nowrap px-2 py-2 text-sm '>
                          {account.labelRekening}
                        </td>
                        <td className='whitespace-nowrap px-2 py-2 text-sm '>
                          {account.namaRekening}
                        </td>
                        <td className='whitespace-nowrap px-2 py-2 text-sm text-right'>
                          {account_total_unique_deposit[index]} dari{' '}
                          {account_history_length[index]} ({' '}
                          <span className=' text-emerald-500'>
                            {(
                              (account_total_unique_deposit[index] /
                                histories.filter(
                                  history => history.accountId === account.id
                                ).length) *
                              100
                            ).toFixed(2)}
                            %
                          </span>{' '}
                          )
                        </td>
                        {parseInt(account_average_duration[index]) >
                        parseInt(total_average_duration) +
                          0.1 * parseInt(total_average_duration) ? (
                          <td className='whitespace-nowrap px-2 py-2 text-sm text-red-500 text-right'>
                            {account_average_duration[index]} detik
                          </td>
                        ) : parseInt(account_average_duration[index]) <
                          parseInt(total_average_duration) -
                            0.1 * parseInt(total_average_duration) ? (
                          <td className='whitespace-nowrap px-2 py-2 text-sm text-emerald-500 text-right'>
                            {account_average_duration[index]} detik
                          </td>
                        ) : (
                          <td className='whitespace-nowrap px-2 py-2 text-sm text-right'>
                            {account_average_duration[index]} detik
                          </td>
                        )}
                      </tr>
                    );
                  }
                  return null;
                })}
              </tbody>
            </table>
          </div>
        </section>
      </main>
    </>
  );
};

export default Stats;
