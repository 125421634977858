import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { Fragment } from 'react';
import { classNames } from '../../../../helper';
import { LogType } from '../../../../types';
import { QueryType } from '.';

interface FilterLogProps {
  query: QueryType;
  set_query: React.Dispatch<React.SetStateAction<QueryType>>;
}

const log_types: LogType[] = [
  '',
  'ACCOUNT',
  'FORM',
  'START',
  'STATEMENT',
  'USER',
];

const FilterRekening: React.FC<FilterLogProps> = ({ query, set_query }) => {
  return (
    <div className='w-44'>
      <Listbox
        value={query.log_type}
        onChange={e => {
          set_query(query => {
            query.page = 1;
            query.log_type = e;
            return { ...query };
          });
        }}
      >
        {({ open }) => (
          <div className='relative'>
            <Listbox.Button className='bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm'>
              <span className='block truncate'>
                {query.log_type || 'ALL TYPE'}
              </span>

              <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
                <ChevronDownIcon className='h-5 w-5 text-gray-400' />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave='transition ease-in duration-100'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Listbox.Options className='absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm'>
                {log_types.map(type => (
                  <Listbox.Option
                    key={type}
                    value={type}
                    className={({ active }) =>
                      classNames(
                        active ? 'text-white bg-emerald-600' : 'text-gray-900',
                        'cursor-default select-none relative py-2 pl-3 pr-9'
                      )
                    }
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? 'font-semibold' : 'font-normal',
                            'block truncate'
                          )}
                        >
                          {type || 'ALL TYPE'}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-emerald-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon className='h-5 w-5' />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        )}
      </Listbox>
    </div>
  );
};

export default FilterRekening;
