import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Banks } from '../../types';

interface ActiveTabState {
  active_tab: Banks;
}

const initial_state: ActiveTabState = {
  active_tab: Banks.ALL,
};

export const active_tab_slice = createSlice({
  name: 'active-tab',
  initialState: initial_state,

  reducers: {
    set_active_tab: (state, action: PayloadAction<Banks>) => {
      state.active_tab = action.payload;
    },
  },
});

export default active_tab_slice;
export const { set_active_tab } = active_tab_slice.actions;
