import {
  BackwardIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  PlusSmallIcon,
} from '@heroicons/react/24/solid';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { LeafIcon } from '../../../Assets';
import LoadingIndicator from '../../../Components/LoadingIndicator/LoadingIndicator';
import { classNames, getPageArray, thousandSeparator } from '../../../helper';
import { useAppDispatch, useAppSelector } from '../../../store';
import { set_selected_acc } from '../../../store/state/selected-acc';
import { set_selected_date } from '../../../store/state/selected-date';
import { AccountModel, StatementModel, isAccountModel } from '../../../types';
import FilterAcc from '../Components/FilterAcc';
import ProfileDropdown from '../Components/ProfileDropdown';

const NoStatements = () => (
  <div className='opacity-50 w-full text-center py-20'>
    <div className='w-24 m-auto'>
      <img src={LeafIcon.default} alt='No statements...' className='w-full' />
    </div>

    <div>Tidak ada mutasi...</div>
  </div>
);

const Trash: React.FC = () => {
  const dispatch = useAppDispatch();

  const { accounts } = useAppSelector(state => state.accounts);
  const { selected_acc } = useAppSelector(state => state.selected_acc);
  const { selected_date } = useAppSelector(state => state.selected_date);

  const [page, set_page] = useState(1);
  const [total_page, set_total_page] = useState(0);
  const [total_data, set_total_data] = useState(0);
  const [trash, set_trash] = useState<StatementModel[]>([]);

  const { promiseInProgress: getting_trashes } = usePromiseTracker();

  useEffect(() => {
    const get_trash = async () => {
      try {
        const uri = `/api/statement/trash/${
          selected_acc?.id || ''
        }?page=${page}&date=${selected_date}`;
        const response = await fetch(uri);

        const res: {
          message: string;
          data: {
            trashStatements: StatementModel[];
            totalPage: number;
            totalData: number;
            totalAmount: number;
          };
        } = await response.json();

        if (!response.ok) throw new Error(res.message);
        set_trash(res.data.trashStatements);
        set_total_data(res.data.totalData);
        set_total_page(res.data.totalPage);
      } catch (error: any) {
        alert(
          error.errors[0].message ||
            `Terjadi kesalahan ketika mengambil data history.`
        );
      }
    };

    trackPromise(get_trash());
  }, [selected_acc, page, selected_date]);

  useEffect(() => {
    set_page(1);
  }, [selected_acc, selected_date]);

  const select_rekening = (acc: AccountModel | null) => {
    if (!acc) return;

    const account = accounts.find(account => {
      const no_rek_sama = account.nomorRekening.includes(acc?.nomorRekening);
      const bank_sama = acc.namaBank.includes(account.namaBank);
      return no_rek_sama && bank_sama;
    });

    if (!account) return;
    dispatch(set_selected_acc(account));
  };

  const restore_from_trash = async (statementId: string) => {
    try {
      const confirmed = window.confirm(
        `Yakin ingin mengembalikan mutasi ini ke pendingan?`
      );
      if (!confirmed) return;
      const uri = `/api/statement/restore/${statementId}`;
      const method = 'PATCH';
      const response = await fetch(uri, { method });
      const res = await response.json();
      if (!response.ok)
        throw new Error((res.errors && res.errors[0]?.message) || res.message);
      const data: StatementModel = res.data.statement;
      set_trash(trashes => trashes.filter(trash => trash.id !== data.id));
    } catch (error: any) {
      alert(
        error.message ||
          `Terjadi kesalahan ketika mengembalikan mutasi dari Trash.`
      );
    }
  };

  return (
    <>
      <Helmet>
        <title>{`[ ${process.env.REACT_APP_SELECTED_WEBSITE} ] Trash - Auto Deposit`}</title>
      </Helmet>

      <header className='w-full'>
        <div className='relative z-10 flex-shrink-0 h-16 bg-white border-b border-gray-200 shadow-sm flex'>
          <div className='flex-1 flex justify-between px-4 sm:px-6'>
            <div className='flex-1 flex items-center'>
              <FilterAcc have_null_option={true} />

              <input
                type='date'
                className='shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block sm:text-sm border-gray-300 rounded-md ml-2'
                value={selected_date}
                onChange={e => dispatch(set_selected_date(e.target.value))}
              />
            </div>

            <div className='ml-2 flex items-center space-x-4 sm:ml-6 sm:space-x-6'>
              <ProfileDropdown />

              <button
                type='button'
                className='flex bg-emerald-600 p-1 rounded-full items-center justify-center text-white hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500'
              >
                <PlusSmallIcon className='h-6 w-6' />
              </button>
            </div>
          </div>
        </div>
      </header>

      <main className='flex-1 overflow-y-auto'>
        <section className='min-w-0 flex-1 h-full flex flex-col lg:order-last py-3 px-5'>
          <div className='flex justify-between items-center'>
            <h1 className='text-3xl font-bold'>
              Trash {process.env.REACT_APP_SELECTED_WEBSITE}
            </h1>
          </div>

          <div className='overflow-auto shadow ring-1 ring-black ring-opacity-5 md:rounded-lg mt-4'>
            <table className='min-w-full divide-y divide-gray-300 border-separate text-sm'>
              <thead className='bg-gray-50'>
                <tr>
                  <th
                    scope='col'
                    className='whitespace-nowrap px-2 py-3.5 pl-4 text-left text-sm font-semibold text-gray-900'
                  >
                    Agent
                  </th>

                  <th
                    scope='col'
                    className='whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900'
                  >
                    Tanggal Mutasi
                  </th>

                  <th
                    scope='col'
                    className='whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900'
                  >
                    Tanggal Trash
                  </th>

                  {!selected_acc && (
                    <th
                      scope='col'
                      className='whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900'
                    >
                      Bank - Nama - Nomor Rekening
                    </th>
                  )}

                  <th
                    scope='col'
                    className='whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900'
                  >
                    Keterangan Mutasi
                  </th>

                  <th
                    scope='col'
                    className='whitespace-nowrap px-2 py-3.5 text-right text-sm font-semibold text-gray-900'
                  >
                    Nominal
                  </th>

                  <th
                    scope='col'
                    className={classNames(
                      'whitespace-nowrap px-2 py-3.5 text-right text-sm font-semibold text-gray-900'
                    )}
                  >
                    Balance
                  </th>

                  <th
                    scope='col'
                    className='whitespace-nowrap px-2 py-3.5 text-center text-sm font-semibold text-gray-900'
                  >
                    Restore
                  </th>
                </tr>
              </thead>

              <tbody className='divide-y divide-gray-200 bg-white '>
                {getting_trashes ? (
                  <tr>
                    <td colSpan={8} className='text-center py-8'>
                      <LoadingIndicator colorScheme='dark' />
                    </td>
                  </tr>
                ) : trash.length ? (
                  trash.map(trash => (
                    <tr key={trash.id} className='text-gray-500'>
                      <td
                        className={classNames(
                          'whitespace-nowrap px-2 py-2 pl-4 text-sm'
                        )}
                      >
                        {trash.agentUsername}
                      </td>

                      <td className='whitespace-nowrap px-2 py-2 text-sm'>
                        {DateTime.fromISO(trash.date)
                          .setZone('UTC+7')
                          .setLocale('id')
                          .toFormat('d LLLL yyyy - HH:mm:ss')}
                      </td>

                      <td className='whitespace-nowrap px-2 py-2 text-sm'>
                        {DateTime.fromISO(trash.updatedAt)
                          .setZone('UTC+7')
                          .setLocale('id')
                          .toFormat('d LLLL yyyy - HH:mm:ss')}
                      </td>

                      {!selected_acc && (
                        <td className='whitespace-nowrap px-2 py-2 text-sm'>
                          <span
                            onClick={() =>
                              select_rekening(
                                isAccountModel(trash.accountId)
                                  ? trash.accountId
                                  : null
                              )
                            }
                            className='hover:text-emerald-500 active:opacity-70 cursor-pointer'
                          >
                            {isAccountModel(trash.accountId)
                              ? `${trash.accountId.namaBank}-${trash.accountId.namaRekening}-${trash.accountId.nomorRekening}`
                              : ''}
                          </span>
                        </td>
                      )}

                      <td className='whitespace-wrap px-2 py-2 text-sm whitespace-pre-wrap max-w-lg'>
                        {trash.keterangan}
                      </td>
                      <td className='whitespace-nowrap px-2 py-2 text-sm text-right font-mono font-light'>
                        {thousandSeparator(trash.nominal.toFixed(0))}
                      </td>

                      <td
                        className={classNames(
                          'whitespace-nowrap px-2 py-2 text-sm text-right font-mono font-light'
                        )}
                      >
                        {thousandSeparator(Math.round(trash.balance))}
                      </td>

                      <td className='whitespace-nowrap px-2 py-2 text-sm'>
                        <button
                          className='flex justify-center bg-emerald-500 p-1 rounded-full text-white w-fit m-auto cursor-pointer focus:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500'
                          onClick={() => restore_from_trash(trash.id)}
                        >
                          <BackwardIcon className='h-5 w-5' />
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={8}>
                      <NoStatements />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            <div className='bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6'>
              <div className='hidden sm:flex-1 sm:flex sm:items-center sm:justify-between'>
                <div>
                  <p className='text-sm text-gray-700'>
                    Menampilkan{' '}
                    <span className='font-medium'>
                      {total_data > 0 ? page * 100 - 99 : 0}
                    </span>{' '}
                    sampai{' '}
                    <span className='font-medium'>
                      {page * 100 > total_data ? total_data : page * 100}
                    </span>{' '}
                    dari <span className='font-medium'>{total_data}</span> hasil
                  </p>
                </div>

                <div>
                  <nav className='relative z-0 inline-flex rounded-md shadow-sm -space-x-px'>
                    <button
                      className='relative inline-flex justify-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 w-12'
                      onClick={() => set_page(1)}
                    >
                      <ChevronDoubleLeftIcon className='h-5 w-5' />
                    </button>

                    <button
                      className='relative inline-flex justify-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 w-12'
                      onClick={() => {
                        if (page === 1) return;
                        set_page(currentPage => currentPage - 1);
                      }}
                    >
                      <ChevronLeftIcon className='h-5 w-5' />
                    </button>

                    {getPageArray(page, total_page).map(el => (
                      <button
                        className={classNames(
                          page === el
                            ? 'z-10 bg-emerald-50 border-emerald-500 text-emerald-600'
                            : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50',

                          'hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium justify-center w-12'
                        )}
                        onClick={() => set_page(el)}
                        key={`Page Button #${el}`}
                      >
                        {el}
                      </button>
                    ))}

                    <button
                      className='relative inline-flex justify-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 w-12'
                      onClick={() => {
                        if (page === total_page) return;
                        set_page(currentPage => currentPage + 1);
                      }}
                    >
                      <ChevronRightIcon className='h-5 w-5' />
                    </button>

                    <button
                      className='relative inline-flex justify-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 w-12'
                      onClick={() => set_page(total_page)}
                    >
                      <ChevronDoubleRightIcon className='h-5 w-5' />
                    </button>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Trash;
