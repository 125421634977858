import {
  ArrowDownTrayIcon,
  CheckCircleIcon,
} from '@heroicons/react/24/outline';
import download from 'downloadjs';
import { DateTime } from 'luxon';
import { useCallback, useContext, useEffect, useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { Helmet } from 'react-helmet';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { LeafIcon } from '../../../../Assets';
import LoadingIndicator from '../../../../Components/LoadingIndicator/LoadingIndicator';
import { DashboardContext } from '../../../../context/dashboard';
import MutasiContext from '../../../../context/mutasi';
import { classNames, errMsg, thousandSeparator } from '../../../../helper';
import { useAppSelector } from '../../../../store';
import { StatementModel } from '../../../../types';
import FilterAcc from '../../Components/FilterAcc';
import ProfileDropdown from '../../Components/ProfileDropdown';
import AdjustBalance from './Components/AdjustBalance';
import AdjustID from './Components/AdjustID';
import Pagination from './Components/Pagination';

const AREA = { GET_MUTASI: 'GET_MUTASI', DOWNLOAD: 'DOWNLOAD' };

const NoStatements = () => (
  <div className='opacity-50 w-full text-center py-20'>
    <div className='w-24 m-auto'>
      <img src={LeafIcon.default} alt='No statements...' className='w-full' />
    </div>

    <div>Tidak ada mutasi...</div>
  </div>
);

export interface Query {
  page: number;
  date: string;
  keterangan: string;
  exact: boolean;
  nominal: string;
}

const Mutasi: React.FC = () => {
  const { selected_acc } = useAppSelector(state => state.selected_acc);

  const [statements, set_statements] = useState<StatementModel[]>([]);
  const [total_data, set_total_data] = useState(0);
  const [total_page, set_total_page] = useState(0);

  const [query, set_query] = useState<Query>({
    page: 1,
    date: DateTime.now().toFormat('yyyy-MM-dd'),
    keterangan: '',
    exact: false,
    nominal: '',
  });

  const { promiseInProgress: getting_stmt } = usePromiseTracker({
    area: AREA.GET_MUTASI,
  });
  const { promiseInProgress: downloading } = usePromiseTracker({
    area: AREA.DOWNLOAD,
  });

  const get_mutasi = useCallback(async () => {
    try {
      const { page, date, keterangan, nominal, exact } = query;
      if (!selected_acc) return;

      const queries = [];
      queries.push(`page=${page}`);
      queries.push(`date=${date}`);
      queries.push(`keterangan=${keterangan}`);
      queries.push(`nominal=${nominal}`);
      queries.push(`exact=${exact}`);

      const uri = `/api/statement/${selected_acc.id}?${queries.join('&')}`;
      const response = await fetch(uri);

      const res: {
        message: string;
        data: {
          statements: StatementModel[];
          totalData: number;
          totalPage: number;
        };
      } = await response.json();

      if (!response.ok) throw new Error(errMsg(res) || res.message);

      set_statements(res.data.statements);
      set_total_data(res.data.totalData);
      set_total_page(res.data.totalPage);
    } catch (error: any) {
      alert(error.message || `Terjadi kesalahan ketika mengambil mutasi...`);
    }
  }, [query, selected_acc]);

  useEffect(() => {
    if (selected_acc) trackPromise(get_mutasi(), AREA.GET_MUTASI);
  }, [query, selected_acc, get_mutasi]);

  const [show_adj_balance_window, set_show_adj_balance_window] =
    useState(false);
  const [show_adj_id_window, set_show_adj_id_window] = useState(false);

  const insert_klop = async () => {
    try {
      if (!selected_acc) return;
      const uri = `/api/statement/insert-klop/${selected_acc.id}`;
      const method = 'PATCH';
      const response = await fetch(uri, { method });
      const res = await response.json();
      if (!response.ok) throw new Error(errMsg(res) || res.message);
      alert(res.message);

      set_statements(statements => {
        const index = statements.findIndex(statement => {
          return statement.id === res.data.statement.id;
        });

        statements[index] = res.data.statement;
        return [...statements];
      });
    } catch (error: any) {
      alert(error.message || `Terjadi kesalahan ketika menambahkan klop...`);
    }
  };

  const download_statements = async () => {
    try {
      if (!selected_acc) return;
      const uri = `/api/statement/download/${selected_acc.id}?date=${query.date}`;
      const response = await fetch(uri);
      const res = await response.blob();
      download(
        res,
        `${query.date}-statement-${selected_acc.namaBank}-${selected_acc.namaRekening}.csv`
      );
    } catch (error: any) {
      alert(
        error.errors[0].message ||
          `Terjadi kesalahan ketika mengambil data history.`
      );
    }
  };

  const { role } = useContext(DashboardContext);

  return (
    <>
      <Helmet>
        <title>{`[ ${process.env.REACT_APP_SELECTED_WEBSITE} ] Mutasi - Auto Deposit`}</title>
      </Helmet>

      <header className='w-full'>
        <div className='relative z-10 flex-shrink-0 h-16 bg-white border-b border-gray-200 shadow-sm flex'>
          <div className='flex-1 flex justify-between px-4 sm:px-6'>
            <div className='flex-1 flex items-center'>
              <FilterAcc have_null_option={false} />

              <input
                type='date'
                className='shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block sm:text-sm border-gray-300 rounded-md ml-2'
                value={query.date}
                onChange={e =>
                  set_query(searchQuery => {
                    searchQuery.date = e.target.value;
                    searchQuery.page = 1;
                    return { ...searchQuery };
                  })
                }
              />

              <DebounceInput
                type='text'
                className='shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block sm:text-sm border-gray-300 rounded-md ml-2 w-36'
                value={query.nominal}
                placeholder='Nominal'
                onChange={e =>
                  set_query(searchQuery => {
                    searchQuery.nominal = e.target.value;
                    return { ...searchQuery };
                  })
                }
                debounceTimeout={500}
              />

              <DebounceInput
                debounceTimeout={500}
                type='text'
                className='shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block sm:text-sm border-gray-300 rounded-md ml-2 w-72'
                value={query.keterangan}
                placeholder='Keterangan...'
                onChange={e =>
                  set_query(searchQuery => {
                    searchQuery.keterangan = e.target.value;
                    searchQuery.page = 1;
                    return { ...searchQuery };
                  })
                }
              />

              <div className='ml-2'>
                <input
                  type='checkbox'
                  className='focus:ring-emerald-500 h-4 w-4 text-emerald-600 border-gray-300 rounded mb-0.5'
                  checked={query.exact}
                  onChange={e =>
                    set_query(searchQuery => {
                      searchQuery.exact = e.target.checked;
                      return { ...searchQuery };
                    })
                  }
                />

                <label className='text-sm ml-2'>Exact</label>
              </div>
            </div>

            <div className='flex items-center space-x-6 sm:ml-6 sm:space-x-3'>
              <ProfileDropdown />

              <button
                type='button'
                className='flex bg-emerald-600 p-1.5 rounded-full items-center justify-center text-white hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500'
                onClick={() => {
                  if (downloading) return;
                  trackPromise(download_statements(), AREA.DOWNLOAD);
                }}
              >
                {downloading ? (
                  <LoadingIndicator colorScheme='light' />
                ) : (
                  <ArrowDownTrayIcon className='h-5 w-5' />
                )}
              </button>
            </div>
          </div>
        </div>
      </header>

      <div className='flex-col flex-1 flex lg:flex-row items-stretch overflow-hidden'>
        <main className='flex-1 overflow-y-auto'>
          <section className='min-w-0 flex-1 h-full flex flex-col lg:order-last py-3 px-5'>
            <div className='flex justify-between items-center'>
              <h1 className='text-3xl font-bold'>
                Mutasi{' '}
                {process.env.REACT_APP_SELECTED_WEBSITE}
              </h1>

              <div className='flex space-x-2'>
                <button
                  type='button'
                  className='inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-full shadow-sm text-white bg-emerald-600 hover:bg-emerald-700 fozcus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500'
                  onClick={insert_klop}
                >
                  <CheckCircleIcon className='h-6 w-6 text-white mr-1' /> Klop
                </button>

                {(role === 'CS' || role === 'MASTER') && (
                  <button
                    type='button'
                    className='inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-full shadow-sm text-white bg-emerald-600 hover:bg-emerald-700 fozcus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500'
                    onClick={() => {
                      if (!selected_acc) return;
                      set_show_adj_balance_window(true);
                    }}
                  >
                    Cocokkan Saldo
                  </button>
                )}

                {(role === 'CS' || role === 'MASTER') && (
                  <button
                    type='button'
                    className='inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-full shadow-sm text-white bg-emerald-600 hover:bg-emerald-700 fozcus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500'
                    onClick={() => {
                      if (!selected_acc) return;
                      set_show_adj_id_window(true);
                    }}
                  >
                    Cocokkan ID
                  </button>
                )}
              </div>
            </div>

            <div className='overflow-auto shadow ring-1 ring-black ring-opacity-5 md:rounded-lg mt-4'>
              <table className='min-w-full divide-y divide-gray-300 border-separate'>
                <thead className='bg-gray-50'>
                  <tr className='whitespace-nowrap text-sm font-semibold text-gray-900'>
                    <th className='px-2 py-3.5 pl-4 text-center'>Tanggal</th>
                    <th className='px-2 py-3.5 text-center'>Jam</th>
                    <th className='px-2 py-3.5 text-left'>Username</th>
                    <th className='px-2 py-3.5 text-left'>Nama</th>
                    <th className='px-2 py-3.5 text-left'>Keterangan</th>
                    <th className='px-2 py-3.5 text-right'>Debet</th>
                    <th className='px-2 py-3.5 text-right'>Kredit</th>
                    <th className='px-2 py-3.5 text-right'>Saldo</th>
                    <th className='px-2 py-3.5 text-center'>Agent</th>
                    <th className='px-2 py-3.5 text-center'>Klop</th>
                  </tr>
                </thead>

                <tbody className='divide-y divide-gray-200 bg-white'>
                  {getting_stmt ? (
                    <tr>
                      <td colSpan={10} className='text-center py-8'>
                        <LoadingIndicator colorScheme='dark' />
                      </td>
                    </tr>
                  ) : statements.length ? (
                    statements.map(statement => {
                      const nominal = thousandSeparator(
                        Math.round(statement.nominal)
                      );

                      return (
                        <tr
                          key={statement.id}
                          className={classNames(
                            statement.membal
                              ? 'text-yellow-500 font-semibold'
                              : 'text-gray-500',
                            'whitespace-nowrap text-sm',
                            statement.confirmed && !statement.formId
                              ? 'bg-red-300'
                              : ''
                          )}
                        >
                          <td className='px-2 py-2 pl-4 text-center'>
                            {DateTime.fromISO(
                              statement.date || new Date().toISOString()
                            )
                              .setLocale('id')
                              .toFormat('d LLLL yyyy')}
                          </td>

                          <td className='px-2 py-2 text-center'>
                            {DateTime.fromISO(
                              statement.date || new Date().toISOString()
                            )
                              .setLocale('id')
                              .toFormat('HH:mm:ss')}
                          </td>

                          <td className='px-2 py-2 max-w-xl whitespace-pre-wrap'>
                            {statement.playerUsername}
                          </td>
                          <td className='px-2 py-2 max-w-xl whitespace-pre-wrap'>
                            {statement.nama}
                          </td>
                          <td className='px-2 py-2 max-w-xl whitespace-pre-wrap'>
                            {statement.keterangan}
                          </td>

                          <td
                            className={classNames(
                              'px-2 py-2 text-right font-mono',
                              statement.membal ? 'font-medium' : 'font-light'
                            )}
                          >
                            {statement.type === 'DB' ? nominal : ''}
                          </td>

                          <td
                            className={classNames(
                              'px-2 py-2 text-right font-mono',
                              statement.membal ? 'font-medium' : 'font-light'
                            )}
                          >
                            {statement.type === 'CR' ? nominal : ''}
                          </td>

                          <td
                            className={classNames(
                              'px-2 py-2 text-right font-mono',
                              statement.membal ? 'font-medium' : 'font-light'
                            )}
                          >
                            {thousandSeparator(
                              Math.round(statement.balance),
                              '0'
                            )}
                          </td>

                          <td className='px-2 py-2 text-center'>
                            {statement.agentUsername}
                          </td>
                          <td className='px-2 py-2 pr-4 text-center'>
                            {statement.klop.join(', ')}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={10}>
                        <NoStatements />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

              <Pagination
                query={query}
                set_query={set_query}
                total_data={total_data}
                total_page={total_page}
              />
            </div>
          </section>
        </main>
      </div>

      <MutasiContext.Provider value={{ get_mutasi }}>
        <AdjustBalance
          show_adj_balance_window={show_adj_balance_window}
          set_show_adj_balance_window={set_show_adj_balance_window}
        />

        <AdjustID
          set_show_adj_id_window={set_show_adj_id_window}
          show_adj_id_window={show_adj_id_window}
        />
      </MutasiContext.Provider>
    </>
  );
};

export default Mutasi;
