import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { Fragment, useEffect, useState } from 'react';
import { QueryType } from '.';
import { classNames, errMsg } from '../../../../helper';
import { UserModel } from '../../../../types';

interface FilterAgentProps {
  query: QueryType;
  set_query: React.Dispatch<React.SetStateAction<QueryType>>;
}

const get_agents = async () => {
  const response = await fetch('/api/user/get-all');
  const res: { message: string; data: UserModel[] } = await response.json();
  if (!response.ok) throw new Error(errMsg(res) || res.message);
  return res.data;
};

const FilterAgent: React.FC<FilterAgentProps> = ({ query, set_query }) => {
  const [agents, set_agents] = useState<UserModel[]>([]);

  useEffect(() => {
    get_agents().then(agents => set_agents(agents));
  }, []);

  return (
    <div className='w-40'>
      <Listbox
        value={query.agent}
        onChange={e => {
          set_query(query => {
            query.page = 1;
            query.agent = e;
            return { ...query };
          });
        }}
      >
        {({ open }) => (
          <div className='relative'>
            <Listbox.Button className='bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm'>
              <span className='block truncate'>
                {query.agent.toUpperCase() || 'ALL AGENT'}
              </span>

              <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
                <ChevronDownIcon className='h-5 w-5 text-gray-400' />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave='transition ease-in duration-100'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Listbox.Options className='absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm'>
                <Listbox.Option
                  value=''
                  className={({ active }) =>
                    classNames(
                      active ? 'text-white bg-emerald-600' : 'text-gray-900',
                      'cursor-default select-none relative py-2 pl-3 pr-9'
                    )
                  }
                >
                  {({ selected, active }) => (
                    <>
                      <span
                        className={classNames(
                          selected ? 'font-semibold' : 'font-normal',
                          'block truncate'
                        )}
                      >
                        ALL AGENT
                      </span>

                      {selected ? (
                        <span
                          className={classNames(
                            active ? 'text-white' : 'text-emerald-600',
                            'absolute inset-y-0 right-0 flex items-center pr-4'
                          )}
                        >
                          <CheckIcon className='h-5 w-5' />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>

                {agents.map(agent => (
                  <Listbox.Option
                    key={agent.id}
                    value={agent.username}
                    className={({ active }) =>
                      classNames(
                        active ? 'text-white bg-emerald-600' : 'text-gray-900',
                        'cursor-default select-none relative py-2 pl-3 pr-9'
                      )
                    }
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? 'font-semibold' : 'font-normal',
                            'block truncate'
                          )}
                        >
                          {agent.username.toUpperCase() || 'ALL AGENT'}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-emerald-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon className='h-5 w-5' />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        )}
      </Listbox>
    </div>
  );
};

export default FilterAgent;
