import { PlusSmallIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { errMsg } from '../../../../helper';
import { UserModel } from '../../../../types';
import ProfileDropdown from '../../Components/ProfileDropdown';
import AddEditUser from './AddEditUser';

const User: React.FC = () => {
  const [display_add_edit_window, set_display_add_edit_window] =
    useState(false);

  const [users, set_users] = useState<UserModel[]>([]);
  const [editing_user, set_editing_user] = useState<UserModel | undefined>();

  useEffect(() => {
    const get_users = async () => {
      try {
        const response = await fetch('/api/user/get-all');
        const res = await response.json();
        if (!response.ok) throw new Error(errMsg(res) || res.message);
        set_users(res.data);
      } catch (error: any) {
        alert(
          error.message || `Terjadi kesalahan ketika mengambil data agent.`
        );
      }
    };

    get_users();
  }, []);

  return (
    <>
      <Helmet>
        <title>{`[ ${process.env.REACT_APP_SELECTED_WEBSITE} ] User - Auto Deposit`}</title>
      </Helmet>

      <header className='w-full'>
        <div className='relative z-10 flex-shrink-0 h-16 bg-white border-b border-gray-200 shadow-sm flex'>
          <div className='flex-1 flex justify-between px-4 sm:px-6'>
            <div className='flex-1 flex'></div>

            <div className='ml-2 flex items-center space-x-4 sm:ml-6 sm:space-x-6'>
              <ProfileDropdown />

              <button
                type='button'
                className='flex bg-emerald-600 p-1 rounded-full items-center justify-center text-white hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500'
                onClick={() => set_display_add_edit_window(true)}
              >
                <PlusSmallIcon className='h-6 w-6' />
              </button>
            </div>
          </div>
        </div>
      </header>

      <div className='py-3 px-5 overflow-auto'>
        <h1 className='text-3xl font-bold'>
          Agent Alias {process.env.REACT_APP_SELECTED_WEBSITE}
        </h1>

        {[true, false].map(active => (
          <div
            className='py-8'
            key={`User-${active ? 'Active' : 'Non-Active'}`}
          >
            <h2 className='text-2xl font-bold ml-4 mb-8'>
              {active ? 'Aktif' : 'Non-Aktif'}
            </h2>

            <ul className='grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-4 md:gap-x-6 lg:max-w-5xl lg:gap-x-8 lg:gap-y-12 xl:grid-cols-6'>
              {users
                .filter(user => user.active === active)
                .map((user, index) => (
                  <li
                    key={user.id}
                    onClick={() => {
                      set_editing_user(user);
                      set_display_add_edit_window(true);
                    }}
                    className='cursor-pointer active:opacity-90'
                  >
                    <div className='space-y-4'>
                      <img
                        className='mx-auto h-20 w-20 rounded-full lg:w-24 lg:h-24 shadow'
                        src={`https://xsgames.co/randomusers/avatar.php?g=pixel&id=${index}`}
                        alt={user.username}
                      />

                      <div className='space-y-2'>
                        <div className='text-xs font-medium lg:text-sm text-center'>
                          <h3 className={user.active ? '' : 'text-red-500'}>
                            {user.username} - ({!user.active ? 'Non-' : ''}
                            Aktif)
                          </h3>

                          <p className='text-emerald-600'>{user.role}</p>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
            </ul>
          </div>
        ))}
      </div>

      <AddEditUser
        user={editing_user}
        display_add_edit_window={display_add_edit_window}
        set_users={set_users}
        set_display_add_edit_window={set_display_add_edit_window}
        set_editing_user={set_editing_user}
      />
    </>
  );
};

export default User;
