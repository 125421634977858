import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/solid';
import download from 'downloadjs';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { Helmet } from 'react-helmet';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { LeafIcon } from '../../../Assets';
import LoadingIndicator from '../../../Components/LoadingIndicator/LoadingIndicator';
import { classNames, getPageArray, thousandSeparator } from '../../../helper';
import { useAppDispatch, useAppSelector } from '../../../store';
import { set_selected_acc } from '../../../store/state/selected-acc';
import { set_selected_date } from '../../../store/state/selected-date';
import {
  AccountModel,
  FormModel,
  StatementModel,
  isAccountModel,
  isStatementModel,
} from '../../../types';
import FilterAcc from '../Components/FilterAcc';
import ProfileDropdown from '../Components/ProfileDropdown';

const AREA = { GET_HISTORIES: 'GET_HISTORIES', DOWNLOAD: 'DOWNLOAD' };

const NoHistories = () => (
  <div className='opacity-50 w-full text-center py-20'>
    <div className='w-24 m-auto'>
      <img src={LeafIcon.default} alt='No statements...' className='w-full' />
    </div>

    <div>Tidak ada histrory...</div>
  </div>
);

const History: React.FC = () => {
  const dispatch = useAppDispatch();

  const { promiseInProgress: getting_history } = usePromiseTracker({
    area: AREA.GET_HISTORIES,
  });

  const { promiseInProgress: downloading } = usePromiseTracker({
    area: AREA.DOWNLOAD,
  });

  const { selected_acc } = useAppSelector(state => state.selected_acc);
  const { accounts } = useAppSelector(state => state.accounts);
  const { selected_date } = useAppSelector(state => state.selected_date);
  const { warning_duration } = useAppSelector(state => state.warning_duration);

  const [page, set_page] = useState(1);
  const [total_page, set_total_page] = useState(0);
  const [total_data, set_total_data] = useState(0);
  const [total_amount, set_total_amoun] = useState(0);
  const [histories, set_histories] = useState<FormModel[]>([]);
  const [username, set_username] = useState('');
  const [exact, set_exact] = useState(false);
  const [amount, set_amount] = useState('');
  const [latest_stmt, set_latest_stmt] = useState<StatementModel | null>(null);

  useEffect(() => {
    const get_histories = async () => {
      try {
        const queries = [];
        queries.push(`page=${page}`);
        queries.push(`date=${selected_date}`);
        queries.push(`username=${username}`);
        queries.push(`exact=${exact}`);
        queries.push(`amount=${amount}`);

        const uri = `/api/form/history/${selected_acc?.id || ''}?${queries.join(
          '&'
        )}`;

        const response = await fetch(uri);

        const res: {
          message: string;
          data: FormModel[];
          totalPage: number;
          totalData: number;
          totalAmount: number;
        } = await response.json();

        if (!response.ok) throw new Error(res.message);
        set_histories(res.data);
        set_total_data(res.totalData);
        set_total_page(res.totalPage);
        set_total_amoun(res.totalAmount);
      } catch (error: any) {
        alert(
          error.errors[0].message ||
            `Terjadi kesalahan ketika mengambil data history.`
        );
      }
    };

    trackPromise(get_histories(), AREA.GET_HISTORIES);
  }, [selected_acc?.id, page, selected_date, username, exact, amount]);

  useEffect(() => {
    const get_latest_stmt = async () => {
      try {
        if (!selected_acc?.id) return;
        const uri = `/api/statement/get-last/${selected_acc?.id}`;
        const response = await fetch(uri);

        const res: { message: string; data: StatementModel } =
          await response.json();

        if (!response.ok) throw new Error(res.message);
        set_latest_stmt(res.data);
      } catch (error: any) {
        alert(
          error.message || `Terjadi kesalahan ketika mengambil data history.`
        );
      }
    };

    get_latest_stmt();
  }, [selected_acc?.id]);

  const select_acc = (acc: AccountModel | null) => {
    if (!acc) return;

    const account = accounts.find(account => {
      const no_rek_sama = account.nomorRekening.includes(acc?.nomorRekening);
      const bank_sama = account.namaBank.includes(acc?.namaBank);
      return no_rek_sama && bank_sama;
    });

    if (!account) return;
    dispatch(set_selected_acc(account));
    set_page(1);
  };

  const download_histories = async () => {
    try {
      const query = [];
      query.push(`date=${selected_date}`);

      const uri = `/api/form/download/${selected_acc?.id || ''}?${query.join(
        '&'
      )}`;

      const response = await fetch(uri);
      const res = await response.blob();

      download(
        res,
        `${selected_date}-history-${
          selected_acc
            ? `${selected_acc.namaBank}-${selected_acc.namaRekening}`
            : 'ALL'
        }.csv`
      );
    } catch (error: any) {
      alert(
        error.errors[0].message ||
          `Terjadi kesalahan ketika mengambil data history.`
      );
    }
  };

  useEffect(() => {
    set_page(1);
  }, [selected_acc]);

  return (
    <>
      <Helmet>
        <title>{`[ ${process.env.REACT_APP_SELECTED_WEBSITE} ] History - Auto Deposit`}</title>
      </Helmet>

      <header className='w-full'>
        <div className='relative z-10 flex-shrink-0 h-16 bg-white border-b border-gray-200 shadow-sm flex'>
          <div className='flex-1 flex justify-between px-4 sm:px-6'>
            <div className='flex-1 flex items-center'>
              <FilterAcc have_null_option={true} />

              <input
                type='date'
                className='shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block sm:text-sm border-gray-300 rounded-md ml-2'
                value={selected_date}
                onChange={e => {
                  dispatch(set_selected_date(e.target.value));
                  set_page(1);
                }}
              />

              <DebounceInput
                type='text'
                className='shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block sm:text-sm border-gray-300 rounded-md ml-2'
                value={amount}
                placeholder='Nominal'
                onChange={e => set_amount(e.target.value)}
                debounceTimeout={500}
              />

              <DebounceInput
                type='text'
                className='shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block sm:text-sm border-gray-300 rounded-md ml-2'
                value={username}
                placeholder='Username'
                onChange={e => set_username(e.target.value)}
                debounceTimeout={500}
              />

              <div className='ml-2'>
                <input
                  type='checkbox'
                  className='focus:ring-emerald-500 h-4 w-4 text-emerald-600 border-gray-300 rounded mb-0.5'
                  checked={exact}
                  onChange={e => set_exact(e.target.checked)}
                />

                <label className='text-sm ml-2'>Exact</label>
              </div>
            </div>

            <div className='ml-2 flex items-center sm:ml-6 space-x-3'>
              <ProfileDropdown />

              <button
                type='button'
                className='flex bg-emerald-600 p-1.5 rounded-full items-center justify-center text-white hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500'
                onClick={() => {
                  if (downloading) return;
                  trackPromise(download_histories(), AREA.DOWNLOAD);
                }}
              >
                {downloading ? (
                  <LoadingIndicator colorScheme='light' />
                ) : (
                  <ArrowDownTrayIcon className='h-5 w-5' />
                )}
              </button>
            </div>
          </div>
        </div>
      </header>

      <main className='flex-1 overflow-y-auto'>
        <section className='min-w-0 flex-1 h-full flex flex-col lg:order-last py-3 px-5'>
          <div className='flex justify-between items-center'>
            <h1 className='text-3xl font-bold'>
              History {process.env.REACT_APP_SELECTED_WEBSITE}
            </h1>

            <div>
              <div className='font-bold text-emerald-500 text-right'>
                Total: {thousandSeparator(total_amount)}
              </div>

              <div className='text-emerald-500 text-sm text-right'>
                {selected_acc &&
                  selected_acc.namaBank !== 'BRI' &&
                  `Saldo Akhir: 
                ${thousandSeparator(latest_stmt?.balance?.toFixed(0) || '')}`}
              </div>
            </div>
          </div>

          <div className='overflow-auto shadow ring-1 ring-black ring-opacity-5 md:rounded-lg mt-4'>
            <table className='min-w-full divide-y divide-gray-300'>
              <thead className='bg-gray-50'>
                <tr>
                  <th
                    scope='col'
                    className='whitespace-nowrap px-2 py-3.5 pl-4 text-left text-sm font-semibold text-gray-900'
                  >
                    Agent
                  </th>

                  <th
                    scope='col'
                    className='whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900'
                  >
                    Waktu Deposit
                  </th>

                  <th
                    scope='col'
                    className='whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900'
                  >
                    Waktu Confirm
                  </th>

                  <th
                    scope='col'
                    className='whitespace-nowrap px-2 py-3.5 text-right text-sm font-semibold text-gray-900'
                  >
                    Durasi
                  </th>

                  <th
                    scope='col'
                    className='whitespace-nowrap px-2 py-3.5 text-center text-sm font-semibold text-gray-900'
                  >
                    Player ID
                  </th>

                  <th
                    scope='col'
                    className='whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900'
                  >
                    Rekening Asal
                  </th>

                  {!selected_acc && (
                    <th
                      scope='col'
                      className='whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900'
                    >
                      Rekening Tujuan
                    </th>
                  )}

                  <th
                    scope='col'
                    className='whitespace-nowrap px-2 py-3.5 text-right text-sm font-semibold text-gray-900'
                  >
                    Jumlah
                  </th>

                  <th
                    scope='col'
                    className='whitespace-nowrap px-2 py-3.5 text-right text-sm font-semibold text-gray-900'
                  >
                    Saldo
                  </th>

                  <th
                    scope='col'
                    className='whitespace-nowrap px-2 py-3.5 text-center text-sm font-semibold text-gray-900'
                  >
                    Tanggal Mutasi
                  </th>

                  <th
                    scope='col'
                    className='whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900'
                  >
                    Keterangan
                  </th>
                </tr>
              </thead>

              <tbody className='divide-y divide-gray-200 bg-white'>
                {getting_history ? (
                  <tr>
                    <td colSpan={11} className='text-center py-8'>
                      <LoadingIndicator colorScheme='dark' />
                    </td>
                  </tr>
                ) : histories.length ? (
                  histories.map((history, index) => {
                    const statement = isStatementModel(history.statementId)
                      ? history.statementId
                      : null;

                    let account: AccountModel | null = null;

                    if (statement)
                      account = isAccountModel(statement.accountId)
                        ? statement.accountId
                        : null;

                    const {
                      bankPlayer,
                      namaRekeningPlayer,
                      nomorRekeningPlayer,
                    } = history.pengirim;

                    const timeConfirmed = DateTime.fromISO(
                      history.timeConfirmed
                    ).setLocale('id');

                    const statementTime = DateTime.fromISO(
                      statement?.date || new Date().toISOString()
                    ).setLocale('id');

                    return (
                      <tr
                        key={history?.id || `History#$${index}`}
                        className={
                          statement && statement?.membal
                            ? 'text-yellow-500 font-semibold'
                            : 'text-gray-500'
                        }
                      >
                        <td
                          className={classNames(
                            'whitespace-nowrap px-2 py-2 pl-4 text-sm'
                          )}
                        >
                          {history.agentId}
                        </td>

                        <td className='whitespace-nowrap px-2 py-2 text-sm'>
                          {DateTime.fromISO(history.timeRequest)
                            .setLocale('id')
                            .toFormat('d LLLL yyyy - HH:mm:ss')}
                        </td>

                        <td className='whitespace-nowrap px-2 py-2 text-sm'>
                          {timeConfirmed.toFormat('d LLLL yyyy - HH:mm:ss')}
                        </td>

                        <td
                          className={classNames(
                            'whitespace-nowrap px-2 py-2 text-sm text-right',
                            statement && statement?.membal
                              ? ''
                              : history.secondsDuration > warning_duration
                              ? 'text-red-500'
                              : history.secondsDuration <= warning_duration / 2
                              ? 'text-emerald-500'
                              : ''
                          )}
                        >
                          {thousandSeparator(history.secondsDuration)} detik
                        </td>

                        <td className='whitespace-nowrap px-2 py-2 text-sm text-center'>
                          {history.username}
                        </td>

                        <td className='whitespace-nowrap px-2 py-2 text-sm'>
                          {bankPlayer} - {nomorRekeningPlayer} -{' '}
                          {namaRekeningPlayer}
                        </td>

                        {!selected_acc && (
                          <td className='whitespace-nowrap px-2 py-2 text-sm'>
                            <span
                              onClick={() => select_acc(account)}
                              className='hover:text-emerald-500 active:opacity-70 cursor-pointer'
                            >
                              {statement && account
                                ? `${account.namaBank} - ${account.nomorRekening} - ${account.namaRekening}`
                                : ''}
                            </span>
                          </td>
                        )}

                        <td
                          className={classNames(
                            'whitespace-nowrap px-2 py-2 text-sm text-right font-mono',
                            statement
                              ? statement?.membal
                                ? 'font-medium'
                                : 'font-light'
                              : ''
                          )}
                        >
                          {thousandSeparator(history.amount)}
                        </td>

                        <td
                          className={classNames(
                            'whitespace-nowrap px-2 py-2 text-sm text-right font-mono',
                            statement
                              ? statement?.membal
                                ? 'font-medium'
                                : 'font-light'
                              : ''
                          )}
                        >
                          {statement
                            ? thousandSeparator(Math.round(statement.balance))
                            : 0}
                        </td>

                        <td
                          className={classNames(
                            'whitespace-nowrap px-2 py-2 text-sm text-center',
                            statementTime.day !== timeConfirmed.day
                              ? 'text-yellow-500'
                              : ''
                          )}
                        >
                          {statementTime.toFormat('d LLLL yyyy - HH:mm:ss')}
                        </td>

                        <td className='whitespace-nowrap px-2 py-2 text-sm text-left'>
                          {history.keterangan}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={11}>
                      <NoHistories />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            <div className='bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6'>
              <div className='hidden sm:flex-1 sm:flex sm:items-center sm:justify-between'>
                <div>
                  <p className='text-sm text-gray-700'>
                    Menampilkan{' '}
                    <span className='font-medium'>
                      {total_data > 0 ? page * 100 - 99 : 0}
                    </span>{' '}
                    sampai{' '}
                    <span className='font-medium'>
                      {page * 100 > total_data ? total_data : page * 100}
                    </span>{' '}
                    dari <span className='font-medium'>{total_data}</span> hasil
                  </p>
                </div>

                <div>
                  <nav className='relative z-0 inline-flex rounded-md shadow-sm -space-x-px'>
                    <button
                      className='relative inline-flex justify-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 w-12'
                      onClick={() => set_page(1)}
                    >
                      <ChevronDoubleLeftIcon className='h-5 w-5' />
                    </button>

                    <button
                      className='relative inline-flex justify-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 w-12'
                      onClick={() => {
                        if (page === 1) return;
                        set_page(currentPage => currentPage - 1);
                      }}
                    >
                      <ChevronLeftIcon className='h-5 w-5' />
                    </button>

                    {getPageArray(page, total_page).map(el => (
                      <button
                        className={classNames(
                          page === el
                            ? 'z-10 bg-emerald-50 border-emerald-500 text-emerald-600'
                            : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50',

                          'hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium justify-center w-12'
                        )}
                        onClick={() => set_page(el)}
                        key={`Page Button #${el}`}
                      >
                        {el}
                      </button>
                    ))}

                    <button
                      className='relative inline-flex justify-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 w-12'
                      onClick={() => {
                        if (page === total_page) return;
                        set_page(currentPage => currentPage + 1);
                      }}
                    >
                      <ChevronRightIcon className='h-5 w-5' />
                    </button>

                    <button
                      className='relative inline-flex justify-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 w-12'
                      onClick={() => set_page(total_page)}
                    >
                      <ChevronDoubleRightIcon className='h-5 w-5' />
                    </button>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default History;
