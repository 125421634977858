import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/solid';
import { DateTime } from 'luxon';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { useNavigate } from 'react-router-dom';
import LoadingIndicator from '../../../../../Components/LoadingIndicator/LoadingIndicator';
import { nav_path, socket_name } from '../../../../../constant';
import { classNames, errMsg, thousandSeparator } from '../../../../../helper';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import {
  add_pendingan,
  remove_pendingan,
  unselect_all_pendingan,
  update_pendingan,
} from '../../../../../store/state/pendingan';
import get_pendingan_live, {
  GetPendinganPayload,
} from '../../../../../store/state/pendingan/request/get';
import { set_selected_acc } from '../../../../../store/state/selected-acc';
import { AccountModel, StatementModel } from '../../../../../types';
import Statements from './Statements';
import { socket } from '../../../../..';

const AREA = {
  GETTING_PENDINGAN: 'GETTING_STMT',
  FETCHING_STMT: 'FETCHING_STMT',
};

const Aside: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { pendingan } = useAppSelector(state => state.pendingan);
  const { accounts } = useAppSelector(state => state.accounts);
  const { active_tab } = useAppSelector(state => state.active_tab);
  const { selected_acc } = useAppSelector(state => state.selected_acc);

  const total_pendingan = useMemo(() => {
    return pendingan.reduce((total, statement) => {
      return total + statement.nominal;
    }, 0);
  }, [pendingan]);

  const accounts_displaying = useMemo(() => {
    if (!accounts) return [];

    return accounts.filter(account => {
      return account.namaBank === active_tab;
    });
  }, [accounts, active_tab]);

  const selected_id = useRef<string>('');

  useEffect(() => {
    if (!selected_acc) return;
    if (selected_acc.id === selected_id.current) return;
    selected_id.current = selected_acc.id;

    const get_pendingan = async () => {
      const { meta, payload } = await trackPromise(
        dispatch(get_pendingan_live(selected_acc)),
        AREA.GETTING_PENDINGAN
      );
      const res = payload as GetPendinganPayload;

      if (meta.requestStatus === 'rejected') {
        alert(errMsg(res) || res.message);
        if (res.status === 401) navigate(nav_path.login);
      }
    };

    get_pendingan();
  }, [selected_acc, dispatch, navigate]);

  useEffect(() => {
    if (!selected_acc) return;

    /*
     * ada beberapa kondisi di mana data pendingan akan di update
     * 1. ketika insert new statement ✔
     * 2. ketika tarik mutasi baru ✔
     * 3. ketika trash statement ✔
     * 4. ketika edit statement ✔
     * 5. ketika centang-centang confirm manual ✔
     * 6. di-start ✔
     * 7. restore from trash ✔
     */

    const add_stmt_socket_name = `${socket_name.add_statement}-${selected_acc.id}`;
    const rmv_stmt_socket_name = `${socket_name.remove_statement}-${selected_acc.id}`;
    const upd_stmt_socket_name = `${socket_name.update_statement}-${selected_acc.id}`;

    socket.on(add_stmt_socket_name, (e: StatementModel[]) => {
      dispatch(add_pendingan(e));
    });

    socket.on(rmv_stmt_socket_name, (e: StatementModel) => {
      dispatch(remove_pendingan(e));
    });

    socket.on(upd_stmt_socket_name, (e: StatementModel) => {
      dispatch(update_pendingan(e));
    });

    return () => {
      socket.off(add_stmt_socket_name);
      socket.off(rmv_stmt_socket_name);
      socket.off(upd_stmt_socket_name);
    };
  }, [dispatch, selected_acc]);

  const { promiseInProgress: fetching_statements } = usePromiseTracker({
    area: AREA.FETCHING_STMT,
  });
  const { promiseInProgress: getting_pendingan } = usePromiseTracker({
    area: AREA.GETTING_PENDINGAN,
  });

  const fetch_statements = async () => {
    try {
      if (!selected_acc) return;
      const uri = '/api/statement/fetch';

      const method = 'POST';
      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      };
      const body = JSON.stringify({ accountId: selected_acc.id });

      const response = await fetch(uri, { method, headers, body });
      const res = await response.json();
      if (!response.ok) throw new Error(errMsg(res) || res.message);
    } catch (error: any) {
      alert(
        error.message || `Terjadi kesalahan ketika mengambil mutasi baru...`
      );
    }
  };

  // * When active tab or selected account changed
  // * : unselect all statements
  useEffect(() => {
    dispatch(unselect_all_pendingan);
  }, [active_tab, selected_acc, dispatch]);

  const [time, set_time] = useState(new Date());

  useEffect(() => {
    if (!selected_acc) return;
    let next_check: number = 0;

    const seconds = DateTime.fromISO(
      selected_acc?.lastFetchStatementTime?.toString() || ''
    )
      .diffNow()
      .as('seconds');

    if (seconds < 60) next_check = 1000;
    else if (seconds < 60 * 60) next_check = 1000 * 60;
    else if (seconds < 60 * 60 * 24) next_check = 1000 * 60 * 60;

    const timeout = setTimeout(() => set_time(new Date()), next_check);

    return () => {
      clearTimeout(timeout);
    };
  }, [time, selected_acc]);

  return (
    <aside className='w-[30rem] bg-white border-l border-gray-200 lg:block py-3 px-5'>
      <div className='flex justify-between items-end'>
        <h1 className='text-3xl font-bold'>Pendingan {active_tab}</h1>

        <div className='text-xs text-right'>
          <>
            Terakhir Tarik:
            <br />
            <b>
              {DateTime.fromISO(
                selected_acc?.lastFetchStatementTime?.toString() || ''
              )
                .setLocale('id')
                .toRelative() || 'Belum Pernah'}
            </b>
          </>
        </div>
      </div>

      <div className='flex mt-1'>
        <Listbox
          as='div'
          value={selected_acc}
          onChange={(e: AccountModel | null) => {
            dispatch(set_selected_acc(e));
          }}
          className='flex-grow max-w-[21rem]'
        >
          {({ open }) => (
            <div className='relative mt-1'>
              <Listbox.Button className='bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm'>
                <span className='block truncate'>
                  {selected_acc
                    ? `${selected_acc.labelRekening} - ${selected_acc.namaRekening} - ${selected_acc.nomorRekening}`
                    : '--Pilih Rekening--'}
                </span>

                <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
                  <ChevronDownIcon className='h-5 w-5 text-gray-400' />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave='transition ease-in duration-100'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <Listbox.Options className='absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm'>
                  {accounts_displaying
                    .filter(account => account.deleted === false)
                    .map(account => (
                      <Listbox.Option
                        key={account.id}
                        value={account}
                        className={({ active }) =>
                          classNames(
                            active
                              ? 'text-white bg-emerald-600'
                              : 'text-gray-900',
                            'cursor-default select-none relative py-2 pl-3 pr-9'
                          )
                        }
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={classNames(
                                selected ? 'font-semibold' : 'font-normal',
                                'block truncate'
                              )}
                            >
                              {`${account.labelRekening} - ${account.namaRekening} - ${account.nomorRekening}`}
                            </span>

                            {selected ? (
                              <span
                                className={classNames(
                                  active ? 'text-white' : 'text-emerald-600',
                                  'absolute inset-y-0 right-0 flex items-center pr-4'
                                )}
                              >
                                <CheckIcon className='h-5 w-5' />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                </Listbox.Options>
              </Transition>
            </div>
          )}
        </Listbox>

        <button
          type='button'
          className='inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 w-1/4 text-center mt-1 justify-center ml-1'
          onClick={() => {
            if (fetching_statements) return;

            if (process.env.NODE_ENV === 'production')
              if (
                Math.abs(
                  DateTime.fromISO(
                    selected_acc?.lastFetchStatementTime?.toString() || ''
                  )
                    .diffNow('seconds')
                    .get('seconds')
                ) < 60
              )
                return alert(
                  'Harap tunggu setidaknya 1 menit sebelum melakukan penarikan data kembali...'
                );

            trackPromise(fetch_statements(), AREA.FETCHING_STMT);
          }}
        >
          {fetching_statements ? (
            <LoadingIndicator colorScheme='light' />
          ) : (
            'Tarik'
          )}
        </button>
      </div>

      <div className='mt-1 bg-emerald-200 px-4 py-3 rounded text-sm'>
        Total Pendingan: Rp {thousandSeparator(total_pendingan)}
      </div>

      <Statements loading={getting_pendingan} />
    </aside>
  );
};

export default Aside;
