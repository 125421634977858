import { createAsyncThunk } from '@reduxjs/toolkit';

interface SaveAccountParams {
  editing_acc_id: string;
  bank_name: string;
  acc_name: string;
  label: string;
  acc_username: string;
  acc_pass: string;
  acc_num: string;
  pin: string;
  balance: string;
  mid?: string;
}

const save_account = createAsyncThunk(
  'account/save_account',

  async (
    {
      editing_acc_id,
      bank_name,
      acc_name,
      label,
      acc_username,
      acc_pass,
      acc_num,
      balance,
      pin,
      mid,
    }: SaveAccountParams,
    { rejectWithValue }
  ) => {
    const uri = editing_acc_id
      ? `/api/account/by-id/${editing_acc_id}`
      : `/api/account/create`;
    const method = editing_acc_id ? 'PATCH' : 'POST';

    const body = JSON.stringify({
      namaBank: bank_name,
      namaRekening: acc_name,
      labelRekening: label,
      usernameRekening: acc_username,
      passwordRekening: acc_pass,
      nomorRekening: acc_num,
      pin,
      balance,
      mid,
    });

    const response = await fetch(uri, {
      method,
      headers: { 'Content-Type': 'application/json' },
      body,
    });

    const res = await response.json();

    if (!response.ok)
      return rejectWithValue(
        JSON.stringify({ ...res, status: response.status })
      );

    return res;
  }
);

export default save_account;
