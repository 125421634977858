import { Listbox } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { DateTime } from 'luxon';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { ArrowIcon } from '../../../Assets';
import LoadingIndicator from '../../../Components/LoadingIndicator/LoadingIndicator';
import Select from '../../../Components/Select';
import { classNames, errMsg } from '../../../helper';
import { useAppDispatch, useAppSelector } from '../../../store';
import { set_selected_date } from '../../../store/state/selected-date';
import { TarikanModel } from '../../../types';
import FilterAcc from '../Components/FilterAcc';
import ProfileDropdown from '../Components/ProfileDropdown';

const AREA = { TARIKAN: 'TARIKAN' };

const NoSelectedAcc = () => (
  <div className='opacity-50 w-full text-left py-5'>
    <div className='w-12 mr-auto mb-3 mt-5 ml-20 flex flex-col items-center'>
      <img src={ArrowIcon.default} alt='No Rekening' />
      <div className='min-w-fit mt-2'>Pilih&nbsp;Rekening</div>
    </div>
  </div>
);

const NoSelectedBatch = () => (
  <div className='opacity-50 w-full text-left py-5'>
    <div className='w-12 m-auto mb-3 mt-5 flex flex-col items-center relative -left-80'>
      <img src={ArrowIcon.default} alt='No Rekening' />
      <div className='min-w-fit mt-2'>Pilih&nbsp;Batch</div>
    </div>
  </div>
);

type TarikanType = 'SUCCESS' | 'FAIL' | null;

const Tarikan: React.FC = () => {
  const dispatch = useAppDispatch();

  const { selected_acc } = useAppSelector(state => state.selected_acc);
  const { selected_date } = useAppSelector(state => state.selected_date);

  const [tarikan, set_tarikan] = useState<TarikanModel[]>([]);
  const [selected_batch, set_selected_batch] = useState(null);
  const [tarikan_type, set_tarikan_type] = useState<TarikanType>(null);

  const { promiseInProgress: getting_tarikan } = usePromiseTracker({
    area: AREA.TARIKAN,
  });

  const filtered_tarikan = useMemo(() => {
    if (!tarikan.length) return [];
    return tarikan.filter(stmt => stmt.date === selected_batch);
  }, [tarikan, selected_batch]);

  const batches = useMemo(() => {
    set_selected_batch(null);
    let filtered = tarikan;
    if (tarikan_type)
      filtered = tarikan.filter(
        t => t.success === (tarikan_type === 'SUCCESS')
      );
    return filtered.map(t => t.date.toString()).reverse();
  }, [tarikan, tarikan_type]);

  const get_tarikan = useCallback(async () => {
    try {
      if (!selected_acc) return;
      const queries = [];
      queries.push(`date=${selected_date}`);

      const uri = `/api/tarikan/${selected_acc.id}?${queries.join('&')}`;
      const response = await fetch(uri);
      const res = await response.json();
      if (!response.ok) throw new Error(errMsg(res) || res.message);

      set_tarikan(res.data.tarikan);
      set_selected_batch(null);
    } catch (error: any) {
      alert(error.message || `Terjadi kesalahan ketika mengambil mutasi...`);
    }
  }, [selected_date, selected_acc]);

  useEffect(() => {
    if (selected_acc) trackPromise(get_tarikan(), AREA.TARIKAN);
  }, [selected_acc, get_tarikan]);

  return (
    <>
      <Helmet>
        <title>{`[ ${process.env.REACT_APP_SELECTED_WEBSITE} ] Tarikan - Auto Deposit`}</title>
      </Helmet>

      <header className='w-full'>
        <div className='relative z-10 flex-shrink-0 h-16 bg-white border-b border-gray-200 shadow-sm flex'>
          <div className='flex-1 flex justify-between px-4 sm:px-6'>
            <div className='flex-1 flex items-center space-x-2'>
              <FilterAcc have_null_option={false} />

              <input
                type='date'
                className='shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block sm:text-sm border-gray-300 rounded-md ml-2'
                value={selected_date}
                onChange={e => {
                  set_selected_batch(null);
                  dispatch(set_selected_date(e.target.value));
                }}
              />

              <Select
                displayValue={tarikan_type || 'ALL'}
                nullOptions={true}
                nullMessage='ALL'
                onChange={set_tarikan_type}
                options={['FAIL', 'SUCCESS'] as TarikanType[]}
                value={tarikan_type}
              />

              <Listbox
                as='div'
                value={selected_batch}
                onChange={set_selected_batch}
                className='w-fit'
              >
                <div className='relative'>
                  <Listbox.Button className='bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm'>
                    <span className='block truncate'>
                      {selected_acc
                        ? batches.length
                          ? selected_batch
                            ? DateTime.fromISO(selected_batch).toFormat(
                                'HH:mm:ss'
                              )
                            : '-- Pilih Jam --'
                          : '-- Tidak Ada Tarikan --'
                        : '-- Belum Pilih Rekening --'}
                    </span>

                    <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
                      <ChevronDownIcon className='h-5 w-5 text-gray-400' />
                    </span>
                  </Listbox.Button>

                  <Listbox.Button className='absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none'>
                    <ChevronDownIcon className='h-5 w-5 text-gray-400' />
                  </Listbox.Button>

                  {batches.length > 0 && (
                    <Listbox.Options className='absolute z-10 mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
                      {batches.map(batch => (
                        <Listbox.Option
                          key={`Batch#${batch}`}
                          value={batch}
                          className={({ active }) =>
                            classNames(
                              'relative cursor-default select-none py-2 pl-3 pr-9',
                              active
                                ? 'bg-emerald-600 text-white'
                                : 'text-gray-900'
                            )
                          }
                        >
                          {({ active, selected }) => (
                            <>
                              <span
                                className={classNames(
                                  'block truncate',
                                  selected ? 'font-semibold' : ''
                                )}
                              >
                                {DateTime.fromISO(batch).toFormat('HH:mm:ss')}
                              </span>

                              {selected && (
                                <span
                                  className={classNames(
                                    'absolute inset-y-0 right-0 flex items-center pr-4',
                                    active ? 'text-white' : 'text-emerald-600'
                                  )}
                                >
                                  <CheckIcon className='h-5 w-5' />
                                </span>
                              )}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  )}
                </div>
              </Listbox>
            </div>

            <div className='flex items-center space-x-6 sm:ml-6 sm:space-x-3'>
              <ProfileDropdown />
            </div>
          </div>
        </div>
      </header>

      <div className='flex-col flex-1 flex lg:flex-row items-stretch overflow-hidden'>
        <main className='flex-1 overflow-y-auto'>
          <section className='min-w-0 flex-1 h-full flex flex-col lg:order-last py-3 px-5'>
            <div className='flex justify-between items-center'>
              <h1 className='text-3xl font-bold'>
                Tarikan{' '}
                {process.env.REACT_APP_SELECTED_WEBSITE}
              </h1>
            </div>

            <div className='overflow-auto shadow ring-1 ring-black ring-opacity-5 md:rounded-lg mt-4 p-5'>
              {getting_tarikan ? (
                <LoadingIndicator colorScheme='dark' />
              ) : selected_acc ? (
                selected_batch ? (
                  <>
                    {Object.keys(filtered_tarikan[0].details).map(detailKey => (
                      <div key={detailKey} className=''>
                        <h2 className='text-2xl font-bold mb-2'>{detailKey}</h2>

                        {filtered_tarikan[0].details[detailKey]
                          .split('\n')
                          .map((p: any, index: number) => (
                            <Fragment key={detailKey + index}>
                              {p}
                              <br />
                            </Fragment>
                          ))}
                      </div>
                    ))}
                  </>
                ) : (
                  <NoSelectedBatch />
                )
              ) : (
                <NoSelectedAcc />
              )}
            </div>
          </section>
        </main>
      </div>
    </>
  );
};

export default Tarikan;
