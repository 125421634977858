import { Dialog, Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { Fragment, useEffect, useState } from 'react';
import { classNames, errMsg } from '../../../../helper';
import { UserModel } from '../../../../types';

interface AddEditUserProps {
  user: UserModel | undefined;
  display_add_edit_window: boolean;
  set_users: React.Dispatch<React.SetStateAction<UserModel[]>>;
  set_display_add_edit_window: React.Dispatch<React.SetStateAction<boolean>>;
  set_editing_user: React.Dispatch<React.SetStateAction<UserModel | undefined>>;
}

const AddEditUser: React.FC<AddEditUserProps> = ({
  user,
  display_add_edit_window,
  set_users,
  set_display_add_edit_window,
  set_editing_user,
}) => {
  const [selected_role, set_selected_role] = useState<string>('');
  const [username, set_username] = useState<string>('');

  const close_window = () => {
    set_display_add_edit_window(false);

    setTimeout(() => {
      set_username('');
      set_selected_role('');
      set_editing_user(undefined);
    }, 200);
  };

  const add_user = async () => {
    try {
      if (!username) throw new Error('Anda belum memasukkan alias');
      if (!selected_role) throw new Error('Anda belum memilih role');

      const uri = `/api/user/create`;
      const method = 'POST';
      const headers = { 'Content-Type': 'application/json' };
      const body = JSON.stringify({ username, role: selected_role });
      const options = { method, headers, body };
      const response = await fetch(uri, options);
      const res = await response.json();
      if (!response.ok) throw new Error(errMsg(res) || res.message);
      alert(res.message);
      set_users(user => [...user, res.data]);
      close_window();
    } catch (error: any) {
      alert(error.message || `Terjadi kesalahan ketika submit user.`);
    }
  };

  useEffect(() => {
    if (user) {
      set_username(user.username);
      set_selected_role(user.role);
    }
  }, [user]);

  const non_activate = async () => {
    try {
      const uri = `/api/user/non-activate/${user?.id}`;
      const method = 'PATCH';
      const response = await fetch(uri, { method });
      const res = await response.json();
      if (!response.ok) throw new Error(errMsg(res) || res.message);
      alert(res.message);

      set_users(users => {
        const index = users.findIndex(u => u.id === res.data.user.id);
        users[index] = res.data.user;
        return [...users];
      });

      close_window();
    } catch (error: any) {
      alert(error.message || `Terjadi kesalahan ketika submit user.`);
    }
  };

  const delete_user = async () => {
    try {
      const confirm = window.confirm('Apakah yakin ingin menghapus user ini?');
      if (!confirm) return;
      const uri = `/api/user/${user?.id}`;
      const method = 'DELETE';
      const response = await fetch(uri, { method });
      const res = await response.json();
      if (!response.ok) throw new Error(errMsg(res) || res.message);
      alert(res.message);
      set_users(users => users.filter(u => u.id !== res.data.user.id));
      close_window();
    } catch (error: any) {
      alert(error.message || `Terjadi kesalahan ketika submit user.`);
    }
  };

  const reset_pass = async () => {
    try {
      const confirm = window.confirm(
        'Apakah yakin ingin mereset password user ini?'
      );
      if (!confirm) return;
      const uri = `/api/user/reset-password/${user?.id}`;
      const method = 'PATCH';
      const response = await fetch(uri, { method });
      const res = await response.json();
      if (!response.ok) throw new Error(errMsg(res) || res.message);
      alert(res.message);
      close_window();
    } catch (error: any) {
      alert(error.message || `Terjadi kesalahan ketika submit user.`);
    }
  };

  const change_role = async (role: string) => {
    try {
      const uri = `/api/user/change-role`;
      const method = 'PATCH';
      const Accept = 'application/json';
      const headers = { Accept, 'Content-Type': Accept };
      const body = JSON.stringify({ userId: user?.id, role });
      const options = { method, headers, body };
      const response = await fetch(uri, options);
      const res = await response.json();
      if (!response.ok) throw new Error(errMsg(res) || res.message);
      alert(res.message);
    } catch (error: any) {
      alert(error.message || `Terjadi kesalahan ketika mengubah role user.`);
    }
  };

  const reset_pin = async () => {
    try {
      const response = await fetch(`/api/user/reset-pin`, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ agent_id: user?.id }),
      });

      const res = await response.json();
      if (!response.ok) throw new Error(errMsg(res) || res.message);
      alert(res.message);
    } catch (error: any) {
      alert(error.message || `Terjadi kesalahan ketika mereset PIN`);
    }
  };

  return (
    <>
      <Transition.Root show={display_add_edit_window} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={close_window}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>

          <div className='fixed z-10 inset-0 overflow-y-auto'>
            <div className='flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                enterTo='opacity-100 translate-y-0 sm:scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              >
                <Dialog.Panel className='relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6'>
                  <div>
                    <div className='mt-3 text-center sm:mt-5'>
                      <Dialog.Title
                        as='h3'
                        className='text-lg leading-6 font-medium text-gray-900'
                      >
                        {user ? 'Edit' : 'Tambah'} Alias
                      </Dialog.Title>

                      <Listbox
                        value={selected_role}
                        onChange={async e => {
                          if (user) {
                            if (selected_role === 'MASTER')
                              return alert(
                                'Tidak bisa mengubah role MASTER...'
                              );
                            if (
                              !window.confirm(
                                `Yakin ingin mengubah role user ini menjadi ${e}?`
                              )
                            )
                              return;
                            await change_role(e);
                          }

                          set_selected_role(e);
                        }}
                      >
                        {({ open }) => (
                          <div className='mt-4 relative'>
                            <Listbox.Button className='bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm'>
                              <span className='block truncate'>
                                {selected_role || '--PILIH ROLE--'}
                              </span>

                              <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
                                <ChevronDownIcon className='h-5 w-5 text-gray-400' />
                              </span>
                            </Listbox.Button>

                            <Transition
                              show={open}
                              as={Fragment}
                              leave='transition ease-in duration-100'
                              leaveFrom='opacity-100'
                              leaveTo='opacity-0'
                            >
                              <Listbox.Options className='absolute z-10 mt-1 w-full bg-white shadow-lg max-h-64 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm'>
                                <Listbox.Option
                                  key='no-role'
                                  className='hidden'
                                  value=''
                                ></Listbox.Option>

                                {[
                                  { value: 'KASIR', text: 'Kasir' },
                                  { value: 'CS', text: 'CS' },
                                ].map(role => (
                                  <Listbox.Option
                                    key={role.value}
                                    className={({ active }) =>
                                      classNames(
                                        active
                                          ? 'text-white bg-emerald-600'
                                          : 'text-gray-900',
                                        'cursor-default select-none relative py-2 pl-3 pr-9'
                                      )
                                    }
                                    value={role.value}
                                  >
                                    {({ selected, active }) => (
                                      <>
                                        <span
                                          className={classNames(
                                            selected
                                              ? 'font-semibold'
                                              : 'font-normal',
                                            'block truncate'
                                          )}
                                        >
                                          {role.text}
                                        </span>

                                        {selected ? (
                                          <span
                                            className={classNames(
                                              active
                                                ? 'text-white'
                                                : 'text-emerald-600',
                                              'absolute inset-y-0 right-0 flex items-center pr-4'
                                            )}
                                          >
                                            <CheckIcon className='h-5 w-5' />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        )}
                      </Listbox>

                      <div className='mt-2'>
                        <input
                          type='text'
                          className='shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block w-full sm:text-sm border-gray-300 rounded-md'
                          placeholder='Alias. Contoh: bambang'
                          disabled={!!user}
                          onChange={e => set_username(e.target.value)}
                          value={username}
                        />
                      </div>

                      {user && (
                        <div className='mt-2'>
                          <input
                            type='text'
                            className='shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block w-full sm:text-sm border-gray-300 rounded-md'
                            disabled
                            value={user?.active ? 'Aktif' : 'Non-Aktif'}
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='mt-4 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense'>
                    {user ? (
                      <button
                        type='button'
                        className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-3 bg-emerald-600 text-base font-medium text-white hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 sm:col-start-2 sm:text-sm transition-colors'
                        onClick={non_activate}
                      >
                        {user?.active ? 'Non-' : ''}Aktifkan
                      </button>
                    ) : (
                      <button
                        type='button'
                        className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-3 bg-emerald-600 text-base font-medium text-white hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 sm:col-start-2 sm:text-sm transition-colors'
                        onClick={add_user}
                      >
                        Simpan
                      </button>
                    )}

                    <button
                      type='button'
                      className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-3 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 sm:mt-0 sm:col-start-1 sm:text-sm transition-colors'
                      onClick={close_window}
                    >
                      Batal
                    </button>
                  </div>

                  {user && (
                    <div className='mt-2 flex gap-3'>
                      <button
                        type='button'
                        className='w-full inline-flex justify-center rounded-md border shadow-sm px-4 py-3 bg-orange-500 text-base font-medium text-white hover:bg-orange-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 sm:mt-0 sm:col-start-1 sm:text-sm transition-colors'
                        onClick={reset_pass}
                      >
                        Reset Password
                      </button>

                      <button
                        type='button'
                        className='w-full inline-flex justify-center rounded-md border shadow-sm px-4 py-3 bg-red-500 text-base font-medium text-white hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:mt-0 sm:col-start-1 sm:text-sm transition-colors'
                        onClick={reset_pin}
                      >
                        Reset PIN
                      </button>

                      <button
                        type='button'
                        className='w-full inline-flex justify-center rounded-md border shadow-sm px-4 py-3 bg-red-500 text-base font-medium text-white hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:mt-0 sm:col-start-1 sm:text-sm transition-colors'
                        onClick={delete_user}
                      >
                        Delete Agent
                      </button>
                    </div>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default AddEditUser;
