import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { classNames } from '../../../../helper';

interface SeeDetailsProps {
  open: boolean;
  set_open: React.Dispatch<React.SetStateAction<boolean>>;

  details:
    | {
        before: {
          [k: string]: any;
        };
        after: {
          [k: string]: any;
        };
      }
    | undefined;
}

const SeeDetails: React.FC<SeeDetailsProps> = ({ details, open, set_open }) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={set_open}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed z-10 inset-0 overflow-y-auto'>
          <div className='flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-6xl sm:w-fit sm:p-6'>
                <div className='flex justify-between space-x-8'>
                  <div className='mt-3 sm:mt-5'>
                    <div className='mt-2'>
                      <table className='min-w-full divide-y divide-gray-300 border-separate text-sm'>
                        <thead className='bg-gray-50'>
                          <tr className='font-semibold text-gray-900'>
                            <th className='pl-4 pr-6 py-3.5'></th>
                            <th className='pl-4 pr-6 py-3.5'>Sebelum</th>
                            <th className='pl-4 pr-6 py-3.5'>Sesudah</th>
                          </tr>
                        </thead>

                        <tbody className='divide-y divide-gray-200 bg-white'>
                          {details &&
                            Object.keys(details.before).map(key => {
                              const changed =
                                details.before[key] !== details.after[key];

                              return (
                                <tr key={key} className='text-gray-500'>
                                  <th className='pl-4 pr-6 py-2 bg-gray-50'>
                                    {key?.toString()}
                                  </th>
                                  <td
                                    className={classNames(
                                      'pl-4 pr-6 py-2',
                                      changed ? 'bg-emerald-100' : ''
                                    )}
                                  >
                                    {details.before[key]?.toString()}
                                  </td>
                                  <td
                                    className={classNames(
                                      'pl-4 pr-6 py-2',
                                      changed ? 'bg-emerald-100' : ''
                                    )}
                                  >
                                    {details.after[key]?.toString()}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className='mt-5 sm:mt-6'>
                  <button
                    type='button'
                    className='inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-emerald-600 text-base font-medium text-white hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 sm:text-sm'
                    onClick={() => set_open(false)}
                  >
                    Kembali
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default SeeDetails;
