import { Transition } from '@headlessui/react';
import React, { Fragment, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { local_storage_var, nav_path } from '../../../constant';
import { errMsg } from '../../../helper';
import { useAppDispatch, useAppSelector } from '../../../store';
import { set_locked } from '../../../store/state/locked';

const allowed_key = ['Backspace', 'Meta', 'Control', 'Alt', 'Shift', 'Tab'];

const InputPIN: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { locked: open } = useAppSelector(state => state.locked);

  const inputPIN0 = useRef<HTMLInputElement>(null);
  const inputPIN1 = useRef<HTMLInputElement>(null);
  const inputPIN2 = useRef<HTMLInputElement>(null);
  const inputPIN3 = useRef<HTMLInputElement>(null);

  const clearInput = () => {
    if (inputPIN0.current) inputPIN0.current.value = '';
    if (inputPIN1.current) inputPIN1.current.value = '';
    if (inputPIN2.current) inputPIN2.current.value = '';
    if (inputPIN3.current) inputPIN3.current.value = '';
  };

  useEffect(() => {
    clearInput();
  }, []);

  const submit_pin = async () => {
    try {
      if (
        !inputPIN0.current?.value ||
        !inputPIN1.current?.value ||
        !inputPIN2.current?.value ||
        !inputPIN3.current?.value
      )
        throw new Error('PIN Tidak Lengkap...');

      const response = await fetch(`/api/user/confirm-pin`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          pin:
            inputPIN0.current.value +
            inputPIN1.current.value +
            inputPIN2.current.value +
            inputPIN3.current.value,
        }),
      });

      const res = await response.json();

      if (!response.ok) throw new Error(errMsg(res) || res.message);
      dispatch(set_locked(!res.data.pinMatch));
      localStorage.setItem(local_storage_var.LOCKED, 'false');
    } catch (error: any) {
      alert(error.message || `Terjadi kesalahan ketika mengkonfirmasi PIN...`);
      clearInput();
      inputPIN0.current?.focus();
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Transition.Child
        as={Fragment}
        enter='ease-out duration-300'
        enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
        enterTo='opacity-100 translate-y-0 sm:scale-100'
        leave='ease-in duration-200'
        leaveFrom='opacity-100 translate-y-0 sm:scale-100'
        leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
      >
        <div className='fixed z-10 inset-0 bg-emerald-50 flex items-center justify-center'>
          <div className='text-center'>
            <h2 className='font-bold text-3xl'>Masukkan PIN</h2>

            <form className='space-x-2 mt-4'>
              <input
                type='password'
                className='shadow-sm focus:ring-emerald-500 focus:border-emerald-500 border-gray-300 rounded-md text-center w-20 h-20 text-9xl leading-none pb-10'
                ref={inputPIN0}
                maxLength={1}
                onKeyDown={e => {
                  if (allowed_key.indexOf(e.key) < 0) e.preventDefault();

                  if (isFinite(+e.key)) {
                    inputPIN0.current!.value = e.key;
                    inputPIN1.current?.focus();
                  }
                }}
                autoFocus
              />

              <input
                type='password'
                className='shadow-sm focus:ring-emerald-500 focus:border-emerald-500 border-gray-300 rounded-md text-center w-20 h-20 text-9xl leading-none pb-10'
                ref={inputPIN1}
                maxLength={1}
                onKeyDown={e => {
                  if (allowed_key.indexOf(e.key) < 0) e.preventDefault();
                  if (e.key === 'Backspace' && !inputPIN1.current!.value)
                    inputPIN0.current?.focus();

                  if (isFinite(+e.key)) {
                    inputPIN1.current!.value = e.key;
                    inputPIN2.current?.focus();
                  }
                }}
              />

              <input
                type='password'
                className='shadow-sm focus:ring-emerald-500 focus:border-emerald-500 border-gray-300 rounded-md text-center w-20 h-20 text-9xl leading-none pb-10'
                ref={inputPIN2}
                maxLength={1}
                onKeyDown={e => {
                  if (allowed_key.indexOf(e.key) < 0) e.preventDefault();
                  if (e.key === 'Backspace' && !inputPIN2.current!.value)
                    inputPIN1.current?.focus();

                  if (isFinite(+e.key)) {
                    inputPIN2.current!.value = e.key;
                    inputPIN3.current?.focus();
                  }
                }}
              />

              <input
                type='password'
                className='shadow-sm focus:ring-emerald-500 focus:border-emerald-500 border-gray-300 rounded-md text-center w-20 h-20 text-9xl leading-none pb-10'
                ref={inputPIN3}
                maxLength={1}
                onKeyDown={e => {
                  if (allowed_key.indexOf(e.key) < 0) e.preventDefault();
                  if (e.key === 'Backspace' && !inputPIN3.current!.value)
                    inputPIN2.current?.focus();

                  if (isFinite(+e.key)) {
                    inputPIN3.current!.value = e.key;
                    submit_pin();
                  }
                }}
              />
            </form>

            <div className='mt-4'>
              <button
                type='button'
                className='px-4 py-2 border border-transparent font-medium rounded-md shadow-sm text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 w-full text-center'
                onClick={() => navigate(nav_path.login)}
              >
                Login Ulang
              </button>
            </div>
          </div>
        </div>
      </Transition.Child>
    </Transition.Root>
  );
};

export default InputPIN;
