const nav_path = {
  dashboard: {
    index: '/dashboard',
    settings: '/dashboard/settings',
    history: '/dashboard/history',
    live: '/dashboard/live',
    rekening: '/dashboard/rekening',
    trash: '/dashboard/trash',
    user: '/dashboard/user',
    mutasi: '/dashboard/mutasi',
    log: '/dashboard/log',
    tarikan: '/dashboard/tarikan',
    stats: '/dashboard/stats',
  },

  login: '/login',
};

export default nav_path;
