import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';

interface SelectedDateState {
  selected_date: string;
}

const initial_state: SelectedDateState = {
  selected_date: DateTime.now().setZone('UTC+7').toFormat('yyyy-MM-dd'),
};

export const selected_date_slice = createSlice({
  name: 'selected-date',
  initialState: initial_state,

  reducers: {
    set_selected_date: (state, action: PayloadAction<string>) => {
      state.selected_date = action.payload;
    },
  },
});

export default selected_date_slice;
export const { set_selected_date } = selected_date_slice.actions;
