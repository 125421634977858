import { createAsyncThunk } from '@reduxjs/toolkit';
import { AccountModel } from '../../../../types';

export interface GetAccountsPayload {
  message: string;
  data: AccountModel[];
  status: number;
}

const get_accounts = createAsyncThunk(
  'account/get_accounts',
  async (_, { rejectWithValue }) => {
    const response = await fetch('/api/account/get-all');
    const res: GetAccountsPayload = await response.json();

    if (!response.ok)
      return rejectWithValue({ ...res, status: response.status });

    return res.data;
  }
);

export default get_accounts;
