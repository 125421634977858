import { Listbox } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { classNames } from '../../../helper';
import { set_selected_acc } from '../../../store/state/selected-acc';
import { useAppDispatch, useAppSelector } from '../../../store';
import { AccountModel } from '../../../types';

interface FilterRekeningProps {
  have_null_option: boolean;
}

const FilterRekening: React.FC<FilterRekeningProps> = ({
  have_null_option,
}) => {
  const dispatch = useAppDispatch();

  const { selected_acc } = useAppSelector(state => state.selected_acc);
  const { accounts } = useAppSelector(state => state.accounts);

  const null_message = have_null_option
    ? '-- Semua Rekening --'
    : '-- Pilih Rekening --';

  return (
    <Listbox
      as='div'
      value={selected_acc}
      onChange={(e: AccountModel | null) => dispatch(set_selected_acc(e))}
      className='w-fit min-w-[30rem]'
    >
      <div className='relative'>
        <Listbox.Button className='bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm'>
          <span className='block truncate'>
            {selected_acc
              ? `${selected_acc.labelRekening} - ${selected_acc.namaRekening} - ${selected_acc.nomorRekening}`
              : null_message}
          </span>

          <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
            <ChevronDownIcon
              className='h-5 w-5 text-gray-400'
              aria-hidden='true'
            />
          </span>
        </Listbox.Button>

        <Listbox.Button className='absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none'>
          <ChevronDownIcon className='h-5 w-5 text-gray-400' />
        </Listbox.Button>

        <Listbox.Options className='absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
          {have_null_option && (
            <Listbox.Option
              value={null}
              className={({ active }) =>
                classNames(
                  'relative cursor-default select-none py-2 pl-3 pr-9',
                  active ? 'bg-emerald-600 text-white' : 'text-gray-900'
                )
              }
            >
              {({ active }) => (
                <>
                  <span
                    className={classNames(
                      'block truncate',
                      !selected_acc ? 'font-semibold' : ''
                    )}
                  >
                    {null_message}
                  </span>

                  {!selected_acc && (
                    <span
                      className={classNames(
                        'absolute inset-y-0 right-0 flex items-center pr-4',
                        active ? 'text-white' : 'text-emerald-600'
                      )}
                    >
                      <CheckIcon className='h-5 w-5' />
                    </span>
                  )}
                </>
              )}
            </Listbox.Option>
          )}

          {accounts
            .filter(account => account.deleted === false)
            .map(account => (
              <Listbox.Option
                key={account.id}
                value={account}
                className={({ active }) =>
                  classNames(
                    'relative cursor-default select-none py-2 pl-3 pr-9',
                    active ? 'bg-emerald-600 text-white' : 'text-gray-900'
                  )
                }
              >
                {({ active }) => (
                  <>
                    <span
                      className={classNames(
                        'block truncate',
                        account.id === selected_acc?.id ? 'font-semibold' : ''
                      )}
                    >
                      {account.labelRekening} - {account.namaRekening} -{' '}
                      {account.nomorRekening}
                    </span>

                    {account.id === selected_acc?.id && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          active ? 'text-white' : 'text-emerald-600'
                        )}
                      >
                        <CheckIcon className='h-5 w-5' />
                      </span>
                    )}
                  </>
                )}
              </Listbox.Option>
            ))}
        </Listbox.Options>
      </div>
    </Listbox>
  );
};

export default FilterRekening;
