import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { nav_path } from '../constant';

const Loading: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const currentUser = async () => {
      try {
        const uri = '/api/user/is-token-valid';
        const response = await fetch(uri);
        const res = await response.json();
        if (!response.ok) throw new Error(res.message);
        if (res.isTokenValid) navigate(nav_path.dashboard.live);
        else if (!res.isTokenValid) navigate(nav_path.dashboard.live);
      } catch (error: any) {
        alert(error.message || `Terjadi kesalahan ketika mengecek token.`);
      }
    };

    currentUser();
  }, [navigate]);

  return (
    <div className='min-h-screen flex justify-center items-center'>
      Loading...
    </div>
  );
};

export default Loading;
