import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { StatementModel } from '../../../types';
import get_pendingan_live from './request/get';

interface PendinganState {
  pendingan: StatementModel[];
}

const initial_state: PendinganState = {
  pendingan: [],
};

export const pendingan_slice = createSlice({
  name: 'pendingan',
  initialState: initial_state,

  reducers: {
    set_pendingan: (state, action: PayloadAction<StatementModel[]>) => {
      state.pendingan = action.payload;
    },

    add_pendingan: (state, action: PayloadAction<StatementModel[]>) => {
      state.pendingan = [...action.payload, ...state.pendingan];
    },

    remove_pendingan: (state, action: PayloadAction<StatementModel>) => {
      state.pendingan = state.pendingan.filter(
        pendingan => pendingan.id !== action.payload.id
      );
    },

    update_pendingan: (state, action: PayloadAction<StatementModel>) => {
      const index = state.pendingan.findIndex(
        pendingan => pendingan.id === action.payload.id
      );
      const selected = state.pendingan[index].selected;
      state.pendingan[index] = { ...action.payload, selected };
    },

    toggle_selected_pendingan: (state, action: PayloadAction<number>) => {
      const index = action.payload;
      state.pendingan[index].selected = !state.pendingan[index].selected;
    },

    unselect_all_pendingan: state => {
      state.pendingan.forEach(statement => (statement.selected = false));
    },
  },

  extraReducers: builder => {
    builder.addCase(get_pendingan_live.fulfilled, (state, action) => {
      state.pendingan = action.payload.data.map(statement => ({
        ...statement,
        selected: false,
      }));
    });
  },
});

export default pendingan_slice;

export const {
  set_pendingan,
  toggle_selected_pendingan,
  unselect_all_pendingan,
  add_pendingan,
  remove_pendingan,
  update_pendingan,
} = pendingan_slice.actions;
