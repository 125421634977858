import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { ProfileImg } from '../../../Assets';
import { nav_path } from '../../../constant';
import { classNames } from '../../../helper';

const ProfileDropdown: React.FC = () => {
  const navigate = useNavigate();

  const signout = async () => {
    try {
      const uri = `/api/user/logout`;
      const response = await fetch(uri, { method: 'POST' });
      const res = await response.json();
      if (!response.ok) throw new Error(res.message);
      navigate(nav_path.login);
    } catch (error: any) {
      alert(error.message || `Terjadi kesalahan ketika logout.`);
    }
  };

  const relogin_IDN = async () => {
    try {
      const uri = '/api/form/relog-idn';
      const method = 'POST';
      const response = await fetch(uri, { method });
      const res = await response.json();
      if (!response.ok) throw new Error(res.message);
      alert(res.message);
    } catch (error: any) {
      alert(
        error.message || `Terjadi kesalahan ketika mencoba login ke IDN...`
      );
    }
  };

  const user_nav = {
    PROFILE: {
      name: 'Settings',
      onClick: () => navigate(nav_path.dashboard.settings),
    },

    RELOG_IDN: {
      name: 'Relogin IDN',
      onClick: relogin_IDN,
    },

    SIGN_OUT: {
      name: 'Keluar',
      onClick: signout,
    },
  };

  return (
    <Menu as='div' className='relative flex-shrink-0'>
      <div>
        <Menu.Button className='bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500'>
          <img
            className='h-8 w-8 rounded-full'
            src={ProfileImg.default}
            alt='Profile'
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items className='origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none text-right'>
          {Object.values(user_nav).map(item => (
            <Menu.Item key={item.name}>
              {({ active }: { active: boolean }) => (
                <div
                  className={classNames(
                    active ? 'bg-gray-100' : '',
                    'block px-4 py-2 text-sm text-gray-700 cursor-pointer'
                  )}
                  onClick={item.onClick}
                >
                  {item.name}
                </div>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default ProfileDropdown;
