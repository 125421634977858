import { Listbox } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { classNames } from '../helper';

interface SelectProps {
  value: any;
  onChange: React.Dispatch<React.SetStateAction<any>>;
  displayValue: string;
  nullOptions: boolean;
  nullMessage?: string;
  options: any[];
}

const Select: React.FC<SelectProps> = ({
  value,
  onChange,
  displayValue,
  nullOptions,
  nullMessage,
  options,
}) => {
  return (
    <Listbox as='div' value={value} onChange={onChange} className='w-fit'>
      <div className='relative'>
        <Listbox.Button className='bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-teal-500 focus:border-teal-500 sm:text-sm'>
          <span className='block truncate'>{displayValue}</span>

          <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
            <ChevronDownIcon className='h-5 w-5 text-gray-400' />
          </span>
        </Listbox.Button>

        <Listbox.Button className='absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none'>
          <ChevronDownIcon className='h-5 w-5 text-gray-400' />
        </Listbox.Button>

        <Listbox.Options className='absolute z-10 mt-1 max-h-60 w-fit max-w-lg overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
          {nullOptions && (
            <Listbox.Option
              value={null}
              className={({ active }) =>
                classNames(
                  'relative cursor-default select-none py-2 pl-3 pr-9 w-full',
                  active ? 'bg-teal-600 text-white' : 'text-gray-900'
                )
              }
            >
              {({ active }) => (
                <>
                  <span
                    className={classNames(
                      'block truncate',
                      !value ? 'font-semibold' : ''
                    )}
                  >
                    {nullMessage}
                  </span>

                  {!value && (
                    <span
                      className={classNames(
                        'absolute inset-y-0 right-0 flex items-center pr-4',
                        active ? 'text-white' : 'text-teal-600'
                      )}
                    >
                      <CheckIcon className='h-5 w-5' />
                    </span>
                  )}
                </>
              )}
            </Listbox.Option>
          )}

          {options.map(option => (
            <Listbox.Option
              key={option}
              value={option}
              className={({ active }) =>
                classNames(
                  'relative cursor-default select-none py-2 pl-3 pr-9 max-w-lg w-full',
                  active ? 'bg-teal-600 text-white' : 'text-gray-900'
                )
              }
            >
              {({ active, selected }) => (
                <>
                  <span
                    className={classNames(
                      'block truncate',
                      selected ? 'font-semibold' : ''
                    )}
                  >
                    {option}
                  </span>

                  {selected && (
                    <span
                      className={classNames(
                        'absolute inset-y-0 right-0 flex items-center pr-4',
                        active ? 'text-white' : 'text-teal-600'
                      )}
                    >
                      <CheckIcon className='h-5 w-5' />
                    </span>
                  )}
                </>
              )}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </div>
    </Listbox>
  );
};

export default Select;
