import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { Helmet } from 'react-helmet';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { LeafIcon } from '../../../../Assets';
import LoadingIndicator from '../../../../Components/LoadingIndicator/LoadingIndicator';
import { classNames, errMsg } from '../../../../helper';
import { LogModel, LogType } from '../../../../types';
import ProfileDropdown from '../../Components/ProfileDropdown';
import FilterAgent from './FilterAgent';
import FilterLog from './FilterLog';
import Pagination from './Pagination';
import SeeDetails from './SeeDetails';

const NoLogs = () => (
  <div className='opacity-50 w-full text-center py-20'>
    <div className='w-24 m-auto'>
      <img src={LeafIcon.default} alt='No logs...' className='w-full' />
    </div>

    <div>Tidak ada logs...</div>
  </div>
);

const AREA = { GET_LOG: 'GET_LOG' };

export interface QueryType {
  log_type: LogType;
  date: string;
  page: number;
  agent: string;
  description: string;
}

const Log: React.FC = () => {
  const [logs, set_logs] = useState<LogModel[]>([]);
  const [total_data, set_total_data] = useState(0);
  const [total_page, set_total_page] = useState(0);
  const [show_detail_window, set_show_detail_window] = useState(false);

  const [query, set_query] = useState<QueryType>({
    log_type: '',
    date: DateTime.now().toFormat('yyyy-MM-dd'),
    page: 1,
    agent: '',
    description: '',
  });

  const [details, set_details] = useState<{
    before: {
      [k: string]: any;
    };

    after: {
      [k: string]: any;
    };
  }>();

  const { promiseInProgress: getting_logs } = usePromiseTracker({
    area: AREA.GET_LOG,
  });

  useEffect(() => {
    const get_logs = async () => {
      try {
        const { log_type, date, page, agent, description } = query;
        const uri = `/api/log/get-all?page=${page}&date=${date}&log_type=${log_type}&agent=${agent}&description=${description}`;
        const response = await fetch(uri);

        const res: {
          message: string;
          data: { logs: LogModel[]; total_data: number; total_page: number };
        } = await response.json();

        if (!response.ok) throw new Error(errMsg(res) || res.message);
        set_logs(res.data.logs);
        set_total_data(res.data.total_data);
        set_total_page(res.data.total_page);
      } catch (error: any) {
        alert(error.message || `Terjadi kesalahan ketika mengambil mutasi...`);
      }
    };

    trackPromise(get_logs(), AREA.GET_LOG);
  }, [query]);

  return (
    <>
      <Helmet>
        <title>{`[ ${process.env.REACT_APP_SELECTED_WEBSITE} ] Log - Auto Deposit`}</title>
      </Helmet>

      <header className='w-full'>
        <div className='relative z-10 flex-shrink-0 h-16 bg-white border-b border-gray-200 shadow-sm flex'>
          <div className='flex-1 flex justify-between px-4 sm:px-6'>
            <div className='flex-1 flex items-center'>
              <FilterLog query={query} set_query={set_query} />

              <input
                type='date'
                className='shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block sm:text-sm border-gray-300 rounded-md mx-2'
                value={query.date}
                onChange={e =>
                  set_query(query => {
                    query.page = 1;
                    query.date = e.target.value;
                    return { ...query };
                  })
                }
              />

              <FilterAgent query={query} set_query={set_query} />

              <DebounceInput
                debounceTimeout={500}
                type='text'
                className='shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block sm:text-sm border-gray-300 rounded-md mx-2 w-1/4'
                value={query.description}
                placeholder='Description...'
                onChange={e =>
                  set_query(query => {
                    query.page = 1;
                    query.description = e.target.value;
                    return { ...query };
                  })
                }
              />
            </div>

            <div className='flex items-center space-x-6 sm:ml-6 sm:space-x-3'>
              <ProfileDropdown />
            </div>
          </div>
        </div>
      </header>

      <div className='flex-col flex-1 flex lg:flex-row items-stretch overflow-hidden'>
        <main className='flex-1 overflow-y-auto'>
          <section className='min-w-0 flex-1 h-full flex flex-col lg:order-last py-3 px-5'>
            <div className='flex justify-between items-center'>
              <h1 className='text-3xl font-bold'>
                Log {process.env.REACT_APP_SELECTED_WEBSITE}
              </h1>
            </div>

            <div className='overflow-auto shadow ring-1 ring-black ring-opacity-5 md:rounded-lg mt-4'>
              <table className='min-w-full divide-y divide-gray-300 border-separate'>
                <thead className='bg-gray-50'>
                  <tr className='whitespace-nowrap px-2 py-3.5 pl-4 text-left text-sm font-semibold text-gray-900'>
                    <th className='px-2 py-3.5 pl-4 text-center'>Time</th>
                    <th className='px-2 py-3.5 text-center'>Type</th>
                    <th className='px-2 py-3.5 text-center'>Agent</th>
                    <th className='px-2 py-3.5 text-left'>Description</th>
                    <th className='px-2 py-3.5 text-left'>Target</th>
                    <th className='px-2 py-3.5 pr-4 text-center'>Details</th>
                  </tr>
                </thead>

                <tbody className='divide-y divide-gray-200 bg-white'>
                  {getting_logs ? (
                    <tr>
                      <td colSpan={6} className='text-center py-8'>
                        <LoadingIndicator colorScheme='dark' />
                      </td>
                    </tr>
                  ) : logs.length ? (
                    logs.map(log => (
                      <tr
                        key={log.id}
                        className='whitespace-nowrap text-sm text-gray-500'
                      >
                        <td className='px-2 py-2 pl-4 text-center font-mono font-light'>
                          {DateTime.fromISO(log.time)
                            .setLocale('id')
                            .toFormat('d LLLL yyyy - HH:mm:ss')}
                        </td>

                        <td className='px-2 py-2 text-center'>{log.type}</td>
                        <td className='px-2 py-2 text-center'>
                          {log.agent.toUpperCase()}
                        </td>

                        <td className='px-2 py-2 max-w-xl whitespace-pre-wrap'>
                          {log.description}
                        </td>

                        <td className='px-2 py-2'>{log.target || '-'}</td>

                        <td className='px-2 py-2 pr-4 text-center'>
                          <span
                            className={classNames(
                              log.details
                                ? 'cursor-pointer text-emerald-500 active:opacity-70 font-medium'
                                : 'text-gray-300 cursor-not-allowed',
                              'select-none'
                            )}
                            onClick={() => {
                              if (!log.details) return;
                              set_details(log.details);
                              set_show_detail_window(true);
                            }}
                          >
                            Lihat Perubahan
                          </span>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={6}>
                        <NoLogs />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

              <Pagination
                total_data={total_data}
                total_page={total_page}
                query={query}
                set_query={set_query}
              />
            </div>
          </section>
        </main>
      </div>

      <SeeDetails
        details={details}
        open={show_detail_window}
        set_open={set_show_detail_window}
      />
    </>
  );
};

export default Log;
