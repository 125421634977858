import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface FindingStatementState {
  finding_statement: boolean;
}

const initial_state: FindingStatementState = {
  finding_statement: false,
};

export const finding_statement_slice = createSlice({
  name: 'finding-statement',
  initialState: initial_state,

  reducers: {
    set_finding_statement: (state, action: PayloadAction<boolean>) => {
      state.finding_statement = action.payload;
    },
  },
});

export default finding_statement_slice;
export const { set_finding_statement } = finding_statement_slice.actions;
