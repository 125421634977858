import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { io } from 'socket.io-client';
import App from './App';
import './index.css';
import { store } from './store';
import { set_socket_id } from './store/state/socket-id';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

export const socket = io(
  process.env.REACT_APP_SOCKET_URL || 'http://localhost:8001'
);

socket.on('connect', () => {
  store.dispatch(set_socket_id(socket.id));
});

socket.on('connect_error', () => {
  store.dispatch(set_socket_id(null));
  setTimeout(() => socket.connect(), 2 * 1000);
});

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
