import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface WarningDurationState {
  warning_duration: number;
}

const initial_state: WarningDurationState = {
  warning_duration: 120,
};

export const warning_duration_slice = createSlice({
  name: 'warning-duration',
  initialState: initial_state,

  reducers: {
    set_warning_duration: (state, action: PayloadAction<number>) => {
      state.warning_duration = action.payload;
    },
  },
});

export default warning_duration_slice;
export const { set_warning_duration } = warning_duration_slice.actions;
