import { createAsyncThunk } from '@reduxjs/toolkit';
import { AccountModel, FormModel } from '../../../../types';

export interface StartCheckFormPayload {
  message: string;
  data: FormModel[];
  status?: number;
}

const start_matching_forms_and_statements = createAsyncThunk(
  'form/start',

  async (selected_acc: AccountModel | null, { rejectWithValue }) => {
    const response = await fetch(`/api/form/start`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ selectedAccId: selected_acc?.id }),
    });

    const res: StartCheckFormPayload = await response.json();
    if (!response.ok)
      return rejectWithValue({ ...res, status: response.status });
    return res;
  }
);

export default start_matching_forms_and_statements;
