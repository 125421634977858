import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import account_slice from './state/account';
import active_tab_slice from './state/active-tab';
import find_statement_query_slice from './state/find-statement-query';
import finding_statement_slice from './state/finding-statement';
import form_slice from './state/form';
import locked_slice from './state/locked';
import pendingan_slice from './state/pendingan';
import selected_acc_slice from './state/selected-acc';
import selected_date_slice from './state/selected-date';
import socket_id_slice from './state/socket-id';
import warning_duration_slice from './state/warning-duration';

export const store = configureStore({
  reducer: {
    accounts: account_slice.reducer,
    active_tab: active_tab_slice.reducer,
    find_statement_query: find_statement_query_slice.reducer,
    finding_statement: finding_statement_slice.reducer,
    forms: form_slice.reducer,
    locked: locked_slice.reducer,
    selected_acc: selected_acc_slice.reducer,
    selected_date: selected_date_slice.reducer,
    socket_id: socket_id_slice.reducer,
    pendingan: pendingan_slice.reducer,
    warning_duration: warning_duration_slice.reducer,
  },
});

export const useAppDispatch: () => typeof store.dispatch = useDispatch;

export const useAppSelector: TypedUseSelectorHook<
  ReturnType<typeof store.getState>
> = useSelector;
