import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { LogoImg } from '../Assets';
import { local_storage_var, nav_path } from '../constant';

const Login = () => {
  const navigate = useNavigate();

  const [username, set_username] = useState('');
  const [password, set_password] = useState('');

  const submitLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!username) throw new Error('Username kosong.');
    if (!password) throw new Error('Password kosong.');

    try {
      const response = await fetch('/api/user/login', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      const res = await response.json();
      if (!response.ok) throw new Error(res.errors[0].message || res.message);
      localStorage.setItem(
        local_storage_var.SELECTED_WEBSITE,
        res.data.selectedWebsite
      );
      navigate(nav_path.dashboard.live);
    } catch (error: any) {
      alert(error.message || `Terjadi kesalahan ketika login.`);
    }
  };

  return (
    <>
      <Helmet>
        <title>{`[ ${process.env.REACT_APP_SELECTED_WEBSITE} ] Login - Auto Deposit`}</title>
      </Helmet>

      <div className='bg-gray-50 min-h-screen'>
        <div className='min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8'>
          <div className='sm:mx-auto sm:w-full sm:max-w-md'>
            <img
              className='mx-auto h-12 w-auto'
              src={LogoImg.default}
              alt='AutoDeposit'
            />
            <h2 className='mt-6 text-center text-3xl font-extrabold text-gray-900'>
              Auto Deposit {process.env.REACT_APP_SELECTED_WEBSITE}
            </h2>
          </div>

          <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
            <div className='bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10'>
              <form className='space-y-6' onSubmit={submitLogin}>
                <div>
                  <label
                    htmlFor='username'
                    className='block text-sm font-medium text-gray-700'
                  >
                    Username
                  </label>

                  <div className='mt-1'>
                    <input
                      id='username'
                      name='username'
                      type='username'
                      autoComplete='username'
                      required
                      className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm'
                      onChange={e => set_username(e.target.value)}
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor='password'
                    className='block text-sm font-medium text-gray-700'
                  >
                    Password
                  </label>

                  <div className='mt-1'>
                    <input
                      id='password'
                      name='password'
                      type='password'
                      autoComplete='current-password'
                      required
                      className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm'
                      onChange={e => set_password(e.target.value)}
                    />
                  </div>
                </div>

                <div>
                  <button
                    type='submit'
                    className='w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500'
                  >
                    Sign in
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
