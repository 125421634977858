import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { CheckIcon } from '@heroicons/react/24/solid';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { useNavigate } from 'react-router-dom';
import { AREA } from '..';
import { LeafIcon } from '../../../../../Assets';
import LoadingIndicator from '../../../../../Components/LoadingIndicator/LoadingIndicator';
import { nav_path } from '../../../../../constant';
import { classNames, errMsg, thousandSeparator } from '../../../../../helper';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { set_find_statement_query } from '../../../../../store/state/find-statement-query';
import { set_finding_statement } from '../../../../../store/state/finding-statement';
import check_and_confirm_form, {
  ConfirmFormPayload,
} from '../../../../../store/state/form/request/confirm';
import {
  RejectFormPayload,
  check_and_reject_form,
} from '../../../../../store/state/form/request/reject';
import start_matching_forms_and_statements, {
  StartCheckFormPayload,
} from '../../../../../store/state/form/request/start';
import { set_selected_acc } from '../../../../../store/state/selected-acc';
import { Banks, FormModel } from '../../../../../types';

const NoForms = () => (
  <div className='opacity-50 w-full text-center py-20'>
    <div className='w-24 m-auto'>
      <img src={LeafIcon.default} alt='No forms' className='w-full' />
    </div>

    <div>Form Deposit Kosong</div>
  </div>
);

interface MainProps {
  fetching_form: boolean;
}

const Main: React.FC<MainProps> = ({ fetching_form }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { forms } = useAppSelector(state => state.forms);
  const { active_tab } = useAppSelector(state => state.active_tab);
  const { accounts } = useAppSelector(state => state.accounts);
  const { selected_acc } = useAppSelector(state => state.selected_acc);
  const { pendingan } = useAppSelector(state => state.pendingan);
  const { warning_duration } = useAppSelector(state => state.warning_duration);

  const { promiseInProgress: rejecting_form } = usePromiseTracker({
    area: AREA.REJECT,
  });

  const { promiseInProgress: confirming_form } = usePromiseTracker({
    area: AREA.CONFIRM,
  });

  const [confirming_form_input_name, set_confirming_form_input_name] =
    useState('');

  const [rejecting_form_input_name, set_rejecting_form_input_name] =
    useState('');

  const forms_display = useMemo(() => {
    if (!forms) return [];

    return forms.filter((form: FormModel) => {
      let { bankPT } = form.penerima;
      bankPT = bankPT.replaceAll(/\d/g, '') as Banks;
      return bankPT === active_tab;
    });
  }, [forms, active_tab]);

  const on_click_account = (penerima: {
    bankPT: string;
    nomorRekeningPT: string;
    namaRekeningPT: string;
  }) => {
    const index = accounts.findIndex(
      account =>
        account.nomorRekening
          .replace(/\s/g, '')
          .includes(penerima.nomorRekeningPT.replace(/\s/g, '')) &&
        account.namaBank.includes(penerima.bankPT.replace(/\d/g, '')) &&
        account.namaRekening.includes(penerima.namaRekeningPT)
    );

    if (index >= 0) dispatch(set_selected_acc(accounts[index]));
  };

  const start = useCallback(async () => {
    const { meta, payload } = await trackPromise(
      dispatch(start_matching_forms_and_statements(selected_acc)),
      AREA.START
    );

    const res = payload as StartCheckFormPayload;

    if (meta.requestStatus === 'rejected') {
      alert(errMsg(res) || res.message);
      if (res.status === 401) navigate(nav_path.login);
    }
  }, [dispatch, navigate, selected_acc]);

  const reject_form = async (form: FormModel) => {
    const { meta, payload } = await dispatch(check_and_reject_form(form));
    const res = payload as RejectFormPayload;

    if (meta.requestStatus === 'rejected') {
      alert(errMsg(res) || res.message);
      if (res.status === 401) navigate(nav_path.login);
    }

    set_rejecting_form_input_name('');
  };

  const confirm_form = async (form: FormModel) => {
    try {
      const selected_statement = pendingan.filter(
        statement => statement.selected
      );

      if (!selected_statement.length) throw new Error('Belum memilih mutasi.');

      const { meta, payload } = await dispatch(
        check_and_confirm_form({ form, statements: selected_statement })
      );
      const res = payload as ConfirmFormPayload;

      if (meta.requestStatus === 'rejected') {
        alert(errMsg(res) || res.message);
        if (res.status === 401) navigate(nav_path.login);
      }
    } catch (error: any) {
      alert(error.message);
    } finally {
      set_confirming_form_input_name('');
    }
  };

  useEffect(() => {
    const listener = async (e: KeyboardEvent) => {
      if (e.key === 's' && (e.ctrlKey || e.metaKey)) {
        e.preventDefault();
        if (fetching_form) return;
        start();
      }
    };

    window.addEventListener('keydown', listener);

    return () => {
      window.removeEventListener('keydown', listener);
    };
  }, [fetching_form, start]);

  return (
    <main className='flex-1 overflow-y-auto'>
      <section className='min-w-0 flex-1 h-full flex flex-col lg:order-last py-3 px-5'>
        <div className='flex items-center'>
          <h1 className='text-3xl font-bold'>
            Form Deposit {process.env.REACT_APP_SELECTED_WEBSITE}
          </h1>

          <button
            type='button'
            className='inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 w-fit text-center justify-center ml-4'
            onClick={() => {
              if (fetching_form) return;
              start();
            }}
          >
            {fetching_form ? <LoadingIndicator colorScheme='light' /> : 'Start'}
          </button>
        </div>

        {!forms_display.length ? (
          <NoForms />
        ) : (
          <div className='mt-4 flex flex-col'>
            <div className='overflow-x-auto px-1'>
              <div className='inline-block min-w-full py-2 align-middle'>
                <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                  <table className='min-w-full divide-y divide-gray-300'>
                    <thead className='bg-gray-50'>
                      <tr>
                        <th
                          scope='col'
                          className='whitespace-nowrap py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                        >
                          User
                        </th>

                        <th
                          scope='col'
                          className='whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900'
                        >
                          Time
                        </th>

                        <th
                          scope='col'
                          className='whitespace-nowrap px-2 py-2 text-right text-sm font-semibold text-gray-900'
                        >
                          Amount
                        </th>

                        <th
                          scope='col'
                          className='whitespace-nowrap px-2 py-2 text-right text-sm font-semibold text-gray-900'
                        >
                          Balance
                        </th>

                        <th
                          scope='col'
                          className='whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900'
                        >
                          Asal
                        </th>

                        <th
                          scope='col'
                          className='whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900'
                        >
                          Tujuan
                        </th>
                      </tr>
                    </thead>

                    <tbody className='divide-y divide-gray-200 bg-white'>
                      {forms_display.map(form => (
                        <tr
                          key={form.inputName}
                          className={classNames(
                            pendingan.findIndex(
                              statement =>
                                statement.nominal === form.amount &&
                                statement.nama.toUpperCase().trim() ===
                                  form.pengirim.namaRekeningPlayer
                                    .toUpperCase()
                                    .trim()
                            ) >= 0
                              ? 'bg-emerald-50'
                              : '',

                            'text-gray-500'
                          )}
                        >
                          <td className='whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6'>
                            {form.username}
                          </td>

                          <td
                            className={classNames(
                              'whitespace-nowrap px-2 py-2 text-sm',

                              DateTime.now().toMillis() -
                                DateTime.fromJSDate(
                                  new Date(form.timeRequest)
                                ).toMillis() >
                                warning_duration * 1000
                                ? 'text-red-500'
                                : ''
                            )}
                          >
                            {DateTime.fromJSDate(new Date(form.timeRequest))
                              .setLocale('id')
                              .toRelative()}
                          </td>

                          <td
                            className={classNames(
                              'whitespace-nowrap px-2 py-2 text-sm text-right',
                              pendingan.findIndex(
                                statement => statement.nominal === form.amount
                              ) >= 0
                                ? 'text-emerald-600 font-semibold'
                                : ''
                            )}
                          >
                            {thousandSeparator(form.amount)}
                          </td>

                          <td className='whitespace-nowrap px-2 py-2 text-sm  text-right'>
                            {thousandSeparator(form.balance)}
                          </td>

                          <td className='whitespace-nowrap px-2 py-2 text-sm '>
                            <pre className='font-sans'>
                              {form.pengirim.bankPlayer} -{' '}
                              {form.pengirim.nomorRekeningPlayer} -{' '}
                              <span
                                className={classNames(
                                  pendingan.findIndex(
                                    statement =>
                                      statement.nama ===
                                      form.pengirim.namaRekeningPlayer
                                  ) >= 0
                                    ? 'text-emerald-600 font-semibold'
                                    : ''
                                )}
                              >
                                {form.pengirim.namaRekeningPlayer}
                              </span>
                            </pre>
                          </td>

                          <td className='whitespace-nowrap px-2 py-2 pr-6 text-sm '>
                            <pre className='font-sans'>
                              <span
                                onClick={() => on_click_account(form.penerima)}
                                className='hover:text-emerald-500 active:opacity-70 cursor-pointer'
                              >
                                {form.penerima.bankPT} -{' '}
                                {form.penerima.nomorRekeningPT} -{' '}
                                {form.penerima.namaRekeningPT}
                              </span>
                            </pre>
                          </td>

                          <td className='flex'>
                            <button
                              type='button'
                              className='inline-flex items-center p-1 m-1 border border-transparent rounded-full shadow-sm text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500'
                              onClick={() => {
                                dispatch(
                                  set_find_statement_query({
                                    nama_pengirim:
                                      form.pengirim.namaRekeningPlayer,
                                    nominal: form.amount.toString(),
                                  })
                                );

                                dispatch(set_finding_statement(true));
                              }}
                            >
                              <MagnifyingGlassIcon className='h-4 w-4' />
                            </button>

                            <button
                              type='button'
                              className='inline-flex items-center p-0.5 m-1 border border-transparent rounded-full shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
                              onClick={() => {
                                if (rejecting_form) return;
                                if (!window.confirm('Reject form ini?')) return;
                                set_rejecting_form_input_name(form.inputName);
                                reject_form(form);
                              }}
                              onMouseEnter={e =>
                                e.currentTarget.parentElement?.parentElement?.classList.add(
                                  'bg-red-100'
                                )
                              }
                              onMouseLeave={e =>
                                e.currentTarget.parentElement?.parentElement?.classList.remove(
                                  'bg-red-100'
                                )
                              }
                            >
                              {rejecting_form_input_name === form.inputName ? (
                                <LoadingIndicator colorScheme='light' />
                              ) : (
                                <XMarkIcon className='h-5 w-5' />
                              )}
                            </button>

                            <button
                              type='button'
                              className='inline-flex items-center p-0.5 m-1 border border-transparent rounded-full shadow-sm text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500'
                              onClick={() => {
                                if (confirming_form) return;
                                set_confirming_form_input_name(form.inputName);
                                confirm_form(form);
                              }}
                              onMouseEnter={e =>
                                e.currentTarget.parentElement?.parentElement?.classList.add(
                                  'bg-emerald-100'
                                )
                              }
                              onMouseLeave={e =>
                                e.currentTarget.parentElement?.parentElement?.classList.remove(
                                  'bg-emerald-100'
                                )
                              }
                            >
                              {confirming_form_input_name === form.inputName ? (
                                <LoadingIndicator colorScheme='light' />
                              ) : (
                                <CheckIcon className='h-5 w-5' />
                              )}
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </main>
  );
};

export default Main;
