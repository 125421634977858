import { useRef } from 'react';

const ChangePIN = () => {
  const old_pin_ref = useRef<HTMLInputElement>(null);
  const new_pin_ref = useRef<HTMLInputElement>(null);
  const confirm_pin_ref = useRef<HTMLInputElement>(null);

  const handle_submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const old_pin = old_pin_ref.current?.value;
      if (!old_pin) throw new Error('Silahkan isi PIN lama');

      const new_pin = new_pin_ref.current?.value;
      if (!new_pin) throw new Error('Silahkan isi PIN baru');

      const confirm_pin = confirm_pin_ref.current?.value;
      if (!confirm_pin) throw new Error('Silahkan isi konfirmasi PIN');

      if (new_pin.length !== 4) throw new Error('PIN harus 4 karakter');

      if (new_pin !== confirm_pin)
        throw new Error('PIN baru dan konfirmasi PIN tidak cocok');

      const response = await fetch('/api/user/change-pin', {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ oldPIN: old_pin, newPIN: new_pin }),
      });

      const res: { errors?: { message: string }[]; message?: string } =
        await response.json();

      if (res.errors && res.errors.length)
        throw new Error(res.errors[0].message);

      alert(res.message);

      old_pin_ref.current.value = '';
      new_pin_ref.current.value = '';
      confirm_pin_ref.current.value = '';
    } catch (error: any) {
      alert(error.message || `Terjadi kesalahan ketika mengubah password.`);
    }
  };

  return (
    <form className='w-1/5 space-y-3' onSubmit={handle_submit}>
      <h1 className='text-2xl font-bold'>Ganti PIN</h1>

      <input
        type='password'
        className='shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block w-full sm:text-sm border-gray-300 rounded-md'
        placeholder='PIN Lama'
        ref={old_pin_ref}
        maxLength={4}
      />

      <input
        type='password'
        className='shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block w-full sm:text-sm border-gray-300 rounded-md'
        placeholder='PIN Baru'
        ref={new_pin_ref}
        maxLength={4}
      />

      <input
        type='password'
        className='shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block w-full sm:text-sm border-gray-300 rounded-md'
        placeholder='Konfirmasi PIN Baru'
        ref={confirm_pin_ref}
        maxLength={4}
      />

      <input
        type='submit'
        className='inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 w-full'
        value='Submit'
      />
    </form>
  );
};

export { ChangePIN };
