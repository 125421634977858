import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Dashboard from './Pages/Dashboard';
import ChangePassword from './Pages/Dashboard/Outlet/Settings';
import History from './Pages/Dashboard/Outlet/History';
import Live from './Pages/Dashboard/Outlet/Live';
import Log from './Pages/Dashboard/Outlet/Log';
import Mutasi from './Pages/Dashboard/Outlet/Mutasi';
import Rekening from './Pages/Dashboard/Outlet/Rekening';
import Stats from './Pages/Dashboard/Outlet/Stats';
import Tarikan from './Pages/Dashboard/Outlet/Tarikan';
import Trash from './Pages/Dashboard/Outlet/Trash';
import User from './Pages/Dashboard/Outlet/User';
import Loading from './Pages/Loading';
import Login from './Pages/Login';
import { nav_path } from './constant';

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Loading />} />

        <Route path={nav_path.dashboard.index} element={<Dashboard />}>
          <Route
            path={nav_path.dashboard.settings}
            element={<ChangePassword />}
          />

          <Route path={nav_path.dashboard.history} element={<History />} />
          <Route path={nav_path.dashboard.live} element={<Live />} />
          <Route path={nav_path.dashboard.rekening} element={<Rekening />} />
          <Route path={nav_path.dashboard.trash} element={<Trash />} />
          <Route path={nav_path.dashboard.user} element={<User />} />
          <Route path={nav_path.dashboard.mutasi} element={<Mutasi />} />
          <Route path={nav_path.dashboard.log} element={<Log />} />
          <Route path={nav_path.dashboard.tarikan} element={<Tarikan />} />
          <Route path={nav_path.dashboard.stats} element={<Stats />} />
        </Route>

        <Route path={nav_path.login} element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
