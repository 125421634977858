import { Dialog, Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { Fragment, useEffect, useRef, useState } from 'react';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { useNavigate } from 'react-router-dom';
import LoadingIndicator from '../../../../Components/LoadingIndicator/LoadingIndicator';
import { nav_path } from '../../../../constant';
import { classNames, errMsg } from '../../../../helper';
import { useAppDispatch, useAppSelector } from '../../../../store';
import {
  delete_account,
  save_account,
} from '../../../../store/state/account/request';
import { AccountModel, Banks } from '../../../../types';

const AREA = { SAVE: 'SAVE', DELETE: 'DELETE' };

interface AddRekeningProps {
  editing_acc_id: string;
  set_display_add_edit_window: React.Dispatch<React.SetStateAction<boolean>>;
  display_add_edit_window: boolean;
  set_editing_acc_id: React.Dispatch<React.SetStateAction<string>>;
}

const AddRekening: React.FC<AddRekeningProps> = ({
  display_add_edit_window,
  set_display_add_edit_window,
  editing_acc_id,
  set_editing_acc_id,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { active_tab } = useAppSelector(state => state.active_tab);

  const [selected_bank, set_selected_bank] = useState(active_tab || 'BCA');
  const [pin, set_pin] = useState('');
  const [label, set_label] = useState('');
  const [acc_name, set_acc_name] = useState('');
  const [acc_num, set_acc_num] = useState('');
  const [acc_pass, set_acc_pass] = useState('');
  const [balance, set_balance] = useState('');
  const [acc_username, set_acc_username] = useState('');
  const [mid, set_mid] = useState('');

  const nama_bank_ref = useRef(null);

  const { promiseInProgress: saving_acc } = usePromiseTracker({
    area: AREA.SAVE,
  });

  const { promiseInProgress: deleting_acc } = usePromiseTracker({
    area: AREA.DELETE,
  });

  useEffect(() => {
    if (!editing_acc_id) return;

    const get_data = async () => {
      try {
        const uri = `/api/account/by-id/${editing_acc_id}`;
        const response = await fetch(uri);

        const res: { message: string; data: AccountModel } =
          await response.json();

        if (!response.ok) throw new Error(res.message);

        set_selected_bank(res.data.namaBank);
        set_label(res.data.labelRekening);
        set_acc_name(res.data.namaRekening);
        set_acc_num(res.data.nomorRekening);
        set_acc_pass(res.data.passwordRekening);
        set_acc_username(res.data.usernameRekening);
        set_balance(res.data.balance?.toString() || '');
        set_pin(res.data.pin);
        set_mid(res.data.mid);
      } catch (error: any) {
        alert(
          error.message ||
            `Terjadi kesalahan ketika mengambil akun menggunakan id...`
        );
      }
    };

    get_data();
  }, [editing_acc_id]);

  const close_window = () => {
    set_display_add_edit_window(false);

    setTimeout(() => {
      set_selected_bank(active_tab || 'BCA');
      set_pin('');
      set_label('');
      set_acc_name('');
      set_acc_num('');
      set_acc_pass('');
      set_acc_username('');
      set_balance('');
      set_editing_acc_id('');
      set_mid('');
    }, 200);
  };

  const save_acc = async () => {
    if (!selected_bank) throw new Error('Belum mengisi nama rekening.');
    if (!acc_name) throw new Error('Belum mengisi nama rekening...');
    if (!label) throw new Error('Belum mengisi label rekening...');
    if (!acc_username) throw new Error('Belum mengisi username rekening...');
    if (!acc_pass) throw new Error('Belum mengisi password rekening...');
    if (!acc_num) throw new Error('Belum mengisi nomor rekening...');
    if (selected_bank === 'QRIS' && !mid)
      throw new Error('Belum mengisi Merchant ID');

    const { meta, payload } = await dispatch(
      save_account({
        acc_name,
        acc_num,
        acc_pass,
        acc_username,
        bank_name: selected_bank,
        balance,
        editing_acc_id,
        label,
        pin,
        mid,
      })
    );

    const res = payload as { message: string; status: number };

    if (meta.requestStatus === 'rejected') {
      alert(errMsg(res) || res.message);
      if (res.status === 401) navigate(nav_path.login);
      return;
    }

    close_window();
  };

  const delete_acc = async () => {
    const confirmed = window.confirm(`Yakin ingin menghapus rekening ini?`);
    if (!confirmed) return;
    const { meta, payload } = await dispatch(
      delete_account({ acc_id: editing_acc_id })
    );
    const res = payload as { message: string; status: number };

    if (meta.requestStatus === 'rejected') {
      alert(errMsg(res) || res.message);
      if (res.status === 401) navigate(nav_path.login);
      return;
    }

    close_window();
  };

  return (
    <Transition.Root show={display_add_edit_window} as={Fragment}>
      <Dialog
        as='div'
        className='relative z-10'
        initialFocus={nama_bank_ref}
        onClose={close_window}
      >
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed z-10 inset-0 overflow-y-auto'>
          <div className='flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6'>
                <div>
                  <div className='mt-3 text-center sm:mt-5'>
                    <Dialog.Title
                      as='h3'
                      className='text-lg leading-6 font-medium text-gray-900'
                    >
                      Tambahkan Rekening
                    </Dialog.Title>

                    <Listbox
                      value={selected_bank}
                      onChange={set_selected_bank}
                      refName={'namaBankRef'}
                    >
                      {({ open }) => (
                        <div className='mt-4 relative'>
                          <Listbox.Button className='bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm'>
                            <span className='block truncate'>
                              {selected_bank}
                            </span>

                            <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
                              <ChevronDownIcon className='h-5 w-5 text-gray-400' />
                            </span>
                          </Listbox.Button>

                          <Transition
                            show={open}
                            as={Fragment}
                            leave='transition ease-in duration-100'
                            leaveFrom='opacity-100'
                            leaveTo='opacity-0'
                          >
                            <Listbox.Options className='absolute z-10 mt-1 w-full bg-white shadow-lg max-h-64 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm'>
                              {Object.values(Banks).map(bank => (
                                <Listbox.Option
                                  key={bank}
                                  className={({ active }) =>
                                    classNames(
                                      active
                                        ? 'text-white bg-emerald-600'
                                        : 'text-gray-900',
                                      'cursor-default select-none relative py-2 pl-3 pr-9'
                                    )
                                  }
                                  value={bank}
                                >
                                  {({ selected, active }) => (
                                    <>
                                      <span
                                        className={classNames(
                                          selected
                                            ? 'font-semibold'
                                            : 'font-normal',
                                          'block truncate'
                                        )}
                                      >
                                        {bank}
                                      </span>

                                      {selected ? (
                                        <span
                                          className={classNames(
                                            active
                                              ? 'text-white'
                                              : 'text-emerald-600',
                                            'absolute inset-y-0 right-0 flex items-center pr-4'
                                          )}
                                        >
                                          <CheckIcon className='h-5 w-5' />
                                        </span>
                                      ) : null}
                                    </>
                                  )}
                                </Listbox.Option>
                              ))}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      )}
                    </Listbox>

                    <div className='mt-2'>
                      <input
                        type='text'
                        className='shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block w-full sm:text-sm border-gray-300 rounded-md'
                        placeholder='Nama Sesuai Rekening. Contoh: David'
                        value={acc_name}
                        onChange={e => set_acc_name(e.target.value)}
                      />
                    </div>

                    <div className='mt-2'>
                      <input
                        type='text'
                        className='shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block w-full sm:text-sm border-gray-300 rounded-md'
                        placeholder='Label. Contoh: BCA Deposit'
                        value={label}
                        onChange={e => set_label(e.target.value)}
                      />
                    </div>

                    <div className='mt-2'>
                      <input
                        type='text'
                        className='shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block w-full sm:text-sm border-gray-300 rounded-md'
                        placeholder='Username. Contoh: david123'
                        value={acc_username}
                        onChange={e => set_acc_username(e.target.value)}
                      />
                    </div>

                    <div className='mt-2'>
                      <input
                        type='text'
                        className='shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block w-full sm:text-sm border-gray-300 rounded-md'
                        placeholder='Password. Contoh: 123456'
                        value={acc_pass}
                        onChange={e => set_acc_pass(e.target.value)}
                      />
                    </div>

                    <div className='mt-2'>
                      <input
                        type='text'
                        name='nomorRekening'
                        className='shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block w-full sm:text-sm border-gray-300 rounded-md'
                        placeholder={`Nomor Rekening. Contoh: 0123456789`}
                        value={acc_num}
                        onChange={e => set_acc_num(e.target.value)}
                      />
                    </div>

                    <div className='mt-2'>
                      <input
                        type='number'
                        className='shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block w-full sm:text-sm border-gray-300 rounded-md'
                        placeholder='Saldo awal...'
                        value={balance}
                        onChange={e => set_balance(e.target.value)}
                      />
                    </div>

                    {selected_bank === 'QRIS' && (
                      <div className='mt-2'>
                        <input
                          type='text'
                          className='shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block w-full sm:text-sm border-gray-300 rounded-md'
                          placeholder='Merchant ID'
                          value={mid}
                          onChange={e => set_mid(e.target.value)}
                        />
                      </div>
                    )}
                  </div>
                </div>

                <div className='mt-3 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense'>
                  <button
                    type='button'
                    className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-emerald-600 text-base font-medium text-white hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 sm:col-start-2 sm:text-sm transition-colors'
                    onClick={() => {
                      if (saving_acc) return;
                      trackPromise(save_acc(), AREA.SAVE);
                    }}
                  >
                    {saving_acc ? (
                      <LoadingIndicator colorScheme='light' />
                    ) : (
                      'Simpan'
                    )}
                  </button>

                  <button
                    type='button'
                    className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 sm:mt-0 sm:col-start-1 sm:text-sm transition-colors'
                    onClick={() => set_display_add_edit_window(false)}
                  >
                    Batal
                  </button>
                </div>

                {editing_acc_id && (
                  <div className='mt-2'>
                    <button
                      type='button'
                      className='w-full inline-flex justify-center rounded-md border shadow-sm px-4 py-3 bg-red-500 text-base font-medium text-white hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 sm:mt-0 sm:col-start-1 sm:text-sm transition-colors'
                      onClick={() => {
                        if (deleting_acc) return;
                        trackPromise(delete_acc(), AREA.DELETE);
                      }}
                    >
                      Delete Rekening
                    </button>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default AddRekening;
