import { createAsyncThunk } from '@reduxjs/toolkit';
import { FormModel } from '../../../../types';

export interface RejectFormPayload {
  message: string;
  data: { forms: FormModel[] };
  status?: number;
}

const check_and_reject_form = createAsyncThunk(
  'form/reject_form',

  async (form: FormModel, { rejectWithValue }) => {
    const response = await fetch(`/api/form/reject`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ form }),
    });

    const res: RejectFormPayload = await response.json();

    if (!response.ok)
      return rejectWithValue({ ...res, status: response.status });

    return res;
  }
);

export { check_and_reject_form };
