import { createAsyncThunk } from '@reduxjs/toolkit';
import { AccountModel, StatementModel } from '../../../../types';

export interface GetPendinganPayload {
  message: string;
  data: StatementModel[];
  status?: number;
}

const get_pendingan_live = createAsyncThunk(
  'pendingan/get',
  async (selected_acc: AccountModel, { rejectWithValue }) => {
    const response = await fetch(`/api/statement/live`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ accountId: selected_acc.id }),
    });

    const res: GetPendinganPayload = await response.json();
    if (!response.ok) rejectWithValue({ ...res, status: response.status });
    return res;
  }
);

export default get_pendingan_live;
