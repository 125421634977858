import { createAsyncThunk } from '@reduxjs/toolkit';
import { errMsg } from '../../../../helper';

interface DeleteAccountParams {
  acc_id: string;
}

const delete_account = createAsyncThunk(
  'account/delete_account',

  async ({ acc_id }: DeleteAccountParams, { rejectWithValue }) => {
    const uri = `/api/account/by-id/${acc_id}`;
    const response = await fetch(uri, { method: 'DELETE' });
    const res = await response.json();
    if (!response.ok) return rejectWithValue(errMsg(res) || res.message);
    return res;
  }
);

export default delete_account;
