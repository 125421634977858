import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface LockedState {
  locked: boolean;
}

const initial_state: LockedState = {
  locked: false,
};

export const locked_slice = createSlice({
  name: 'locked',
  initialState: initial_state,

  reducers: {
    set_locked: (state, action: PayloadAction<boolean>) => {
      state.locked = action.payload;
    },
  },
});

export default locked_slice;
export const { set_locked } = locked_slice.actions;
