import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline';
import { Query } from '..';
import { classNames, getPageArray } from '../../../../../helper';

interface PaginationProps {
  total_data: number;
  total_page: number;
  query: Query;
  set_query: React.Dispatch<React.SetStateAction<Query>>;
}

const Pagination: React.FC<PaginationProps> = ({
  query,
  set_query,
  total_data,
  total_page,
}) => {
  return (
    <div className='bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6'>
      <div className='hidden sm:flex-1 sm:flex sm:items-center sm:justify-between'>
        <div>
          <p className='text-sm text-gray-700'>
            Menampilkan{' '}
            <span className='font-medium'>
              {total_data > 0 ? query.page * 100 - 99 : 0}
            </span>{' '}
            sampai{' '}
            <span className='font-medium'>
              {query.page * 100 > total_data ? total_data : query.page * 100}
            </span>{' '}
            dari <span className='font-medium'>{total_data}</span> hasil
          </p>
        </div>

        <div>
          <nav className='relative z-0 inline-flex rounded-md shadow-sm -space-x-px'>
            <button
              className='relative inline-flex justify-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 w-12'
              onClick={() =>
                set_query(searchQuery => {
                  searchQuery.page = 1;
                  return { ...searchQuery };
                })
              }
            >
              <ChevronDoubleLeftIcon className='h-5 w-5' />
            </button>

            <button
              className='relative inline-flex justify-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 w-12'
              onClick={() => {
                if (query.page === 1) return;

                set_query(searchQuery => {
                  searchQuery.page--;
                  return { ...searchQuery };
                });
              }}
            >
              <ChevronLeftIcon className='h-5 w-5' />
            </button>

            {getPageArray(query.page, total_page).map(pageNum => (
              <button
                className={classNames(
                  query.page === pageNum
                    ? 'z-10 bg-emerald-50 border-emerald-500 text-emerald-600'
                    : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50',

                  'hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium justify-center w-12'
                )}
                onClick={() =>
                  set_query(searchQuery => {
                    searchQuery.page = pageNum;
                    return { ...searchQuery };
                  })
                }
                key={`Page Button #${pageNum}`}
              >
                {pageNum}
              </button>
            ))}

            <button
              className='relative inline-flex justify-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 w-12'
              onClick={() => {
                if (query.page === total_page) return;

                set_query(searchQuery => {
                  searchQuery.page++;
                  return { ...searchQuery };
                });
              }}
            >
              <ChevronRightIcon className='h-5 w-5' />
            </button>

            <button
              className='relative inline-flex justify-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 w-12'
              onClick={() =>
                set_query(searchQuery => {
                  searchQuery.page = total_page;
                  return { ...searchQuery };
                })
              }
            >
              <ChevronDoubleRightIcon className='h-5 w-5' />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
